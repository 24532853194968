import { z } from 'zod';
import { priceCalculations } from './price_calculations';
export var CalculatedQuoteDefaultGroups;
(function (CalculatedQuoteDefaultGroups) {
    CalculatedQuoteDefaultGroups["HEAT_PUMPS"] = "Heat Pumps";
    CalculatedQuoteDefaultGroups["HOT_WATER_CYLINDERS"] = "Hot Water Cylinders";
    CalculatedQuoteDefaultGroups["PARTS"] = "Parts";
    CalculatedQuoteDefaultGroups["RADIATORS"] = "Radiators";
    CalculatedQuoteDefaultGroups["UNDERFLOOR"] = "Underfloor heating";
    CalculatedQuoteDefaultGroups["LABOUR"] = "Labour";
    CalculatedQuoteDefaultGroups["SURVEY"] = "Survey";
    CalculatedQuoteDefaultGroups["GRANTS"] = "Grants";
})(CalculatedQuoteDefaultGroups || (CalculatedQuoteDefaultGroups = {}));
export const QuoteItemSchema = z.object({
    group_name: z.string(),
    uuid: z.string().optional(),
    lead_uuid: z.string().optional(),
    name: z.string(),
    description: z.array(z.string()).or(z.string()).optional(),
    quantity: z.coerce.number(),
    price: z.coerce.number(),
    include_vat: z.boolean(),
    image: z.string().optional(),
    subtotal: z.number().optional(),
    selected: z.boolean().optional()
});
export const CalculatedQuoteSchema = z.array(QuoteItemSchema);
const addPartOrIncrementQuantity = (parts, part) => {
    const existingPart = parts.find(x => x.uuid === part.uuid);
    if (existingPart) {
        existingPart.quantity++;
        return parts;
    }
    return [...parts, {
            group_name: 'Parts',
            uuid: part.uuid,
            name: part.name,
            description: part.description || '',
            price: priceCalculations.calculateCustomerPrice(part.cost_price, part.markup),
            quantity: 1,
            include_vat: false,
            selected: true
        }];
};
export var QuoteOverrideType;
(function (QuoteOverrideType) {
    QuoteOverrideType[QuoteOverrideType["NONE"] = 0] = "NONE";
    QuoteOverrideType[QuoteOverrideType["PARTIAL"] = 1] = "PARTIAL";
    QuoteOverrideType[QuoteOverrideType["FULL"] = 2] = "FULL";
})(QuoteOverrideType || (QuoteOverrideType = {}));
// Quotes exist in three states post-migration in https://linear.app/spruce-eco/issue/SPR-1232/costs-and-inventory-enquiry-cost-estimate:
// 1. Quotes with additional line items (parts) saved in the estimate_quote_items table, which are a 'partial' override
//    since they're missing the heat pump, hot water cylinder, labour, survey, and grant.
// 2. Quotes overriden post-migration, with all line items saved in the estimate_quote_items table, which are a 'full' override.
// 3. Quotes calculated on the fly, which are not overrides.
// The second value in the return tuple, 'isOverridden', is used to determine whether the returned quote is a full override or not.
export const calculateQuote = ({ company, selectedHeatPump, selectedHotWaterCylinder, parts, labour, packs, isScottish, override }) => {
    const calculateInnerQuote = (selectedHeatPump, selectedHotWaterCylinder, additionalParts) => {
        var _a;
        const defaultParts = parts.filter(part => !part.deleted_at && part.default_include).map(part => ({
            group_name: CalculatedQuoteDefaultGroups.PARTS,
            uuid: part.uuid,
            name: part.name,
            description: part.description || '',
            price: priceCalculations.calculateCustomerPrice(part.cost_price, part.markup),
            quantity: 1,
            include_vat: false,
            selected: true
        }));
        const defaultLabour = labour.filter(labour => !labour.deleted_at && labour.default_include).map(labour => ({
            group_name: CalculatedQuoteDefaultGroups.LABOUR,
            uuid: labour.uuid,
            name: labour.name,
            description: labour.description || '',
            price: labour.cost_price,
            quantity: labour.days,
            include_vat: false,
            selected: true
        }));
        const defaultPacks = selectPacks({ packs, selectedHeatPump, selectedHotWaterCylinder });
        const explodedPacksCombinedWithDefaultParts = defaultPacks.flatMap((pack) => pack.parts || []).reduce((acc, part) => addPartOrIncrementQuantity(acc, part), defaultParts);
        const calculatedQuote = [
            ...(selectedHeatPump ? [{
                    group_name: CalculatedQuoteDefaultGroups.HEAT_PUMPS,
                    uuid: selectedHeatPump.uuid,
                    name: selectedHeatPump.name,
                    price: priceCalculations.calculateCustomerPrice(selectedHeatPump.cost_price, selectedHeatPump.markup),
                    quantity: 1,
                    include_vat: false,
                    selected: true
                }] : []),
            ...(selectedHotWaterCylinder ? [{
                    group_name: CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS,
                    uuid: selectedHotWaterCylinder.uuid,
                    name: selectedHotWaterCylinder.name,
                    price: priceCalculations.calculateCustomerPrice(selectedHotWaterCylinder.cost_price, selectedHotWaterCylinder.markup),
                    quantity: 1,
                    include_vat: false,
                    selected: true
                }] : []),
            ...explodedPacksCombinedWithDefaultParts,
            ...additionalParts || [],
            ...defaultLabour,
            {
                group_name: CalculatedQuoteDefaultGroups.SURVEY,
                uuid: crypto.randomUUID(),
                name: 'Survey',
                price: (_a = company.survey_cost) !== null && _a !== void 0 ? _a : 0,
                quantity: 1,
                include_vat: true,
                selected: true
            },
            ...(!isScottish ? [{
                    group_name: CalculatedQuoteDefaultGroups.GRANTS,
                    uuid: crypto.randomUUID(),
                    name: 'BUS Grant',
                    description: 'Boiler Upgrade Scheme grant',
                    price: -7500,
                    quantity: 1,
                    selected: true,
                    include_vat: false
                }] : [])
        ]
            // Calculate all subtotals
            .map(x => x.include_vat ? { ...x, subtotal: x.price * x.quantity * 1.2 } : { ...x, subtotal: x.price * x.quantity });
        // We set the second value, 'isOverridden', to false if we receive the 'additionalParts' argument,
        // because we only receive it when we need to calculate a full quote from a partial (parts-only) override
        // which came to us post-migration. We set isOverridden to true if we receive a full override.
        if (additionalParts && additionalParts.length > 0) {
            return [calculatedQuote, QuoteOverrideType.PARTIAL];
        }
        else {
            return [calculatedQuote, QuoteOverrideType.NONE];
        }
    };
    if ((override === null || override === void 0 ? void 0 : override.length) && override.length > 0) {
        // Post-migration in https://linear.app/spruce-eco/issue/SPR-1232/costs-and-inventory-enquiry-cost-estimate,
        // some of our quote overrides might contain only 'Parts' items,
        // because we were only able to migrate the estimate_line_items table, which equates
        // to parts - the heat pump, hot water cylinder, labour, survey, and grant were all
        // generated on the fly by the calculateEstimate function. So, if we have an override,
        // and the override contains only 'Parts' items, we need to calculate the missing
        // items and return the full quote.
        // TODO: If we ever end up archiving old estimates, we could remove this check
        // once we're sure that all estimates which require this check have been archived.
        const hasOnlyParts = override.every(item => item.group_name === CalculatedQuoteDefaultGroups.PARTS);
        if (hasOnlyParts && selectedHeatPump && selectedHotWaterCylinder) {
            return calculateInnerQuote(selectedHeatPump, selectedHotWaterCylinder, override);
        }
        // We have a full override
        return [override.map(item => {
                return { ...item, subtotal: item.include_vat ? item.price * item.quantity * 1.2 : item.price * item.quantity };
            }), QuoteOverrideType.FULL];
    }
    return calculateInnerQuote(selectedHeatPump, selectedHotWaterCylinder);
};
const selectPacks = ({ packs, selectedHeatPump, selectedHotWaterCylinder }) => packs.filter(pack => {
    return pack.uuid === (selectedHeatPump === null || selectedHeatPump === void 0 ? void 0 : selectedHeatPump.default_pack_uuid) || pack.uuid === (selectedHotWaterCylinder === null || selectedHotWaterCylinder === void 0 ? void 0 : selectedHotWaterCylinder.default_pack_uuid);
});
export const quoteItemNameWithQuantity = (quoteItem, postfix) => quoteItem.quantity > 1 ? `${quoteItem.name} × ${quoteItem.quantity}${postfix ? ` ${postfix}` : ''}` : quoteItem.name;
