import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { HLRContext } from './heatloss_report';
import { AGILE_PENCE_PER_KWH, AGILE_PRICE_EXPLANATION, OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH, PRICE_CAP_ELECTRICITY_PENCE_PER_KWH } from '../../../code/models/heating_fuel';
import { HLRListBlock } from './partials/hlr_list';
export const HLRPESystemEfficiency2 = () => {
    const hlrContext = React.useContext(HLRContext);
    const hddPerformanceEstimate = hlrContext.performanceEstimateSummary.hddEstimate;
    return (React.createElement("div", { className: 'flex gap-6 flex-col' },
        React.createElement("div", null, "If you have solar (or are planning to install it) your electricity prices will also be lower because your heat pump will use some of the free electricity that your solar is generating. This is especially true for hot water consumption because you can schedule your hot water to run in the middle of the day."),
        React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: 'flex flex-row justify-between' },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, hddPerformanceEstimate.existing.fuelName),
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Unit price")),
                    React.createElement("div", null,
                        React.createElement("div", { className: "py-2 items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Price cap"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hddPerformanceEstimate.existing.pencePerKwhPriceCap.toFixed(2),
                                " p/kWh"))))),
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: 'flex flex-row justify-between' },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Electricity"),
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Unit price")),
                    React.createElement("div", null,
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Price cap"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                PRICE_CAP_ELECTRICITY_PENCE_PER_KWH,
                                " p/kWh")),
                        (hlrContext === null || hlrContext === void 0 ? void 0 : hlrContext.eligibleForHeatPumpPlus) && React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "OVO Heat Pump Plus*"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH,
                                " p/kWh")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-xs" },
                                React.createElement("span", { className: 'text-gray-900 font-semibold' }, "Octopus Agile"),
                                " last winter average**"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                AGILE_PENCE_PER_KWH,
                                " p/kWh"))),
                    React.createElement("div", null,
                        React.createElement("div", { className: "text-xs" }, "*Currently only available for Vaillant, Viessmann and Mitsubishi heat pumps"),
                        React.createElement("div", { className: "text-xs" },
                            "**",
                            AGILE_PRICE_EXPLANATION))))),
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: "" }, "You may want to check out some of these heat pump tariffs (we haven\u2019t vetted these):"),
            React.createElement("div", { className: 'font-bold' }, "Type of use:"),
            React.createElement(HLRListBlock, { items: [
                    React.createElement(React.Fragment, null,
                        React.createElement("a", { className: 'underline', href: 'https://www.ovoenergy.com/heat-pump-plus' }, "OVO heat pump plus"),
                        ": ",
                        OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH,
                        "p/kWh. Vaillant, Viessmann and Mitsubishi heat pumps only currently.")
                ] }),
            React.createElement("div", { className: 'font-bold' }, "Time of use:"),
            React.createElement(HLRListBlock, { items: [
                    React.createElement(React.Fragment, null,
                        React.createElement("a", { className: 'underline', href: 'https://www.edfenergy.com/heating/electric/air-source-heat-pump-tariff/trial' }, "EDF"),
                        ": 10p/kWh off from 4-7am and from 1-4pm"),
                    React.createElement(React.Fragment, null,
                        React.createElement("a", { className: 'underline', href: 'https://octopus.energy/smart/cosy-octopus/' }, "Octopus Cosy"),
                        ": Cheaper pricing 4am-7am and 1-4pm. Peak pricing 4pm -7pm"),
                    React.createElement(React.Fragment, null,
                        React.createElement("a", { className: 'underline', href: 'https://octopus.energy/smart/agile/' }, "Octopus Agile"),
                        ": Half-hourly pricing which tracks the wholesale price. Goes both very high and negative.")
                ] }),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Emission factors"),
                React.createElement("div", null, "We\u2019ve used the emission factors from the government\u2019s SAP 2010 methodology. For gas this value is fixed but for electricity it is falling over time as the grid decarbonises. This means your system will get cleaner and cleaner. The value used here is based on a projected continued reduction in grid carbon intensity.")),
            React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
                React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                    React.createElement("div", { className: 'flex flex-col gap-4' },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Gas"),
                        React.createElement("div", { className: "gap-1 flex items-end" },
                            React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, hddPerformanceEstimate.existing.emissionsFactorGPerKwh),
                            React.createElement("div", { className: "text-lg" }, "gCO2/kWh")))),
                React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                    React.createElement("div", { className: 'flex flex-col gap-4' },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Electricity"),
                        React.createElement("div", { className: "gap-1 flex items-end" },
                            React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, hddPerformanceEstimate.proposed.emissionsFactorGPerKwh),
                            React.createElement("div", { className: "text-lg" }, "gCO2/kWh"))))))));
};
