import React, { useRef, useState } from 'react';
import { ChevronDownIcon, EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { patchLead } from '../../../code/models/lead';
import { useRouteLoaderData } from 'react-router-dom';
import { leadStatusToBadge } from '../../../components/indicators_and_messaging/badge';
import { DuplicateLeadModal } from '../../admin/components/duplicate_lead_modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
export const EnquiriesListItemOptions = ({ lead, setLead, size }) => {
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [isOpen, setIsOpen] = useState(false);
    const [isDuplicateLeadModalOpen, setIsDuplicateLeadModalOpen] = useState(false);
    // Use case: user opens the dropdown and then clicks outside of it
    // This will close the dropdown
    const selectorRef = useRef(null);
    document.addEventListener('mousedown', (e) => {
        if (selectorRef.current && !selectorRef.current.contains(e.target))
            setIsOpen(false);
    });
    const handleOnClickStatus = async (status) => {
        await patchLead(lead.uuid, { status }, companyPublicInfo.uuid);
        setIsOpen(false);
        if (setLead)
            setLead({ ...lead, status });
    };
    // items in the dropdown
    const items = [
        lead.status === 'New' ? undefined : { label: leadStatusToBadge('New').text, onClick: async () => { await handleOnClickStatus('New'); } },
        lead.status === 'Quoted' ? undefined : { label: leadStatusToBadge('Quoted').text, onClick: async () => { await handleOnClickStatus('Quoted'); } },
        lead.status === 'Converted' ? undefined : { label: leadStatusToBadge('Converted').text, onClick: async () => { await handleOnClickStatus('Converted'); } },
        lead.status === 'SurveyBooked' ? undefined : { label: leadStatusToBadge('SurveyBooked').text, onClick: async () => { await handleOnClickStatus('SurveyBooked'); } },
        lead.status === 'SurveyInProgress' ? undefined : { label: leadStatusToBadge('SurveyInProgress').text, onClick: async () => { await handleOnClickStatus('SurveyInProgress'); } },
        lead.status === 'SurveyCompleted' ? undefined : { label: leadStatusToBadge('SurveyCompleted').text, onClick: async () => { await handleOnClickStatus('SurveyCompleted'); } },
        // separator also needs conditional rendering
        lead.status === 'Rejected' ? undefined : { label: '---', onClick: async () => { } },
        lead.status === 'Rejected' ? undefined : { label: leadStatusToBadge('Rejected').text, onClick: async () => { await handleOnClickStatus('Rejected'); } },
        lead.status === 'Declined' ? undefined : { label: leadStatusToBadge('Declined').text, onClick: async () => { await handleOnClickStatus('Declined'); } },
        lead.status === 'Archived' ? undefined : { label: leadStatusToBadge('Archived').text, onClick: async () => { await handleOnClickStatus('Archived'); } },
        { label: '---', onClick: async () => { } },
        {
            label: 'Duplicate',
            icon: faCopy,
            onClick: async () => {
                setIsOpen(false);
                setIsDuplicateLeadModalOpen(true);
            }
        }
    ].filter(x => x !== undefined);
    // define label and color
    const label = leadStatusToBadge(lead.status).text;
    const color = leadStatusToBadge(lead.status).color;
    //
    let view;
    switch (size) {
        case 'sm':
            view = React.createElement(EllipsisHorizontalIcon, { className: `${isOpen ? 'text-gray-900' : 'text-gray-400'} w-5 h-5 relative cursor-pointer`, onClick: () => { setIsOpen(prev => !prev); } });
            break;
        case 'lg':
            view = React.createElement("div", { className: `${color} px-3 py-2.5 rounded-md justify-center items-center gap-3 flex cursor-pointer`, onClick: () => { setIsOpen(prev => !prev); } },
                React.createElement("div", { className: "px-1 flex-col justify-center items-start inline-flex" },
                    React.createElement("div", { className: "text-xs font-semibold tracking-wide" }, "STATUS"),
                    React.createElement("div", { className: "text-sm font-bold" }, label)),
                React.createElement(ChevronDownIcon, { className: "w-4 h-4 relative" }));
            break;
        default:
            throw new Error('Unknown size');
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { ref: selectorRef },
            view,
            React.createElement(DuplicateLeadModal, { visible: isDuplicateLeadModalOpen, setVisible: setIsDuplicateLeadModalOpen, lead: lead }),
            React.createElement("div", { className: `${!isOpen ? 'hidden' : ''} relative` },
                React.createElement("div", { className: "absolute -start-56 w-60 z-10 py-2 bg-white rounded-md shadow border border-gray-200 flex-col justify-start items-start flex" }, items.map((item, index) => {
                    // separator
                    if (item.label === '---') {
                        return React.createElement("div", { key: index, className: "self-stretch py-2 bg-white justify-start items-start gap-2.5 inline-flex" },
                            React.createElement("div", { className: "grow shrink basis-0 h-px border border-gray-200" }));
                        // normal item
                    }
                    else {
                        return React.createElement("div", { key: index, className: "self-stretch hover:bg-gray-100 px-3 py-2 bg-white justify-start items-start gap-2.5 inline-flex", onClick: async () => { await (item === null || item === void 0 ? void 0 : item.onClick()); } },
                            React.createElement("div", { className: "select-none text-sm flex flex-row gap-2" },
                                (item === null || item === void 0 ? void 0 : item.icon) && React.createElement(FontAwesomeIcon, { className: "w-4 h-4", icon: item.icon }),
                                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.label)));
                    }
                })))));
};
