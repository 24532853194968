import React, { useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Icon } from './icon';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Text } from '../content_display/text';
import { Modal } from '../containers/modal';
export const DropdownMenu = ({ icon, items }) => {
    const [confirmModalItem, setConfirmModalItem] = useState(undefined);
    return (React.createElement(React.Fragment, null,
        React.createElement(Menu, null,
            React.createElement(MenuButton, null, React.createElement(Icon, { icon: icon !== null && icon !== void 0 ? icon : faEllipsis })),
            React.createElement(MenuItems, { anchor: "bottom end", className: "bg-white rounded-md shadow border border-light flex flex-col" }, items.map((item, index) => (React.createElement(MenuItem, { key: index },
                React.createElement("button", { onClick: item.confirmText ? () => setConfirmModalItem(item) : item.onClick, className: "first:pt-2 last:pb-2 flex items-center gap-2 px-3 py-2 hover:bg-gray-200" },
                    item.icon && React.createElement(Icon, { icon: item.icon }),
                    React.createElement(Text, { size: "SM" }, item.label))))))),
        confirmModalItem && React.createElement(Modal, { onConfirm: confirmModalItem.onClick, visible: true, title: confirmModalItem.confirmText, setVisible: () => setConfirmModalItem(undefined) }, "This action cannot be undone.")));
};
