import React from 'react';
import { DropdownMenu } from '../../../components/buttons/dropdown_menu';
import { faEdit, faTrash, faShare, faDumpster } from '@fortawesome/free-solid-svg-icons';
export const DropdownMenuDemo = () => {
    const handleEdit = () => console.log('Edit clicked');
    const handleDelete = () => console.log('Delete clicked');
    const handleShare = () => console.log('Share clicked');
    const items = [
        { label: 'Edit', icon: faEdit, onClick: handleEdit },
        { label: 'Delete', icon: faTrash, onClick: handleDelete, confirmText: 'Are you sure you want to delete this item?' },
        { label: 'Share', icon: faShare, onClick: handleShare }
    ];
    return (React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => (React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description)))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Default (with ellipsis icon)"),
            React.createElement("div", { className: 'flex justify-start items-center gap-4' },
                React.createElement(DropdownMenu, { items: items })),
            React.createElement("div", null, "Custom icon"),
            React.createElement("div", { className: 'flex justify-start items-center gap-4' },
                React.createElement(DropdownMenu, { items: items, icon: faDumpster })))));
};
const componentProps = [
    { name: 'icon', description: 'Custom icon for the dropdown button (optional, defaults to ellipsis)' },
    { name: 'items', description: 'Array of menu items' }
];
const codeBlock = `<DropdownMenu
  icon={faEllipsis}
  items={[
    { label: 'Edit', icon: faEdit, onClick: handleEdit },
    { label: 'Delete', icon: faTrash, onClick: handleDelete, confirmText: 'Are you sure you want to delete this item?' },
    { label: 'Share', icon: faShare, onClick: handleShare },
  ]}
/>`;
