import React, { useRef } from 'react';
import { CanvasNav } from './canvas_nav';
import { faEye, faEyeSlash, faMinusCircle, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FloorPlanInput } from './floor_buttons';
import { Slider } from '../../../components/inputs_and_selections/slider';
export const FloorPlanButtons = ({ floor, setFloor, setShowFloorPlanButtons, setTempImageAndScale, setStageStep, planOpacity, setPlanOpacity }) => {
    const fileInputRef = useRef(null);
    const showOrHide = floor.floor_plan_is_showing
        ? { name: 'Hide', onClick: () => { setFloor({ ...floor, floor_plan_is_showing: false }); setShowFloorPlanButtons(false); }, icon: faEyeSlash, variant: 'DEFAULT' }
        : { name: 'Show', onClick: () => { setFloor({ ...floor, floor_plan_is_showing: true }); setShowFloorPlanButtons(false); }, icon: faEye, variant: 'DEFAULT' };
    const uploadOrRemove = floor.floor_plan_image || floor.floor_plan_url
        ? {
            name: 'Remove',
            onClick: () => {
                setFloor({ ...floor, floor_plan_image: '', floor_plan_url: '', floor_plan_scale: 1 });
                setShowFloorPlanButtons(false);
            },
            confirmText: 'Are you sure you want to remove the uploaded floor plan?',
            icon: faMinusCircle,
            variant: 'DEFAULT'
        }
        : {
            name: 'Upload',
            onClick: () => {
                var _a;
                (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
            },
            icon: faUpload,
            variant: 'DEFAULT'
        };
    return React.createElement(React.Fragment, null,
        React.createElement(CanvasNav, { title: 'Floor plan', headerContent: React.createElement("div", { className: 'p-1 flex w-full' },
                React.createElement(Slider, { min: 0, max: 1, step: 0.1, value: planOpacity, onChange: setPlanOpacity })), items: [
                showOrHide,
                uploadOrRemove,
                {
                    name: 'Close',
                    onClick: () => {
                        // fileInputRef.current?.click()
                        setShowFloorPlanButtons(false);
                    },
                    icon: faTimes,
                    variant: 'DEFAULT'
                }
            ] }),
        React.createElement(FloorPlanInput, { inputRef: fileInputRef, setTempImageAndScale: setTempImageAndScale, setStageStep: setStageStep }));
};
