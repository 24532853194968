import { setError } from '../../components/indicators_and_messaging/toast';
import { apiUrl, axiosPatch, axiosPost, axiosPostV2, client } from '../axios';
import { DEFAULT_PROPERTY } from './property';
import { AuthSDK } from '../utils/auth_provider';
export const getAddressIncludingPostcode = (lead) => {
    return [lead.property.address, lead.property.postcode].filter(x => !!x).join(', ');
};
export const DEFAULT_LEAD = {
    property: DEFAULT_PROPERTY,
    epcId: '',
    epc_scotland_id: '',
    status: 'New',
    epcChanges: {
        insulation: false,
        loftConversion: false,
        extension: false,
        replacedWindows: false,
        other: false,
        noChanges: false
    }
};
// Mapping of override keys to their corresponding default keys
export const DEFAULT_KEYS = {
    designTempOverride: 'designTempDefault',
    internalTempOverride: 'internalTempDefault',
    airChangeOverride: 'airChangesDefault',
    externalWallUValueOverride: 'externalWallDefault',
    partyWallUValueOverride: 'partyWallDefault',
    windowsUValueOverride: 'windowDefault',
    floorUValueOverride: 'floorDefault',
    roofUValueOverride: 'roofDefault'
};
export const leadWithNewOverride = (lead, defaults, key, value) => {
    // If we're dealing with a key that has a corresponding default value, we need to handle it differently
    if (DEFAULT_KEYS[key]) {
        // If the value is undefined, we want to remove the override (it will be set to the default value by the calculateEstimate function)
        // If the value is the same as the corresponding default value, we want to remove the override (same as above)
        // Otherwise, we want to set the override to the new value.
        const defaultValue = defaults[DEFAULT_KEYS[key]];
        const newValue = value ? (parseFloat(value) === defaultValue) ? undefined : parseFloat(value) : undefined;
        return {
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    [key]: newValue
                }
            }
        };
    }
    else {
        // We don't need to worry about defaults here so we can just set the override to the new value.
        return {
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    [key]: value
                }
            }
        };
    }
};
export const duplicateLead = async (lead, companyUUID, name) => {
    const result = await axiosPostV2(`teams/${companyUUID}/leads/${lead.uuid}/duplicate`, { name });
    return { ...result.data.attributes, uuid: result.data.uuid };
};
export const postLead = async (lead, companyUUID, adminEstimate) => {
    // if (lead.houseNotes.length > 0) lead.notes = lead.houseNotes + '. ' + lead.notes;
    const attributes = Object.fromEntries(Object.entries(lead).filter(e => !['version', 'id', 'extra', 'houseNotes', 'epcData'].includes(e[0])));
    const postRequest = { ...attributes };
    return await axiosPost(`teams/${companyUUID}/leads${adminEstimate ? `?adminEstimate=${adminEstimate}` : ''}`, postRequest, 'leads');
};
export const patchLead = async (uuid, lead, companyUUID) => {
    const attributes = Object.fromEntries(Object.entries(lead).filter(e => !['epcData', 'epc_scotland', 'lead_line_items'].includes(e[0])));
    const patchRequest = { ...attributes };
    await axiosPatch(`teams/${companyUUID}/leads/${uuid}`, patchRequest, uuid.toString(), 'leads');
};
export const searchLeads = async (companyUUID, searchText) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/search?query=${searchText}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return result.data.data.map(x => ({ ...x.attributes, uuid: x.uuid }));
    }
    catch (e) {
        console.error('Error loading Leads', e);
        return undefined;
    }
};
export const getLeads = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return result.data.data.map(x => ({ ...x.attributes, uuid: x.uuid }));
    }
    catch (e) {
        console.error('Error loading Leads', e);
        return undefined;
    }
};
export const getLead = async (leadUUID, companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return { ...result.data.data.attributes, uuid: result.data.data.uuid };
    }
    catch (e) {
        console.error('Error loading Lead', e);
        return undefined;
    }
};
export const getEmailTextForLead = async (templateName, leadUUID, companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}/get_email_text/${templateName}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return result.data;
    }
    catch (e) {
        setError(e);
    }
};
export const sendEstimate = async (leadUUID, companyUUID, emailHTML) => {
    try {
        await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/send_estimate`, { email_html: emailHTML });
    }
    catch (e) {
        setError(e);
    }
};
export const sendNudgeEmail = async (leadUUID, estimateUUID, companyUUID, emailHTML) => {
    try {
        await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/send_nudge`, { email_html: emailHTML, quoteUUID: estimateUUID });
    }
    catch (e) {
        setError(e);
    }
};
export const sendDeclined = async (leadUUID, companyUUID, message) => {
    try {
        await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/send_declined`, { email_html: message });
    }
    catch (e) {
        setError(e);
    }
};
