import React, { useEffect, useState } from 'react';
import { insertCalculatedEstimate } from '../../../../code/models/calculated_estimate';
import { getCompanyEstimateCoverNote, getCompanyEstimateEmailTemplate } from '../../../../code/models/company';
import { renderHTMLReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderEstimateContactName, TTPlaceholderEstimateContactPhone, TTPlaceholderHeatPumpModel, TTPlaceholderInstallerName, TTPlaceholderViewEstimate } from '../../../../code/tiptap_placeholders';
import { numberFormat } from '../../../../code/number_format';
import { formatPrice } from '../../../../code/format_price';
import { Modal } from '../../../../components/containers/modal';
import { logEvent } from '../../../../code/log_event';
import { sendEstimate } from '../../../../code/models/lead';
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap';
import { calculateQuote } from '../../../../code/calculate_quote';
import { sum, noop } from 'lodash';
import { Text } from '../../../../components/content_display/text';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Link } from '../../../../components/buttons/link';
import { useNavigate } from 'react-router-dom';
import { ContactCardWrapper } from '../../../estimate_page';
import { determineBestTextShade, DEFAULT_BRAND_COLOUR_PRIMARY } from '../../../../code/utils/colour_utils';
import { Button } from '../../../../components/buttons/button';
import { Heading } from '../../../../components/content_display/heading';
export const SendEstimateModalBlock = ({ setEstimates, isVisible, setIsVisible, adminContext, estimate, calculatedEstimate, lead, setLead }) => {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const [customCoverNoteJSON, setCustomCoverNoteJSON] = useState(getCompanyEstimateCoverNote(((_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.calculated_estimate_cover_note) || ''));
    const [emailHTML, setEmailHTML] = useState('');
    const [estimateToSend, setEstimateToSend] = useState();
    const [calculatedQuote] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    const insertEstimate = async (setEstimates, companyUUID, estimate, lead) => {
        await insertCalculatedEstimate(estimate, calculatedQuote, companyUUID);
        setEstimates(prev => [...prev, estimate]);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return;
        const estimateToSend = {
            ...calculatedEstimate,
            uuid: crypto.randomUUID(),
            status: 'Sent',
            created_at: new Date().toString()
        };
        // the email template used to send the estimate. Populate it as soon as we've loaded adminContext and store it in state.
        const companyEstimateEmailTemplate = getCompanyEstimateEmailTemplate(adminContext.data.company.estimate_template);
        const renderedEmailHTML = renderHTMLReplacingPlaceholders(companyEstimateEmailTemplate, {
            [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + ((_d = (_c = (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.public_info) === null || _d === void 0 ? void 0 : _d.subdomain) + '/estimate/' + estimateToSend.uuid,
            [TTPlaceholderCustomerName.id]: estimateToSend.name,
            [TTPlaceholderCustomerAddress.id]: `${estimateToSend.address}, ${estimateToSend.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: estimateToSend.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(estimateToSend.co2_saved_kg),
            [TTPlaceholderCompanyName.id]: (_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.name,
            [TTPlaceholderCompanyPhone.id]: (_k = (_j = (_h = adminContext.data) === null || _h === void 0 ? void 0 : _h.company) === null || _j === void 0 ? void 0 : _j.public_info) === null || _k === void 0 ? void 0 : _k.phone
        });
        setEmailHTML(renderedEmailHTML);
        setEstimateToSend(estimateToSend);
    }, [adminContext, calculatedEstimate]);
    if (!((_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company) || !estimateToSend)
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    return React.createElement(Modal, { title: 'Send estimate', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            var _a, _b, _c, _d, _e, _f;
            logEvent({
                name: 'Send Estimate Clicked',
                properties: {}
            }, (_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info) === null || _c === void 0 ? void 0 : _c.subdomain) !== null && _d !== void 0 ? _d : '');
            const estimateWithOptionalCoverNote = {
                ...estimateToSend,
                cover_note: ((_f = (_e = adminContext.data.company) === null || _e === void 0 ? void 0 : _e.public_info.calculated_estimate_cover_note) !== null && _f !== void 0 ? _f : '') !== JSON.stringify(customCoverNoteJSON) ? JSON.stringify(customCoverNoteJSON) : undefined
            };
            // Save the custom cover note to the estimate and store it.
            await insertEstimate(setEstimates, companyUUID, estimateWithOptionalCoverNote, lead);
            await sendEstimate(lead.uuid, companyUUID, emailHTML);
            setLead(prev => ({ ...prev, status: 'Quoted' }));
        }, confirmButtonLabel: "Send" },
        React.createElement("div", { className: 'flex flex-col gap-4 w-full' },
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Email confirmation', size: 'LG' }), input: React.createElement(Text, null,
                    "Send an email to ",
                    React.createElement("span", { className: "font-bold" }, (_c = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _c === void 0 ? void 0 : _c.email),
                    " with a link to this ",
                    React.createElement("span", { className: "font-bold" }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))),
                    " estimate.") }),
            adminContext.data.company.public_info.subdomain &&
                // Need to use a styled div here instead of a text so React doesn't complain when we use <Link> inside a <Text>.
                React.createElement("div", { className: 'text-default' },
                    "You can edit this templated email in your ",
                    React.createElement(Link, { className: 'inline', onClick: () => { navigate(`/${adminContext.data.company.public_info.subdomain}/admin/settings/email-templates/send-estimate`); }, text: 'Settings' }),
                    "."),
            React.createElement(FormLabel, { labelText: 'Cover note', helperText: 'Customise the cover note content shown on this estimate.' }),
            React.createElement("div", { className: 'flex flex-col gap-5' },
                React.createElement(Heading, { size: "lg" },
                    "Hi ",
                    calculatedEstimate.name,
                    "!"),
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: (editor) => {
                        setCustomCoverNoteJSON(editor.getJSON());
                    }, placeholders: [
                        TTPlaceholderInstallerName,
                        TTPlaceholderCompanyName,
                        TTPlaceholderCompanyPhone,
                        TTPlaceholderEstimateContactName,
                        TTPlaceholderEstimateContactPhone
                    ], content: getCompanyEstimateCoverNote(JSON.stringify(customCoverNoteJSON)) }),
                React.createElement(ContactCardWrapper, { hasContent: Boolean(adminContext.data.company.public_info.estimate_contact_name || adminContext.data.company.public_info.estimate_contact_phone || adminContext.data.company.public_info.estimate_contact_role || adminContext.data.company.public_info.estimate_contact_portrait) },
                    React.createElement("div", { className: 'flex justify-start items-center pt-1 gap-4' },
                        adminContext.data.company.public_info.estimate_contact_portrait && React.createElement("img", { src: adminContext.data.company.public_info.estimate_contact_portrait, alt: 'Estimate contact headshot', className: 'rounded-full w-20 h-20 object-cover' }),
                        React.createElement("div", { className: 'flex flex-col gap-1' },
                            React.createElement("div", null,
                                React.createElement(Text, { bold: true, size: 'MD' }, adminContext.data.company.public_info.estimate_contact_name),
                                React.createElement(Text, { size: 'SM' }, adminContext.data.company.public_info.estimate_contact_role)),
                            React.createElement("a", { className: 'text-base text-sky-800 font-bold underline', href: `tel:${adminContext.data.company.public_info.estimate_contact_phone}` }, adminContext.data.company.public_info.estimate_contact_phone))),
                    React.createElement("div", { className: 'grid sm:grid-cols-2 gap-4 pb-1' },
                        React.createElement(Button, { style: {
                                backgroundColor: adminContext.data.company.public_info.colour_primary,
                                borderColor: adminContext.data.company.public_info.colour_primary,
                                color: determineBestTextShade((_d = adminContext.data.company.public_info.colour_primary) !== null && _d !== void 0 ? _d : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                            }, className: (!adminContext.data.company.public_info.no_request_callback) ? 'col-span-1' : 'col-span-full', onClick: noop, disabled: true }, "View cost estimate"),
                        (!adminContext.data.company.public_info.no_request_callback) && React.createElement(Button, { colour: 'LIGHT', onClick: noop, disabled: true }, "Request a callback"))))));
};
