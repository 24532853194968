import React, { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { chain, orderBy } from 'lodash';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { faCheck, faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { updateCompany } from '../../../../../code/models/company';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { HeatPumpModal } from '../heat_pump_modal';
import { priceCalculations } from '../../../../../code/price_calculations';
import { EmptySection } from '../empty_section';
export const HeatPumpsInventory = ({ brandRanges, heatPumps, addHeatPumps, deleteHeatPump, updateHeatPump, company, setCompany, packs }) => {
    const [modalHeatPumpId, setModalHeatPumpId] = useState();
    const [addHeatPumpModalVisible, setAddHeatPumpModalVisible] = useState(false);
    const [selectedRangeId, setSelectedRangeId] = useState('');
    const [addRangeModalVisible, setAddRangeModalVisible] = useState(false);
    const [selectedHeatPumpIds, setSelectedHeatPumpIds] = useState([]);
    const rangeHeatPumps = brandRanges.flatMap(x => x.range_heat_pumps).filter(x => x.deleted_at === null);
    const heatPumpsWithRange = heatPumps.map(x => ({ ...x, range_heat_pump: rangeHeatPumps.find(rhp => rhp.uuid === x.range_heat_pump_uuid) }));
    const heatsPumpsWithBrand = heatPumpsWithRange.map(x => ({ ...x, range_heat_pump: { ...x.range_heat_pump, brand_range: brandRanges.find(br => { var _a; return br.uuid === ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid); }) } }));
    const hydratedHeatPumps = chain(orderBy(heatsPumpsWithBrand, x => { var _a; return (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.name; }))
        .groupBy(x => { var _a, _b, _c; return (_c = (_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''; })
        .map((values, key) => ({ key, values }))
        .value();
    const modalHeatPump = heatPumpsWithRange.find(x => x.uuid === modalHeatPumpId);
    const heatPumpsColumns = [
        { key: 'name', name: 'Name' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    const rangeHeatPumpColumns = [
        { key: 'name', name: 'Name' },
        { name: 'Heat Capacity @ 45°C (kW)', render: (row) => React.createElement("div", null, getHeatPumpCapacityAtOutsideTempAndFlowTemp(row, -3, 45).capacityKw) }
    ];
    const setDefaultRange = async (uuid) => {
        if (!uuid)
            return;
        const updatedCompany = { ...company, default_brand_range_uuid: uuid };
        await updateCompany(updatedCompany);
        setCompany(updatedCompany);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "space-y-6" },
            React.createElement("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full" },
                React.createElement(Heading, { size: "2xl" }, "Heat pumps"),
                React.createElement(Button, { colour: 'DARK', onClick: () => setAddRangeModalVisible(true), iconLeft: faPlus }, "Add range")),
            !hydratedHeatPumps.length
                ? React.createElement(EmptySection, { title: 'No heat pumps added', description: 'Add heat pumps to your inventory to create estimates and quotes for your customers.', button: React.createElement(Button, { onClick: () => setAddRangeModalVisible(true), iconLeft: faPlus }, "Add range") })
                : hydratedHeatPumps.map((brandRange, index) => {
                    var _a;
                    return (React.createElement(Section, { title: React.createElement("div", { className: 'flex items-center justify-between gap-3 w-full' },
                            React.createElement("div", { className: 'flex items-center gap-2' },
                                React.createElement(Heading, { size: "xl" }, brandRange.key),
                                ((_a = brandRange.values[0].range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid) === company.default_brand_range_uuid && React.createElement(Badge, { color: "LIGHT", text: "Default range" })),
                            React.createElement(DropdownMenu, { items: [
                                    { label: 'Make default range', onClick: () => { var _a; return setDefaultRange((_a = brandRange.values[0].range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid); }, icon: faCheck }
                                    // { label: 'Delete range', onClick: noop, icon: faTrashAlt } // Not currently implemented
                                ] })), key: index },
                        React.createElement(InventoryTable, { rows: brandRange.values
                                .sort((a, b) => getHeatPumpCapacityAtOutsideTempAndFlowTemp(a.range_heat_pump, -3, 45).capacityKw - getHeatPumpCapacityAtOutsideTempAndFlowTemp(b.range_heat_pump, -3, 45).capacityKw)
                                .map(x => {
                                const packForHeatPump = packs.find(pack => pack.uuid === x.default_pack_uuid);
                                return {
                                    name: React.createElement("div", { className: "flex flex-col gap-2 items-start" },
                                        React.createElement(Text, { size: "SM" }, x.name),
                                        packForHeatPump && React.createElement("div", { className: 'flex gap-1 items-center' },
                                            React.createElement(Badge, { color: "LIGHT", text: packForHeatPump.name }))),
                                    costPrice: React.createElement(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => updateHeatPump(priceCalculations.updateCostPrice(x, parseFloat(value))) }),
                                    markup: React.createElement(Input, { type: 'number', step: 1, value: Math.round(x.markup), setValue: (value) => updateHeatPump(priceCalculations.updateMarkup(x, parseFloat(value))), postfix: '%' }),
                                    customerPrice: React.createElement(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => updateHeatPump(priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value))), prefix: '\u00A3' }),
                                    menu: React.createElement(DropdownMenu, { items: [
                                            { label: 'Edit', onClick: () => setModalHeatPumpId(x.uuid), icon: faEdit },
                                            { label: 'Delete', onClick: () => deleteHeatPump(x.uuid), icon: faTrashAlt, confirmText: 'Are you sure you want to delete this heat pump?' }
                                        ] })
                                };
                            }), columns: heatPumpsColumns })));
                })),
        addRangeModalVisible && React.createElement(Modal, { allowOverflow: true, confirmButtonLabel: 'Continue', visible: true, setVisible: () => setAddRangeModalVisible(false), title: 'Add a heat pump range', onConfirm: () => {
                setAddRangeModalVisible(false);
                setAddHeatPumpModalVisible(true);
            } },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement(Select, { dataCy: "add_dropdown", filter: true, selectedKey: selectedRangeId, options: orderBy(brandRanges.map(x => ({ key: x.uuid, value: x.name })), x => x.value), setSelectedKey: setSelectedRangeId }),
                React.createElement("div", { className: "text-gray-600 text-xs" },
                    "Can't find the range you are looking for? ",
                    React.createElement("a", { target: "_blank", href: "mailto:support@spruce.eco", className: "underline", rel: "noreferrer" }, "Let us know"),
                    " and we'll add it for you."))),
        addHeatPumpModalVisible && React.createElement(Modal, { onConfirm: () => {
                const heatPumpsToAdd = rangeHeatPumps.filter(x => selectedHeatPumpIds.includes(x.uuid)).map(x => ({
                    uuid: crypto.randomUUID(),
                    name: x.name,
                    cost_price: 0,
                    markup: 0,
                    range_heat_pump_uuid: x.uuid
                }));
                addHeatPumps(heatPumpsToAdd);
                setSelectedHeatPumpIds([]);
            }, confirmButtonLabel: 'Add', confirmDisabled: selectedHeatPumpIds.length === 0, visible: true, setVisible: () => setAddHeatPumpModalVisible(false), title: 'Add a heat pump' },
            React.createElement(TableLite, { rows: orderBy(rangeHeatPumps.filter(x => x.brand_range_uuid === selectedRangeId && !heatPumps.map(x => x.range_heat_pump_uuid).includes(x.uuid)), x => getHeatPumpCapacityAtOutsideTempAndFlowTemp(x, -3, 45).capacityKw), rowIdName: 'uuid', columns: rangeHeatPumpColumns, selectedIds: selectedHeatPumpIds, setSelectedIds: setSelectedHeatPumpIds })),
        modalHeatPump && React.createElement(HeatPumpModal, { heatPump: modalHeatPump, setVisible: () => setModalHeatPumpId(undefined), updateHeatPump: updateHeatPump, packs: packs })));
};
