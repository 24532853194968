import React from 'react';
import { ButtonDemo } from './buttons/button_demo';
import { IconDemo } from './buttons/icon_demo';
import { InfoDemo } from './buttons/info_demo';
import { LinkDemo } from './buttons/link_demo';
import { BottomSheetHeaderDemo } from './containers/bottom_sheet_header_demo';
import { ModalDemo } from './containers/modal_demo';
import { SectionDemo } from './containers/section_demo';
import { AccordionDemo } from './content_display/accordion_demo';
import { CardDemo } from './content_display/card_demo';
import { EmptyStateDemo } from './content_display/empty_state_demo';
import { ListItemDemo } from './content_display/list_item_demo';
import { TabDemo } from './content_display/tab_demo';
import { TableDemo } from './content_display/table_demo';
import { TableLiteDemo } from './content_display/table_lite_demo';
import { AlertDemo } from './indicators_and_messaging/alert_demo';
import { BadgeDemo } from './indicators_and_messaging/badge_demo';
import { LoaderDemo } from './indicators_and_messaging/loader_demo';
import { ProgressChartDemo } from './indicators_and_messaging/progress_chart_demo';
import { ToastDemo } from './indicators_and_messaging/toast_demo';
import { CheckboxDemo } from './inputs_and_selections/checkbox_demo';
import { SelectDemo } from './inputs_and_selections/select_demo';
import { FormLabelDemo } from './inputs_and_selections/form_label_demo';
import { InputDemo } from './inputs_and_selections/input_demo';
import { VerticalFormGroupDemo } from './inputs_and_selections/vertical_form_group_demo';
import { PhotoScrollDemo } from './inputs_and_selections/photo_scroll_demo';
import { RadioDemo } from './inputs_and_selections/radio_demo';
import { SliderDemo } from './inputs_and_selections/slider_demo';
import { TextAreaDemo } from './inputs_and_selections/text_area_demo';
import { ToggleDemo } from './inputs_and_selections/toggle_demo';
import { TextDemo } from './content_display/text_demo';
import { HeadingDemo } from './content_display/heading_demo';
import { SummaryTableDemo } from './content_display/summary_table_demo';
import { Header } from '../survey/components/header';
import { HorizontalFormGroupDemo } from './inputs_and_selections/horizontal_form_group_demo';
import { useNavigation } from '../../code/use_navigation';
import { DropdownMenuDemo } from './buttons/dropdown_menu_demo';
export const Components = () => {
    var _a;
    const navbarItems = [
        {
            title: 'Buttons',
            page: undefined,
            urlRegex: undefined,
            url: undefined,
            children: [
                { url: '/components/button', urlRegex: /^\/components\/button$/, title: 'Button', page: React.createElement(ButtonDemo, null), children: [] },
                { url: '/components/icon', urlRegex: /^\/components\/icon$/, title: 'Icon', page: React.createElement(IconDemo, null), children: [] },
                { url: '/components/info', urlRegex: /^\/components\/info$/, title: 'Info', page: React.createElement(InfoDemo, null), children: [] },
                { url: '/components/link', urlRegex: /^\/components\/link$/, title: 'Link', page: React.createElement(LinkDemo, null), children: [] },
                { url: '/components/dropdown_menu', urlRegex: /^\/components\/dropdown_menu$/, title: 'Dropdown menu', page: React.createElement(DropdownMenuDemo, null), children: [] }
            ]
        },
        {
            title: 'Containers',
            page: undefined,
            urlRegex: undefined,
            url: undefined,
            children: [
                { url: '/components/bottom_sheet_header', urlRegex: /^\/components\/bottom_sheet_header$/, title: 'Bottom Sheet Header', page: React.createElement(BottomSheetHeaderDemo, null), children: [] },
                { url: '/components/modal', urlRegex: /^\/components\/modal$/, title: 'Modal', page: React.createElement(ModalDemo, null), children: [] },
                { url: '/components/section', urlRegex: /^\/components\/section$/, title: 'Section', page: React.createElement(SectionDemo, null), children: [] }
            ]
        },
        {
            title: 'Content Display',
            page: undefined,
            urlRegex: undefined,
            url: undefined,
            children: [
                { url: '/components/text', urlRegex: /^\/components\/text$/, title: 'Text', page: React.createElement(TextDemo, null), children: [] },
                { url: '/components/heading', urlRegex: /^\/components\/heading$/, title: 'Heading', page: React.createElement(HeadingDemo, null), children: [] },
                { url: '/components/accordion', urlRegex: /^\/components\/accordion$/, title: 'Accordion', page: React.createElement(AccordionDemo, null), children: [] },
                { url: '/components/card', urlRegex: /^\/components\/card$/, title: 'Card', page: React.createElement(CardDemo, null), children: [] },
                { url: '/components/empty_state', urlRegex: /^\/components\/empty_state$/, title: 'Empty State', page: React.createElement(EmptyStateDemo, null), children: [] },
                { url: '/components/list_item', urlRegex: /^\/components\/list_item$/, title: 'List Item', page: React.createElement(ListItemDemo, null), children: [] },
                { url: '/components/tab', urlRegex: /^\/components\/tab$/, title: 'Tab', page: React.createElement(TabDemo, null), children: [] },
                { url: '/components/table_lite', urlRegex: /^\/components\/table_lite$/, title: 'Table Lite', page: React.createElement(TableLiteDemo, null), children: [] },
                { url: '/components/table', urlRegex: /^\/components\/table$/, title: 'Table', page: React.createElement(TableDemo, null), children: [] },
                { url: '/components/summary_table', urlRegex: /^\/components\/summary_table$/, title: 'Summary Table', page: React.createElement(SummaryTableDemo, null), children: [] }
            ]
        },
        {
            title: 'Indicators & Messaging',
            page: undefined,
            urlRegex: undefined,
            url: undefined,
            children: [
                { url: '/components/alert', urlRegex: /^\/components\/alert$/, title: 'Alert', page: React.createElement(AlertDemo, null), children: [] },
                { url: '/components/badge', urlRegex: /^\/components\/badge$/, title: 'Badge', page: React.createElement(BadgeDemo, null), children: [] },
                { url: '/components/loader', urlRegex: /^\/components\/loader$/, title: 'Loader', page: React.createElement(LoaderDemo, null), children: [] },
                { url: '/components/progress_chart', urlRegex: /^\/components\/progress_chart$/, title: 'Progress Chart', page: React.createElement(ProgressChartDemo, null), children: [] },
                { url: '/components/toast', urlRegex: /^\/components\/toast$/, title: 'Toast', page: React.createElement(ToastDemo, null), children: [] }
            ]
        },
        {
            title: 'Inputs & Selectors',
            page: undefined,
            urlRegex: undefined,
            url: undefined,
            children: [
                { url: '/components/checkbox', urlRegex: /^\/components\/checkbox$/, title: 'Checkbox', page: React.createElement(CheckboxDemo, null), children: [] },
                { url: '/components/select', urlRegex: /^\/components\/select$/, title: 'Select', page: React.createElement(SelectDemo, null), children: [] },
                { url: '/components/form_label', urlRegex: /^\/components\/form_label$/, title: 'Form Label', page: React.createElement(FormLabelDemo, null), children: [] },
                { url: '/components/input_group', urlRegex: /^\/components\/input_group$/, title: 'Input Group', page: React.createElement(VerticalFormGroupDemo, null), children: [] },
                { url: '/components/input', urlRegex: /^\/components\/input$/, title: 'Input', page: React.createElement(InputDemo, null), children: [] },
                { url: '/components/photo_scroll', urlRegex: /^\/components\/photo_scroll$/, title: 'Photo Scroll', page: React.createElement(PhotoScrollDemo, null), children: [] },
                { url: '/components/radio', urlRegex: /^\/components\/radio$/, title: 'Radio', page: React.createElement(RadioDemo, null), children: [] },
                { url: '/components/slider', urlRegex: /^\/components\/slider$/, title: 'Slider', page: React.createElement(SliderDemo, null), children: [] },
                { url: '/components/text_area', urlRegex: /^\/components\/text_area$/, title: 'Text Area', page: React.createElement(TextAreaDemo, null), children: [] },
                { url: '/components/toggle_input_group', urlRegex: /^\/components\/toggle_input_group$/, title: 'Toggle Input Group', page: React.createElement(HorizontalFormGroupDemo, null), children: [] },
                { url: '/components/toggle', urlRegex: /^\/components\/toggle$/, title: 'Toggle', page: React.createElement(ToggleDemo, null), children: [] }
            ]
        }
    ];
    const { currentPath, navigateTo } = useNavigation();
    const page = (_a = recursiveFilter(currentPath, navbarItems)) === null || _a === void 0 ? void 0 : _a.page;
    return React.createElement("div", { className: 'h-full flex' },
        React.createElement("div", { className: 'overflow-y-auto border-r border-gray-200 flex-shrink-0' },
            React.createElement(RenderItems, { menuItems: navbarItems, depth: 1, url: currentPath, handleUrlChange: navigateTo })),
        React.createElement("div", { className: 'flex-grow p-8 max-w-3xl overflow-y-auto' }, page !== null && page !== void 0 ? page : React.createElement(Header, { content: 'Spruce Component Library' })));
};
const RenderItems = ({ menuItems, depth, url, handleUrlChange }) => {
    return menuItems.map(x => {
        var _a;
        return React.createElement("div", { className: `flex flex-col ${x.page ? 'cursor-pointer' : ''}`, onClick: () => x.page && handleUrlChange(x.url), key: x.title },
            React.createElement("div", { style: { paddingLeft: depth * 10 }, className: `p-4 ${x.children.length > 0 ? 'font-bold text-gray-900' : ''} ${((_a = x.urlRegex) === null || _a === void 0 ? void 0 : _a.test(url)) ? 'underline' : ''} border-b border-gray-200` }, x.title),
            x.children.length > 0 && React.createElement("div", null,
                React.createElement(RenderItems, { menuItems: x.children, depth: depth + 1, url: url, handleUrlChange: handleUrlChange })));
    });
};
const recursiveFilter = (url, menuItems) => {
    var _a;
    for (const item of menuItems) {
        if ((_a = item.urlRegex) === null || _a === void 0 ? void 0 : _a.test(url))
            return item;
        const child = recursiveFilter(url, item.children);
        if (child)
            return child;
    }
};
