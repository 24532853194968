import React from 'react';
import { Text } from '../../../../components/content_display/text';
export const InfoCard = ({ title, value, info }) => {
    return (React.createElement("div", { className: `px-4 py-3 bg-gray-100 rounded-md ${info && 'cursor-pointer'}` },
        React.createElement(Text, { size: "XS", bold: true },
            title,
            " ",
            info && info),
        React.createElement(Text, { size: "MD" }, value)));
};
