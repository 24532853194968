import { BubbleMenu, EditorContent, NodeViewWrapper, ReactNodeViewRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import React, { useCallback, useEffect } from 'react';
import { Editor, mergeAttributes, Node } from '@tiptap/core';
import { Button } from '../buttons/button';
export function tiptapHTMLToJSON(html) {
    // a hack to get the default estimate email text in Tiptap JSON format: apply HTML and then get JSON
    const editor = new Editor({
        extensions: TiptapExtensions,
        content: html
    });
    return editor.getJSON();
}
const InlinePlaceholderWrapper = (props) => {
    return (React.createElement(NodeViewWrapper, { as: 'span' },
        React.createElement("span", { className: 'bg-amber-100 rounded' }, props.node.attrs.label)));
};
const ButtonPlaceholderWrapper = (props) => {
    return (React.createElement(NodeViewWrapper, { as: 'div' },
        React.createElement("div", { className: 'table m-auto text-center' },
            React.createElement(Button, null, props.node.attrs.label))));
};
const ButtonPlaceholderComponent = Node.create({
    name: 'button-ph',
    inline: false,
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            id: { default: '' },
            label: { default: '' }
        };
    },
    parseHTML() { return [{ tag: 'button-ph' }]; },
    renderHTML({ HTMLAttributes }) { return ['button-ph', mergeAttributes(HTMLAttributes)]; },
    addNodeView() { return ReactNodeViewRenderer(ButtonPlaceholderWrapper); }
});
const InlinePlaceholderComponent = Node.create({
    name: 'inline-ph',
    inline: true,
    group: 'inline',
    atom: true,
    addAttributes() {
        return {
            id: { default: '' },
            label: { default: '' }
        };
    },
    parseHTML() { return [{ tag: 'inline-ph' }]; },
    renderHTML({ HTMLAttributes }) { return ['inline-ph', mergeAttributes(HTMLAttributes)]; },
    addNodeView() { return ReactNodeViewRenderer(InlinePlaceholderWrapper); }
});
// share extensions between all editors and for background rendering
export const TiptapExtensions = [
    StarterKit,
    Link.configure({
        openOnClick: false,
        autolink: true
    }),
    InlinePlaceholderComponent,
    ButtonPlaceholderComponent
];
export const Tiptap = ({ editable, className, content, onUpdateCallback, placeholders, noPlaceholderButtons, onEditorInit }) => {
    const editor = useEditor({
        editable,
        extensions: TiptapExtensions,
        editorProps: {
            attributes: {
                class: className
            }
        },
        onUpdate: ({ editor }) => {
            onUpdateCallback(editor);
        },
        content
    });
    // apply className
    useEffect(() => {
        if (!editor) {
            return;
        }
        editor === null || editor === void 0 ? void 0 : editor.setOptions({
            editorProps: {
                attributes: { class: className }
            }
        });
    }, [className]);
    useEffect(() => {
        if (!onEditorInit) {
            return;
        }
        if (editor) {
            onEditorInit(editor);
        }
    }, [onEditorInit, editor]);
    useEffect(() => {
        if (!editor) {
            return;
        }
        editor === null || editor === void 0 ? void 0 : editor.setEditable(editable);
    }, [editable]);
    const setLink = useCallback(() => {
        if (!editor) {
            return null;
        }
        const previousUrl = editor.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);
        // cancelled
        if (url === null) {
            return;
        }
        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run();
            return;
        }
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run();
    }, [editor]);
    if (!editor) {
        return null;
    }
    return (React.createElement("div", null,
        editor && React.createElement(BubbleMenu, { className: "flex flex-row gap-2 bg-black p-1 rounded text-sm", tippyOptions: { duration: 100 }, editor: editor },
            React.createElement("button", { onClick: () => editor.chain().focus().toggleBold().run(), className: `${editor.isActive('bold') ? 'text-blue-300' : ''} border-0 bg-none text-white p-1 hover:text-gray-300` }, "Bold"),
            React.createElement("button", { onClick: () => editor.chain().focus().toggleItalic().run(), className: `${editor.isActive('italic') ? 'text-blue-300' : ''} border-0 bg-none text-white p-1 hover:text-gray-300` }, "Italic"),
            React.createElement("button", { onClick: setLink, className: `${editor.isActive('link') ? 'text-blue-300' : ''} border-0 bg-none text-white p-1 hover:text-gray-300` }, "Link"),
            editor.isActive('link') &&
                React.createElement("button", { onClick: () => editor.chain().focus().unsetLink().run(), className: `${editor.isActive('link') ? 'text-blue-300' : ''} border-0 bg-none text-white p-1 hover:text-gray-300` }, "Unlink")),
        React.createElement("div", null,
            placeholders && !noPlaceholderButtons && React.createElement("div", { className: "pb-2 justify-start items-start gap-2.5 flex overflow-x-auto relative no-scrollbar md:flex-wrap" }, placeholders === null || placeholders === void 0 ? void 0 : placeholders.map((control) => {
                return React.createElement("button", { key: control.id, onClick: () => editor.chain().focus().insertContent(control.code).run(), className: `${control.type === 'button-ph' ? 'hover:bg-black text-white bg-gray-700' : 'hover:bg-amber-300 bg-amber-100'} border-0 bg-none py-1 px-2 whitespace-nowrap text-nowrap text-sm rounded` }, control.label);
            })),
            React.createElement(EditorContent, { editor: editor, className: `${editable ? '' : 'bg-gray-100'}` }))));
};
