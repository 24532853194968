import React, { useState } from 'react';
import { updateDefaultMaterial } from '../../../code/models/material';
import { MaterialsSelector } from './materials_selector';
import { DesignPageHeader } from '../design/components/design_page_header';
import { MaterialInputField } from './material_input_field';
import { CompleteButton } from '../survey/complete_button';
import { CustomMaterialPopup } from './custom_material_popup';
export const DefaultMaterialsTab = ({ materials, setMaterials, setSurvey, survey, materialsLayers, companyUUID, isOffline, allSynced }) => {
    const [selectedMaterialsSet, setSelectedMaterialsSet] = React.useState(survey.default_materials);
    // materials selector props
    const [selectorProps, setSelectorProps] = useState();
    const [innerPages, setInnerPages] = useState(['MATERIAL_HOME']);
    const [tempCustomMaterial, setTempCustomMaterial] = useState();
    const [customMaterialHeader, setCustomMaterialHeader] = useState();
    const innerPage = innerPages[innerPages.length - 1];
    const setInnerPage = (page) => setInnerPages(prev => [...prev, page]);
    const page = 'MATERIALS';
    const handleMaterialUpdate = (material) => {
        setSelectedMaterialsSet(prev => {
            return updateDefaultMaterial(survey, setSurvey, material);
        });
    };
    if (!selectedMaterialsSet || !materials)
        return null;
    if (innerPage === 'MATERIALS') {
        return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
            React.createElement(DesignPageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Choose a material', onBack: () => {
                    setInnerPages(prev => prev.slice(0, -1));
                } }),
            React.createElement("div", { className: 'overflow-y-auto' },
                React.createElement(MaterialsSelector, { groupedProps: selectorProps, setGroupedProps: setSelectorProps, setMaterialsCallback: setMaterials, setPage: setInnerPage, onBack: () => setInnerPages(prev => prev.slice(0, -1)), setTempCustomMaterial: setTempCustomMaterial, companyUUID: companyUUID })));
    }
    if (innerPage === 'CUSTOM_MATERIAL') {
        return React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement("div", { className: 'px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white' }, customMaterialHeader),
            React.createElement("div", { className: 'overflow-y-auto' },
                React.createElement(CustomMaterialPopup, { material: tempCustomMaterial, materialsLayers: materialsLayers, setMaterial: setTempCustomMaterial, onSave: (newMaterial) => {
                        // NB! do not override materials with the `materials` variable!
                        // because it contains filtered values for the current surface and will override the global materials list
                        setMaterials(prev => ([...prev, newMaterial]));
                        setSelectorProps(prev => ({ ...prev, materials: [...prev.materials, newMaterial], selectedMaterial: newMaterial }));
                        if (selectorProps.onSelectCallback) {
                            // trigger global callback
                            selectorProps.onSelectCallback(newMaterial);
                        }
                    }, setVisible: () => setInnerPages(prev => prev.slice(0, -1)), setHeader: setCustomMaterialHeader, onBack: () => setInnerPages(prev => prev.slice(0, -1)) })));
    }
    return React.createElement("div", { className: 'flex flex-col h-full' },
        React.createElement(DesignPageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Materials', onBack: () => window.history.back(), completed: survey.completed_sections.includes(page) }),
        React.createElement("div", { className: 'p-5 w-full bg-white flex-col justify-start items-start gap-6 inline-flex overflow-y-auto flex-grow' },
            React.createElement("div", { className: "flex-col justify-start items-start gap-3 flex" },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Default materials"),
                React.createElement("div", { className: "" }, "Set default materials for the property. You can override these in each room.")),
            React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Walls"),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "External wall"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'External wall',
                            surfaceType: 'external-wall',
                            materials,
                            ageBand: survey.age_band,
                            selectedMaterial: selectedMaterialsSet.externalWall,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Party wall"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Party wall',
                            surfaceType: 'party-wall',
                            materials,
                            ageBand: survey.age_band,
                            selectedMaterial: selectedMaterialsSet.partyWall,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Internal wall"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Internal wall',
                            surfaceType: 'internal-wall',
                            materials,
                            ageBand: survey.age_band,
                            selectedMaterial: selectedMaterialsSet.internalWall,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage }))),
            React.createElement("div", { className: "self-stretch h-px border border-gray-200" }),
            React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Floors"),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Ground floor"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Ground floor',
                            surfaceType: 'ground-floor',
                            materials,
                            ageBand: survey.age_band,
                            selectedMaterial: selectedMaterialsSet.groundFloor,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Intermediate floors/ceilings"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Intermediate floors/ceilings',
                            surfaceType: 'intermediate-floor-and-ceiling',
                            materials,
                            ageBand: survey.age_band,
                            selectedMaterial: selectedMaterialsSet.intermediateFloorAndCeiling,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage }))),
            React.createElement("div", { className: "self-stretch h-px border border-gray-200" }),
            React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Roof"),
                React.createElement(MaterialInputField, { selectorProps: {
                        title: 'Roof',
                        surfaceType: 'roof',
                        materials,
                        ageBand: survey.age_band,
                        selectedMaterial: selectedMaterialsSet.roof,
                        onSelectCallback: handleMaterialUpdate
                    }, setMsProps: setSelectorProps, setPage: setInnerPage })),
            React.createElement("div", { className: "self-stretch h-px border border-gray-200" }),
            React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Doors"),
                React.createElement(MaterialInputField, { selectorProps: {
                        title: 'Door',
                        surfaceType: 'door',
                        materials,
                        ageBand: survey.age_band,
                        selectedMaterial: selectedMaterialsSet.door,
                        onSelectCallback: handleMaterialUpdate
                    }, setMsProps: setSelectorProps, setPage: setInnerPage })),
            React.createElement("div", { className: "self-stretch h-px border border-gray-200" }),
            React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Windows"),
                React.createElement(MaterialInputField, { selectorProps: {
                        title: 'Window',
                        surfaceType: 'window',
                        materials,
                        ageBand: survey.age_band,
                        selectedMaterial: selectedMaterialsSet.window,
                        onSelectCallback: handleMaterialUpdate
                    }, setMsProps: setSelectorProps, setPage: setInnerPage }))),
        React.createElement(CompleteButton, { page: page, pageChecks: [], survey: survey, setSurvey: setSurvey, onBack: async () => window.history.back() }));
};
