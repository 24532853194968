const roundToTwoDecimalPlaces = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
export const priceCalculations = {
    calculateCustomerPrice: (costPrice, markup) => {
        return roundToTwoDecimalPlaces(costPrice * (1 + markup / 100));
    },
    calculateCostPrice: (customerPrice, markup) => {
        return roundToTwoDecimalPlaces(customerPrice / (1 + markup / 100));
    },
    updateCostPrice: (item, newCostPrice) => {
        return { ...item, cost_price: roundToTwoDecimalPlaces(newCostPrice) };
    },
    updateMarkup: (item, newMarkup) => {
        return { ...item, markup: roundToTwoDecimalPlaces(newMarkup) };
    },
    updateCustomerPrice: (part, newCustomerPrice) => {
        const newCostPrice = priceCalculations.calculateCostPrice(newCustomerPrice, part.markup);
        return { ...part, cost_price: roundToTwoDecimalPlaces(newCostPrice) };
    },
    updateCostPriceAndMarkupToMatchCustomerPrice: (part, newCustomerPrice) => {
        return { ...part, cost_price: newCustomerPrice, markup: 0 };
    }
};
