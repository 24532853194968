import React from 'react';
import { Emoji } from './emoji';
const defineMajorStyling = (size) => {
    switch (size) {
        case 'SM':
            return 'text-xs font-bold text-default';
        case 'LG':
            return 'text-md text-bold font-bold';
        case 'MD':
        default:
            return 'text-sm font-bold text-default';
    }
};
const defineMinorStyling = (size) => {
    switch (size) {
        case 'SM':
            return 'text-xs text-default';
        case 'LG':
            return 'text-md text-default';
        case 'MD':
        default:
            return 'text-sm text-default';
    }
};
export const SummaryTable = ({ rows }) => {
    if (!rows)
        return;
    return React.createElement("div", { className: 'w-full divide divide-y border-light' }, rows.map(({ key, value, size = 'MD', emoji, infoButton }, i) => React.createElement("div", { key: i, className: 'flex flex-col gap-1 sm:flex-row justify-between py-2 last:pb-0' },
        React.createElement("div", { className: `flex gap-2 items-center ${defineMajorStyling(size)}` },
            emoji && React.createElement(Emoji, { emoji: emoji }),
            React.createElement("p", null,
                key,
                " ",
                infoButton)),
        React.createElement("p", { className: defineMinorStyling(size) }, value))));
};
