import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { ClickableCard } from '../../../components/content_display/card';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { sum } from 'lodash';
import { TableLite } from '../../../components/content_display/table_lite';
import { getAverageRoomHeightM, getRoomACH, getRoomTemp, getRoomWatts } from '../../../code/models/heat_loss';
import { numberFormat } from '../../../code/number_format';
import { getAreaM2 } from '../floor/code/utils';
export const HLRHeatlossByRoomPage = () => {
    const hlrContext = React.useContext(HLRContext);
    const roomRows = hlrContext.survey.floors.flatMap((floor, floorIndex) => floor.rooms.flatMap(y => {
        const calculatedArea = getAreaM2(y.walls.map(w => ({ x: w.x, y: w.y })));
        const heatLoss = Math.round(getRoomWatts(y, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey));
        return {
            floorIndex,
            name: y.name,
            roomTemp: getRoomTemp(y, hlrContext.survey),
            ach: getRoomACH(y, hlrContext.survey),
            floorArea: calculatedArea,
            volume: getAverageRoomHeightM(y) * calculatedArea,
            heatLoss,
            heatLossPerUnit: (heatLoss / calculatedArea)
        };
    }));
    const sortedRoomRows = roomRows.sort((a, b) => a.floorIndex !== b.floorIndex
        ? a.floorIndex - b.floorIndex
        : a.name.localeCompare(b.name));
    const elementsTableColumns = [
        {
            name: '',
            key: 'name',
            render: (row) => React.createElement("span", { className: 'text-gray-900 text-xs font-bold' }, row.name)
        },
        { name: 'Room temp', key: 'roomTemp', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                row.roomTemp,
                " \u00B0C") },
        { name: 'ACH*', key: 'ach', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                row.ach,
                " ",
                hlrContext.survey.mvhr_installed && 'x 50 % **') },
        { name: 'Floor area', key: 'floorArea', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                numberFormat(1).format(row.floorArea),
                " m\u00B2") },
        { name: 'Volume', key: 'volume', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                numberFormat(0).format(row.volume),
                " m\u00B3") },
        { name: 'Heat loss', key: 'heatLoss', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                numberFormat(0).format(row.heatLoss),
                " W") },
        { name: 'heat loss per unit area', key: 'heatLossPerUnit', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                numberFormat(0).format(row.heatLossPerUnit),
                " W/m\u00B2") }
    ];
    const totalHeatLossWFromRooms = Math.round(sum(sortedRoomRows.map(x => x.heatLoss)));
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBLock, { section: "Heat loss", title: "Heat loss by room", description: "This section shows the heat loss from each room in your home. These results are used to design the radiators for your new system." }),
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 text-lg font-bold " }, "Total heat loss"),
                React.createElement("div", { className: "text-lg" },
                    totalHeatLossWFromRooms,
                    " W")),
            React.createElement(ProgressChart, { total: totalHeatLossWFromRooms, items: sortedRoomRows.map(x => ({ value: x.heatLoss, name: x.name })) })),
        React.createElement(TableLite, { columns: elementsTableColumns, rows: sortedRoomRows }),
        React.createElement("div", { className: "flex-col flex" },
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "*ACH: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "air changes per hour")),
            hlrContext.survey.mvhr_installed && React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "**MVHR: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "Ventilation heat loss reduced by 50% to account for heat recovery through the MVHR unit")))));
};
