import React, { useContext, useState } from 'react';
import { Section } from '../section';
import { Heading } from '../../../../../components/content_display/heading';
import { ClickableCard } from '../../../../../components/content_display/card';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Modal } from '../../../../../components/containers/modal';
import { orderBy, uniq } from 'lodash';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump';
import { ListItem } from '../../../../../components/content_display/list_item';
import { RadioGroup } from '../../../../../components/inputs_and_selections/radio';
import { AdminContext } from '../../../admin_layout';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { FlowTempSlider } from '../../../../heat_loss/design/pages/emitter_design_page';
import { DEFAULT_FLOW_TEMP_C } from '../../../../../code/calculate_estimate';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { HeatLossSummaryCards } from './heat_loss_estimate';
const SelectHeatPumpModal = ({ visible, setVisible, designTempC, flowTempC, totalHeatLossKW, lead, setLead, adminContext }) => {
    var _a, _b;
    const [rangeFilter, setRangeFilter] = useState('All');
    const ranges = ['All', ...uniq((_a = adminContext.data.heatPumps) === null || _a === void 0 ? void 0 : _a.filter(x => !x.deleted_at).map(x => { var _a, _b; return (_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name; })).filter(x => !!x)];
    const filteredHeatPumps = orderBy((_b = adminContext.data.heatPumps) === null || _b === void 0 ? void 0 : _b.filter(x => (!x.deleted_at)).filter(x => { var _a, _b; return rangeFilter === 'All' || ((_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name) === rangeFilter; }).map(x => {
        const capacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC);
        return {
            uuid: x.uuid,
            name: x.name,
            capacityKw: capacityResult.capacityKw,
            outsideTempC: capacityResult.outsideTempC,
            flowTempC: capacityResult.flowTempC,
            warning: capacityResult.warning
        };
    }), x => x.capacityKw);
    return (React.createElement(Modal, { title: 'Select a heat pump', visible: visible, setVisible: setVisible },
        React.createElement("div", { className: 'flex flex-col w-full gap-5' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: "text-gray-500 text-xs font-semibold uppercase" }, "Range"),
                React.createElement("div", { className: "flex-col gap-2 flex overflow-x-auto" },
                    React.createElement(RadioGroup, { items: ranges.map(x => ({
                            name: x,
                            onClick: () => setRangeFilter(x),
                            variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
                        })) }))),
            React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200 " }, filteredHeatPumps.map(x => React.createElement(ListItem, { onClick: async () => {
                    setLead({ ...lead, heat_pump_uuid: x.uuid });
                    setVisible(false);
                }, key: x.uuid, primaryText: x.name, rightBadge: getCapacityBadge(x, totalHeatLossKW) }))))));
};
const SelectHotWaterCylinderModal = ({ visible, setVisible, lead, setLead, adminContext }) => {
    var _a;
    return (React.createElement(Modal, { title: 'Select a hot water cylinder', visible: visible, setVisible: setVisible },
        React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-light " }, (_a = adminContext.data.hotWaterCylinders) === null || _a === void 0 ? void 0 : _a.filter(x => (!x.deleted_at)).map(x => React.createElement(ListItem, { onClick: async () => {
                setLead({ ...lead, hot_water_cylinder_uuid: x.uuid });
                setVisible(false);
            }, key: x.uuid, primaryText: x.name, rightBadge: React.createElement(Badge, { color: 'LIGHT', text: `${Math.round(x.litres)} litres` }) })))));
};
export const SystemDesign = ({ lead, setLead, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    const adminContext = useContext(AdminContext);
    const [selectHeatPumpModalVisible, setSelectHeatPumpModalVisible] = useState(false);
    const [selectHotWaterCylinderModalVisible, setSelectHotWaterCylinderModalVisible] = useState(false);
    const flowTemp = (_c = (_a = lead.flow_temperature_c) !== null && _a !== void 0 ? _a : (_b = adminContext.data.company) === null || _b === void 0 ? void 0 : _b.default_flow_temp_c) !== null && _c !== void 0 ? _c : DEFAULT_FLOW_TEMP_C;
    const selectedHeatPumpCapacity = getHeatPumpCapacityAtOutsideTempAndFlowTemp((_d = estimate.heatPump) === null || _d === void 0 ? void 0 : _d.range_heat_pump, estimate.designTempC, flowTemp);
    const totalHeatLossKW = estimate.totalWatts / 1000;
    const heatPumpRows = [
        { key: 'Sound power level', value: `${(_e = estimate.heatPump) === null || _e === void 0 ? void 0 : _e.range_heat_pump.sound_power_max_dba} dB(A)` },
        { key: 'Refrigerant', value: (_g = (_f = estimate.heatPump) === null || _f === void 0 ? void 0 : _f.range_heat_pump) === null || _g === void 0 ? void 0 : _g.refrigerant },
        { key: 'Volume', value: React.createElement(React.Fragment, null,
                ((((_k = (_j = (_h = estimate.heatPump) === null || _h === void 0 ? void 0 : _h.range_heat_pump) === null || _j === void 0 ? void 0 : _j.width_mm) !== null && _k !== void 0 ? _k : 0) * ((_o = (_m = (_l = estimate.heatPump) === null || _l === void 0 ? void 0 : _l.range_heat_pump) === null || _m === void 0 ? void 0 : _m.height_mm) !== null && _o !== void 0 ? _o : 0) * ((_r = (_q = (_p = estimate.heatPump) === null || _p === void 0 ? void 0 : _p.range_heat_pump) === null || _q === void 0 ? void 0 : _q.depth_mm) !== null && _r !== void 0 ? _r : 0)) / Math.pow(10, 9)).toFixed(2),
                " m\u00B3") },
        { key: 'Dimensions (WxHxD)', value: `${(_t = (_s = estimate.heatPump) === null || _s === void 0 ? void 0 : _s.range_heat_pump) === null || _t === void 0 ? void 0 : _t.width_mm}mm x ${(_v = (_u = estimate.heatPump) === null || _u === void 0 ? void 0 : _u.range_heat_pump) === null || _v === void 0 ? void 0 : _v.height_mm}mm x ${(_x = (_w = estimate.heatPump) === null || _w === void 0 ? void 0 : _w.range_heat_pump) === null || _x === void 0 ? void 0 : _x.depth_mm}mm` },
        {
            key: `SCOP at ${flowTemp}°C`,
            value: estimate.sCOP > 0 ? `${Math.floor(estimate.sCOP * 100)}%` : React.createElement(Badge, { color: 'RED', text: `${Math.floor(estimate.sCOP * 100)}%` })
        },
        {
            key: `Capacity at ${selectedHeatPumpCapacity.flowTempC}°C (${selectedHeatPumpCapacity.outsideTempC} °C)`,
            value: getCapacityBadge(selectedHeatPumpCapacity, totalHeatLossKW)
        }
    ];
    const setFlowTemp = (flowTemp) => {
        setLead({ ...lead, flow_temperature_c: flowTemp });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { title: 'System design' },
            React.createElement("div", { className: 'space-y-3' },
                React.createElement(HeatLossSummaryCards, { lead: lead, estimate: estimate, columns: 4 }),
                React.createElement(Heading, { size: 'lg' }, "Heat pump model"),
                !estimate.heatPump && React.createElement(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setSelectHeatPumpModalVisible(true) }, "Please select a heat pump"),
                estimate.heatPump &&
                    React.createElement(ClickableCard, { variant: "WHITE", title: estimate.heatPump.name, onClick: () => setSelectHeatPumpModalVisible(true), showIcon: true },
                        React.createElement(SummaryTable, { rows: heatPumpRows }),
                        estimate.heatPump.deleted_at && React.createElement(Alert, { type: 'DANGER' }, "This heat pump has been deleted from your inventory. You won't be able to select it again if you change to a different one."),
                        selectedHeatPumpCapacity.warning && React.createElement(Alert, { type: 'WARNING' },
                            selectedHeatPumpCapacity.warning,
                            "."))),
            React.createElement("div", { className: 'space-y-3' },
                React.createElement(Heading, { size: 'lg' }, "Cylinder model"),
                !estimate.hotWaterCylinder && React.createElement(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setSelectHotWaterCylinderModalVisible(true) }, "Please select a hot water cylinder"),
                estimate.hotWaterCylinder &&
                    React.createElement(ClickableCard, { variant: "WHITE", title: estimate.hotWaterCylinder.name, onClick: () => setSelectHotWaterCylinderModalVisible(true), showIcon: true },
                        React.createElement(SummaryTable, { rows: [
                                { key: 'Capacity', value: `${Math.round(estimate.hotWaterCylinder.litres || 0)} litres` }
                            ] }),
                        ((_y = estimate.hotWaterCylinder) === null || _y === void 0 ? void 0 : _y.deleted_at) && React.createElement(Alert, { type: 'DANGER' }, "This hot water cylinder has been deleted from your inventory. You won't be able to select it again if you change to a different one."))),
            React.createElement(FlowTempSlider, { flowTemp: flowTemp, setFlowTemp: setFlowTemp })),
        selectHeatPumpModalVisible && React.createElement(SelectHeatPumpModal, { visible: selectHeatPumpModalVisible, setVisible: setSelectHeatPumpModalVisible, designTempC: estimate.designTempC, flowTempC: flowTemp, totalHeatLossKW: totalHeatLossKW, lead: lead, setLead: setLead, adminContext: adminContext }),
        selectHotWaterCylinderModalVisible && React.createElement(SelectHotWaterCylinderModal, { visible: selectHotWaterCylinderModalVisible, setVisible: setSelectHotWaterCylinderModalVisible, lead: lead, setLead: setLead, adminContext: adminContext })));
};
const getCapacityBadge = (selectedHeatPumpCapacity, totalHeatLossKW) => {
    if (totalHeatLossKW) {
        return React.createElement(Badge, { color: selectedHeatPumpCapacity.capacityKw >= totalHeatLossKW ? selectedHeatPumpCapacity.warning ? 'YELLOW' : 'GREEN' : 'RED', text: selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW' });
    }
    return React.createElement(Badge, { color: 'LIGHT', text: selectedHeatPumpCapacity.capacityKw.toFixed(1) + ' kW' });
};
