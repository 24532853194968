import React from 'react';
import { EnquiryOverview } from '../sections/enquiry_overview';
import { HeatLossEstimate } from '../sections/heat_loss_estimate';
import { CostEstimate } from '../sections/cost_estimate';
import { OfflinePage } from '../../../offline_page';
import { RequireRole } from '../../../../../require_role';
import { USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR } from '../../../../../code/models/user';
export const InstallerAdminDashboard = ({ lead, setLead, estimate, isOffline }) => {
    if (isOffline)
        return React.createElement(OfflinePage, null);
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR] },
        React.createElement("div", { className: "space-y-6" },
            React.createElement(EnquiryOverview, { lead: lead, estimate: estimate }),
            React.createElement(HeatLossEstimate, { lead: lead, setLead: setLead, estimate: estimate }),
            React.createElement(CostEstimate, { estimate: estimate, lead: lead }))));
};
