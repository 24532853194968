import * as axios from 'axios';
import { setError } from '../components/indicators_and_messaging/toast';
import { AuthSDK } from './utils/auth_provider';
export const url = process.env.API_URL;
const api = 'api/v1/';
export const apiUrl = `${url}${api}`;
export const client = axios.default;
// client.defaults.withCredentials = true;
export const axiosGet = async (relativeUrl) => await axiosGetInner(relativeUrl);
const axiosGetInner = async (relativeUrl) => {
    const result = await client.get(`${apiUrl}${relativeUrl}`, {
        headers: {
            'x-auth-token': AuthSDK.getToken()
        }
    });
    return result.data.data.map(x => ({ ...x.attributes, uuid: x.uuid }));
};
export const axiosPatch = async (relativeUrl, data, id, type) => {
    await axiosPatchInner(relativeUrl, data, id, type);
};
const axiosPatchInner = async (relativeUrl, data, id, type) => {
    await client.patch(`${apiUrl}${relativeUrl}`, {
        data: {
            type,
            attributes: data
        }
    }, {
        headers: {
            'Content-Type': 'application/vnd.api+json',
            'x-auth-token': AuthSDK.getToken()
        }
    });
};
export const axiosPost = async (relativeUrl, data, type) => {
    return await axiosPostInner(relativeUrl, data, type);
};
const axiosPostInner = async (relativeUrl, data, type) => {
    const result = await client.post(`${apiUrl}${relativeUrl}`, {
        data: {
            type,
            attributes: data
        }
    }, {
        headers: {
            'Content-Type': 'application/vnd.api+json',
            'x-auth-token': AuthSDK.getToken()
        }
    });
    return result.data.data.uuid;
};
export const axiosDelete = async (relativeUrl) => {
    await axiosDeleteInner(relativeUrl);
};
const axiosDeleteInner = async (relativeUrl) => {
    await client.delete(`${apiUrl}${relativeUrl}`, {
        headers: {
            Accept: 'application/vnd.api+json',
            'x-auth-token': AuthSDK.getToken()
        }
    });
};
// V2 endpoints without JSON API
export const axiosGetV2 = async (relativeUrl) => {
    try {
        const result = await client.get(`${apiUrl}${relativeUrl}`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        setError(e);
    }
};
export const axiosPostV2 = async (relativeUrl, data) => {
    try {
        const result = await client.post(`${apiUrl}${relativeUrl}`, data, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        setError(e);
        return e;
    }
};
export const axiosPostUnwrapped = async (relativeUrl, data) => {
    const result = await client.post(`${apiUrl}${relativeUrl}`, data, { headers: { 'x-auth-token': AuthSDK.getToken() } });
    return result.data;
};
export const axiosPatchV2 = async (relativeUrl, data) => {
    try {
        await client.patch(`${apiUrl}${relativeUrl}`, data, { headers: { 'x-auth-token': AuthSDK.getToken() } });
    }
    catch (e) {
        setError(e);
    }
};
export const axiosDeleteV2 = async (relativeUrl) => {
    try {
        await client.delete(`${apiUrl}${relativeUrl}`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
    }
    catch (e) {
        setError(e);
    }
};
