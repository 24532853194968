import React from 'react';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
export const HPLDrainOrDownpipePhotosBlock = () => {
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, files, setFiles, setSurvey, companyUUID } = hplContext;
    const drainPhotosHydrated = survey.drain_photos.map(x => files.find(y => y.uuid === x.image_uuid));
    const setDrainPhotos = (photos) => setSurvey({ ...survey, drain_photos: photos });
    const isFullScreen = drainPhotosHydrated.length === 0 && flowContext.viewMode === 'FLOW';
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4' },
            React.createElement("div", { className: `${isFullScreen ? 'items-center' : ''} flex flex-col gap-1` },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Drain or downpipe"),
                React.createElement("div", { className: `${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm` }, "Add photos of the drain or downpipe")),
            React.createElement("div", { className: "flex-col gap-3 flex" },
                !isFullScreen && React.createElement("div", { className: "text-gray-900 font-bold" }, "Photos"),
                React.createElement(PhotoScroll, { viewMode: isFullScreen ? 'COMPACT' : 'NORMAL', images: drainPhotosHydrated, deleteImage: (id) => {
                        setDrainPhotos(survey.drain_photos.filter(x => x.image_uuid !== id));
                    }, addImage: (image) => {
                        setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime() }]);
                        const newLocationImage = {
                            uuid: crypto.randomUUID(),
                            image_uuid: image.uuid,
                            entity_uuid: 'survey',
                            entity_attribute: 'drain_photos'
                        };
                        setDrainPhotos([...survey.drain_photos, newLocationImage]);
                    }, companyUUID: companyUUID }))));
};
