import React, { useContext } from 'react';
import { Section } from '../section';
import { Text } from '../../../../../components/content_display/text';
import { formatPrice } from '../../../../../code/format_price';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Button } from '../../../../../components/buttons/button';
import { faExclamationTriangle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { CalculatedQuoteDefaultGroups, calculateQuote, quoteItemNameWithQuantity } from '../../../../../code/calculate_quote';
import { AdminContext } from '../../../admin_layout';
import { groupBy, sum } from 'lodash';
export const CostEstimate = ({ lead, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const adminContext = useContext(AdminContext);
    const navigate = useNavigate();
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [calculatedQuote] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name');
    const quoteHeatPump = (_a = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]) === null || _a === void 0 ? void 0 : _a[0];
    const quoteHotWaterCylinder = (_b = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]) === null || _b === void 0 ? void 0 : _b[0];
    const systemDesignRows = [
        { key: 'Heat pump', value: ((_c = estimate.heatPump) === null || _c === void 0 ? void 0 : _c.name) || React.createElement(Badge, { color: "RED", text: "No heat pump selected", icon: faExclamationTriangle }) },
        { key: 'Cylinder', value: ((_d = estimate.hotWaterCylinder) === null || _d === void 0 ? void 0 : _d.name) || React.createElement(Badge, { color: "RED", text: "No hot water cylinder selected", icon: faExclamationTriangle }) },
        { key: 'Flow temperature (°C)', value: estimate.flowTempC },
        // { key: 'Radiator changes', value: estimate.radiator_changes },
        { key: `SCOP at ${estimate.flowTempC}°C`, value: estimate.sCOP }
    ];
    const costBreakdownRows = [
        ...(quoteHeatPump ? [{ key: quoteItemNameWithQuantity(quoteHeatPump), value: formatPrice(quoteHeatPump.subtotal) }] : []),
        ...(quoteHotWaterCylinder ? [{ key: quoteItemNameWithQuantity(quoteHotWaterCylinder), value: formatPrice(quoteHotWaterCylinder.subtotal) }] : []),
        ...((_e = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.PARTS]) === null || _e === void 0 ? void 0 : _e.map(part => ({ key: quoteItemNameWithQuantity(part), value: formatPrice(part.subtotal) }))) || [],
        ...((_f = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.RADIATORS]) === null || _f === void 0 ? void 0 : _f.map(radiator => ({ key: quoteItemNameWithQuantity(radiator), value: formatPrice(radiator.subtotal) }))) || [],
        ...((_g = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]) === null || _g === void 0 ? void 0 : _g.map(ufh => ({ key: quoteItemNameWithQuantity(ufh, 'm²'), value: formatPrice(ufh.subtotal) }))) || [],
        ...((_h = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.LABOUR]) === null || _h === void 0 ? void 0 : _h.map(labour => ({ key: quoteItemNameWithQuantity(labour, 'days'), value: formatPrice(labour.subtotal) }))) || [],
        ...((_j = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.SURVEY]) === null || _j === void 0 ? void 0 : _j.map(survey => ({ key: quoteItemNameWithQuantity(survey), value: formatPrice(survey.subtotal) }))) || [],
        ...(((_k = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]) === null || _k === void 0 ? void 0 : _k.some(grant => grant.selected)) ? ((_l = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]) === null || _l === void 0 ? void 0 : _l.map(grant => ({ key: quoteItemNameWithQuantity(grant), value: formatPrice(grant.subtotal) }))) || [] : [])
    ];
    const handleEditCostEstimate = () => {
        const url = `/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/estimate/cost-estimate`;
        navigate(url);
    };
    return (React.createElement(Section, { title: "Cost estimate", controls: React.createElement(Button, { size: "SM", iconLeft: faPenToSquare, colour: 'LIGHT', onClick: handleEditCostEstimate }, "Edit cost estimate") },
        React.createElement("div", { className: 'flex flex-col gap-5' },
            React.createElement("div", { className: 'flex items-center justify-between' },
                React.createElement(Text, { bold: true }, "Estimate total"),
                React.createElement(Text, { bold: true }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0))))),
            React.createElement("div", null,
                React.createElement(Text, { bold: true, className: 'mb-1' }, "System design"),
                React.createElement(SummaryTable, { rows: systemDesignRows })),
            React.createElement("div", null,
                React.createElement(Text, { bold: true, className: 'mb-1' }, "Cost breakdown"),
                React.createElement(SummaryTable, { rows: costBreakdownRows }),
                (estimate.isScottish && lead.scottish_rural) && React.createElement(Alert, { className: "mt-3", type: 'SUCCESS' }, "This property is likely to be eligible for the HES rural uplift.")))));
};
