import React, { useContext, useState } from 'react';
import { orderBy, sum } from 'lodash';
import { AdminContext } from '../../../admin_layout';
import { formatPrice } from '../../../../../code/format_price';
import { formatDate } from '../../../../../code/format_date';
import { Link } from '../../../../../components/buttons/link';
import { Button } from '../../../../../components/buttons/button';
import { Section } from '../section';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { SendNudgeLeadEmailModal } from '../../../components/send_nudge_lead_email_modal';
export const EnquiryHistory = ({ lead, estimate, calculatedEstimates, subdomain, reloadTrigger }) => {
    const adminContext = useContext(AdminContext);
    const [isEstimateModalVisible, setIsEstimateModalVisible] = useState(false);
    const [currentCalculatedQuote, setCurrentCalculatedQuote] = useState(undefined);
    const columns = [
        { name: 'Estimate', render: (x) => { var _a; return React.createElement("div", null,
                x.heat_pump_name,
                ", ",
                formatPrice(sum((_a = x.calculated_estimate_quote_items) === null || _a === void 0 ? void 0 : _a.map(i => i.selected ? i.price * i.quantity : 0)))); } },
        { name: 'Status', render: (x) => React.createElement("div", null, x.status) },
        { name: 'Date Sent', render: (x) => React.createElement("div", null, (x.created_at && formatDate(x.created_at)) || 'n/a') },
        { name: 'Date Reminder Sent', render: (x) => React.createElement("div", null, formatDate(x.reminder_sent_at)) },
        { name: '', render: (x) => React.createElement(Link, { text: 'View estimate', onClick: () => window.open(`/${subdomain}/estimate/${x.uuid}?installer=true`) }) },
        {
            name: '',
            render: (x) => {
                return React.createElement("div", { className: 'flex justify-end gap-4 items-center' }, (x.status === 'Sent' || x.status === 'Opened') &&
                    React.createElement(Button, { onClick: () => {
                            setCurrentCalculatedQuote(x);
                            setIsEstimateModalVisible(true);
                        } }, "Send reminder"));
            }
        }
    ];
    const orderedEstimates = orderBy(calculatedEstimates, x => new Date((x.created_at || Date.now()).toString()), 'desc');
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { title: 'Enquiry history' },
            React.createElement(TableLite, { columns: columns, rows: orderedEstimates })),
        React.createElement(SendNudgeLeadEmailModal, { adminContext: adminContext, isVisible: isEstimateModalVisible, setIsVisible: setIsEstimateModalVisible, estimate: estimate, calculatedEstimate: currentCalculatedQuote, lead: lead, reloadTrigger: reloadTrigger })));
};
