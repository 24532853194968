import React, { useEffect, useState } from 'react';
import { updateCalculatedEstimate } from '../../../code/models/calculated_estimate';
import { getCompanyNudgeLeadEmailTemplate } from '../../../code/models/company';
import { renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../code/tiptap_placeholders';
import { numberFormat } from '../../../code/number_format';
import { formatPrice } from '../../../code/format_price';
import { Modal } from '../../../components/containers/modal';
import { logEvent } from '../../../code/log_event';
import { sendNudgeEmail } from '../../../code/models/lead';
import { Tiptap } from '../../../components/inputs_and_selections/tiptap';
import { calculateQuote } from '../../../code/calculate_quote';
import { sum } from 'lodash';
export const SendNudgeLeadEmailModal = ({ isVisible, setIsVisible, adminContext, calculatedEstimate, estimate, lead, reloadTrigger }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (!calculatedEstimate)
        return null;
    const [emailTemplateJSON, setEmailTemplateJSON] = useState();
    const [estimateToSend, setEstimateToSend] = useState();
    const currentEstimateHeatPump = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.heatPumps) === null || _b === void 0 ? void 0 : _b.find(x => x.uuid === calculatedEstimate.range_heat_pump_uuid);
    const currentEstimateHotWaterCylinder = (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders) === null || _d === void 0 ? void 0 : _d.find(x => x.uuid === calculatedEstimate.range_hot_water_cylinder_uuid);
    const [calculatedQuote] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: currentEstimateHeatPump,
        selectedHotWaterCylinder: currentEstimateHotWaterCylinder,
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: calculatedEstimate.calculated_estimate_quote_items
    });
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return;
        const estimateToSend = {
            ...calculatedEstimate,
            status: 'Reminder sent',
            updated_at: new Date().toString()
        };
        // the email template used to send the estimate
        const emailTemplate = getCompanyNudgeLeadEmailTemplate(adminContext.data.company.nudge_lead_template);
        // render all placeholders EXCEPT the View Estimate button
        const emailJSON = renderJSONReplacingPlaceholders(emailTemplate, {
            [TTPlaceholderCustomerName.id]: estimateToSend.name,
            [TTPlaceholderCustomerAddress.id]: `${estimateToSend.address}, ${estimateToSend.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: estimateToSend.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(estimateToSend.co2_saved_kg),
            [TTPlaceholderCompanyName.id]: (_d = (_c = (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.public_info) === null || _d === void 0 ? void 0 : _d.name,
            [TTPlaceholderCompanyPhone.id]: (_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.phone
        });
        setEmailTemplateJSON(emailJSON);
        setEstimateToSend(estimateToSend);
    }, [adminContext, calculatedEstimate]);
    if (!((_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) || !estimateToSend)
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    return React.createElement(Modal, { title: 'Send reminder to lead', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            var _a, _b, _c, _d, _e, _f, _g;
            logEvent({
                name: 'Send Nudge Lead Email',
                properties: {}
            }, (_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info) === null || _c === void 0 ? void 0 : _c.subdomain) !== null && _d !== void 0 ? _d : '');
            // render the email template with the View Estimate button
            const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON, {
                [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + ((_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.subdomain) + '/estimate/' + estimateToSend.uuid
            });
            await sendNudgeEmail(lead.uuid, calculatedEstimate.uuid, companyUUID, emailHTML);
            await updateCalculatedEstimate(estimateToSend, companyUUID);
            reloadTrigger();
        }, confirmButtonLabel: "Send" },
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement("div", null,
                "This will send an estimate of ",
                React.createElement("span", { className: "font-bold" }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))),
                " to ",
                React.createElement("span", { className: "font-bold" }, estimateToSend.name),
                " at ",
                React.createElement("span", { className: "font-bold" }, (_f = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _f === void 0 ? void 0 : _f.email),
                "."),
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement("div", { className: "text-xl font-medium" }, "Customer email"),
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setEmailTemplateJSON(editor.getJSON());
                    }, placeholders: [
                        TTPlaceholderViewEstimate
                    ], content: emailTemplateJSON }),
                ((_j = (_h = (_g = adminContext.data) === null || _g === void 0 ? void 0 : _g.company) === null || _h === void 0 ? void 0 : _h.public_info) === null || _j === void 0 ? void 0 : _j.logo) && React.createElement("img", { src: (_m = (_l = (_k = adminContext.data) === null || _k === void 0 ? void 0 : _k.company) === null || _l === void 0 ? void 0 : _l.public_info) === null || _m === void 0 ? void 0 : _m.logo, className: "max-w-40", alt: "Installer logo" }))));
};
