import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { ClickableCard } from '../../../components/content_display/card';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { sum } from 'lodash';
import { TableLite } from '../../../components/content_display/table_lite';
import { combineHeatLossesForProgressChart, combineSortConductionHeatLosses, combineVentilationHeatLosses, getAverageRoomHeightM, getConductionHeatLossAllElements, getFloorAreaM2, getRoomACH, getRoomTemp, getRoomWatts, getVentilationHeatLoss } from '../../../code/models/heat_loss';
import { getEmitterSizeName, getEmitterTypeName } from '../../../code/models/radiator';
import { HLRFloorplanBlock } from './partials/hlr_floorplan';
import { RadiatorIcon, UFHIcon } from '../../../assets/images/survey_images/survey_images';
import { numberFormat } from '../../../code/number_format';
import { getEmitterWatts } from '../../../code/models/radiator_model';
export const HLRRoomHeatlossPage = ({ floor, room }) => {
    const hlrContext = React.useContext(HLRContext);
    const roomHeatLoss = Math.round(getRoomWatts(room, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey));
    const roomACH = numberFormat(1).format(getRoomACH(room, hlrContext.survey));
    const roomFloorAreaNum = getFloorAreaM2(room.walls);
    const roomFloorArea = numberFormat(1).format(roomFloorAreaNum);
    const roomAvgHeight = getAverageRoomHeightM(room);
    const roomVolume = numberFormat(1).format(roomAvgHeight * parseFloat(roomFloorArea));
    const conductionHeatLossRows = getConductionHeatLossAllElements(room, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey);
    const ventilationHeatLoss = getVentilationHeatLoss(room, hlrContext.designTempC, hlrContext.survey);
    const heatLossForChart = combineHeatLossesForProgressChart(conductionHeatLossRows, [ventilationHeatLoss]);
    const conductionHeatLossRowsCombinedForTable = combineSortConductionHeatLosses(conductionHeatLossRows, false);
    const ventilationHeatLossCombinedForTable = combineVentilationHeatLosses([ventilationHeatLoss]);
    const elements = conductionHeatLossRowsCombinedForTable.map(x => ({
        name: x.elementName,
        temp: numberFormat(1).format(x.otherSideTempC) + ' °C',
        uValue: numberFormat(2).format(x.uValueWPerM2K) + ' W/m²K',
        area: numberFormat(1).format(x.areaM2) + ' m²',
        heatLoss: x.watts
    })).concat(ventilationHeatLossCombinedForTable.map(x => ({
        name: x.elementName,
        temp: numberFormat(1).format(x.externalTempC) + ' °C',
        uValue: '',
        area: '',
        heatLoss: x.watts
    })));
    const elementsTableColumns = [
        {
            name: '',
            key: 'name',
            render: (row) => React.createElement("span", { className: 'text-gray-900 text-xs font-bold' }, row.name)
        },
        { name: 'Other side temperature', key: 'temp', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' }, row.temp) },
        { name: 'U-value', key: 'uValue', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' }, row.uValue) },
        { name: 'Area', key: 'area', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' }, row.area) },
        { name: 'Heat loss', key: 'heatLoss', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                numberFormat(0).format(row.heatLoss),
                " W") }
    ];
    const emitters = room.radiators.map(radiator => {
        const images = radiator.photos.map(x => hlrContext.files.find(y => y.uuid === x.image_uuid));
        const radiatorWatts = Math.round(getEmitterWatts(radiator, room, hlrContext.design, hlrContext.survey, hlrContext.designTempC, hlrContext.groundTempC));
        return {
            type: radiator.emitter_type === 'UNDERFLOOR' ? 'UFH' : radiator.emitter_type === 'SECONDARY' ? 'Secondary' : 'Radiator',
            image: images[0]
                ? React.createElement("img", { src: images[0].file_base64 || `${process.env.S3_BUCKET_URL}/${images[0].file_url}`, alt: '', className: 'w-10 h-10 rounded' })
                : radiator.emitter_type === 'UNDERFLOOR'
                    ? React.createElement("img", { alt: '', src: UFHIcon, className: "p-2 w-10 h-10 rounded bg-gray-100" })
                    : React.createElement("img", { alt: '', src: RadiatorIcon, className: "p-2 w-10 h-10 rounded bg-gray-100" }),
            details: React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", null, getEmitterTypeName(radiator)),
                React.createElement("div", null, getEmitterSizeName(radiator))),
            emitterOutput: radiatorWatts,
            roomDemand: roomHeatLoss,
            percentMet: Math.round((radiatorWatts / roomHeatLoss) * 100),
            // the roomOutputStub is a placeholder for the table as it requires a key.
            // the real value in the cell rendered NOT from this object
            roomOutputStub: 0
        };
    });
    const roomOutput = emitters.reduce((acc, radiator) => acc + radiator.emitterOutput, 0);
    const emittersTotalPercent = sum(emitters.map(x => x.percentMet));
    const emittersTableColumns = [
        {
            name: 'Emitter',
            key: 'type',
            render: (row) => React.createElement("span", { className: 'text-gray-900 text-xs font-bold' }, row.type)
        },
        { name: 'Image', key: 'image', render: (row) => React.createElement("div", { className: 'flex justify-end' }, row.image) },
        { name: 'Details', key: 'details', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' }, row.details) },
        { name: 'Emitter output', key: 'emitterOutput', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                row.emitterOutput,
                " W") },
        { name: 'Room output', key: 'roomOutputStub', render: () => React.createElement("span", { className: 'text-xs font-semibold' },
                roomOutput,
                " W") },
        { name: 'Room demand', key: 'roomDemand', render: (row) => React.createElement("span", { className: 'text-xs font-semibold' },
                row.roomDemand,
                " W") },
        {
            name: '% heat demand met*',
            key: 'percentMet',
            render: (row) => {
                // `inline-block` prevents span from taking full width
                return React.createElement("span", { className: 'inline-block' }, row.percentMet.toString() + ' %');
            }
        }
    ];
    const totalHeatLossW = Math.round(sum([...conductionHeatLossRowsCombinedForTable, ...ventilationHeatLossCombinedForTable].map(x => x.watts)));
    const roomTemp = getRoomTemp(room, hlrContext.survey);
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement("div", { className: "flex-row gap-6 flex" },
            React.createElement("div", { className: 'grow flex flex-col gap-4' },
                React.createElement(HLRSubheaderBLock, { section: "Heat loss by room", title: room.name }),
                React.createElement("div", { className: 'grid grid-cols-3 gap-3' },
                    React.createElement("div", { className: 'p-3 bg-gray-100 rounded-md flex flex-col' },
                        React.createElement("div", { className: "text-xs tracking-tight" }, "Total heat loss"),
                        React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, roomHeatLoss),
                            React.createElement("div", { className: "text-gray-900 text-lg font-semibold" }, "W"))),
                    React.createElement("div", { className: 'p-3 bg-gray-100 rounded-md' },
                        React.createElement("div", { className: "text-xs tracking-tight" }, "Room temp"),
                        React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, roomTemp),
                            React.createElement("div", { className: "text-gray-900 text-lg font-semibold" }, "\u00B0C"))),
                    React.createElement("div", { className: 'p-3 bg-gray-100 rounded-md' },
                        React.createElement("div", { className: "text-xs tracking-tight" }, "Air changes per hour"),
                        React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, roomACH))),
                    React.createElement("div", { className: 'p-3 bg-gray-100 rounded-md' },
                        React.createElement("div", { className: "text-xs tracking-tight" }, "Floor area"),
                        React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, roomFloorArea),
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "m\u00B2"))),
                    React.createElement("div", { className: 'p-3 bg-gray-100 rounded-md' },
                        React.createElement("div", { className: "text-xs tracking-tight" }, "Average height"),
                        React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, numberFormat(1).format(roomAvgHeight)),
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "m"))),
                    React.createElement("div", { className: 'p-3 bg-gray-100 rounded-md' },
                        React.createElement("div", { className: "text-xs tracking-tight" }, "Volume"),
                        React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, roomVolume),
                            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "m\u00B3"))))),
            React.createElement("div", { className: 'w-60 bg-gray-100' },
                React.createElement(HLRFloorplanBlock, { floor: floor, room: room, width: 240, height: 240 }))),
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 text-lg font-bold " }, "Heat loss by element"),
                React.createElement("div", { className: "text-lg" },
                    numberFormat(0).format(totalHeatLossW),
                    " W")),
            React.createElement(ProgressChart, { items: heatLossForChart, total: totalHeatLossW })),
        React.createElement(TableLite, { size: 'SM', rows: elements, columns: elementsTableColumns }),
        React.createElement("div", { className: 'flex justify-between font-semibold' },
            React.createElement("div", null, "Heat loss per m\u00B2"),
            React.createElement("div", null,
                Math.round(totalHeatLossW / roomFloorAreaNum),
                " W/m\u00B2")),
        emitters.length > 0 && React.createElement(React.Fragment, null,
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: "gap-3 flex justify-between" },
                    React.createElement("div", { className: "text-gray-900 text-lg font-bold " }, "Emitter performance"),
                    React.createElement("div", { className: "text-lg" },
                        emittersTotalPercent,
                        "% of ",
                        totalHeatLossW,
                        " W at ",
                        hlrContext.design.flow_temp,
                        " \u00B0C")),
                React.createElement(ProgressChart, { items: emitters.map(x => ({
                        name: x.type,
                        value: x.percentMet
                    })), total: 100, colorVariant: 'EMITTER' })),
            React.createElement(TableLite, { size: 'SM', rows: emitters, columns: emittersTableColumns }),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs font-bold" }, "*% Heat demand met: "),
                React.createElement("span", { className: "text-xs tracking-tight" },
                    "This is calculated for a day when the outdoor temperature is ",
                    numberFormat(1).format(hlrContext.designTempC),
                    " \u00B0C and the flow temperature is ",
                    numberFormat(0).format(hlrContext.design.flow_temp),
                    " \u00B0C")))));
};
