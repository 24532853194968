import React, { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { priceCalculations } from '../../../../../code/price_calculations';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { EmptySection } from '../empty_section';
const CylinderInputs = ({ cylinder, setCylinder, isEditing, packs }) => {
    var _a;
    const [selectedPackId, setSelectedPackId] = useState((_a = cylinder.default_pack_uuid) !== null && _a !== void 0 ? _a : undefined);
    const packOptions = [
        { key: null, value: 'None' },
        ...packs.map(pack => ({ key: pack.uuid, value: pack.name }))
    ];
    const handleSetPack = async (packId) => {
        setCylinder({ ...cylinder, default_pack_uuid: packId });
        setSelectedPackId(packId);
    };
    return (React.createElement("div", { className: 'space-y-4' },
        React.createElement(Input, { label: 'Name', value: cylinder.name || '', setValue: (value) => setCylinder({ ...cylinder, name: value }) }),
        React.createElement("div", { className: "flex flex-col gap-2" },
            React.createElement(FormLabel, { labelText: 'Associated pack' }),
            React.createElement(Select, { placeholder: "Select a pack", options: packOptions, selectedKey: selectedPackId, setSelectedKey: handleSetPack }),
            React.createElement(Text, { size: "XS", className: "text-gray-500" }, "This pack will be automatically included when this hot water cylinder is added to an estimate or quote.")),
        React.createElement(Input, { type: "number", label: 'Cost price', placeholder: 'Enter cost price', value: cylinder.cost_price, setValue: (value) => setCylinder({ ...cylinder, cost_price: parseFloat(value) }), prefix: '\u00A3' }),
        React.createElement(Input, { type: "number", label: 'Markup', placeholder: 'Enter markup', value: cylinder.markup, setValue: (value) => setCylinder({ ...cylinder, markup: parseFloat(value) }), postfix: '%' }),
        React.createElement(Input, { type: "number", label: 'Customer price', placeholder: 'Enter customer price', value: priceCalculations.calculateCustomerPrice(cylinder.cost_price, cylinder.markup), setValue: (value) => {
                setCylinder({ ...cylinder, markup: 0, cost_price: parseFloat(value) });
            }, prefix: '\u00A3' }),
        React.createElement(Input, { type: 'number', label: 'Capacity', value: cylinder.litres || 0, postfix: "L", setValue: (value) => setCylinder({ ...cylinder, litres: parseInt(value) }) })));
};
const AddCylinderModal = ({ visible, setVisible, addHotWaterCylinder, packs }) => {
    const [newCylinder, setNewCylinder] = useState({
        name: '',
        cost_price: 0,
        markup: 0,
        litres: 0
    });
    const handleAddCylinder = () => {
        const cylinder = {
            ...newCylinder,
            uuid: crypto.randomUUID()
        };
        addHotWaterCylinder(cylinder);
        setVisible(false);
        setNewCylinder({ name: '', cost_price: 0, markup: 0, litres: 0 });
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Add a hot water cylinder', onConfirm: handleAddCylinder, confirmButtonLabel: 'Add' },
        React.createElement(CylinderInputs, { cylinder: newCylinder, setCylinder: setNewCylinder, isEditing: false, packs: packs })));
};
const EditCylinderModal = ({ cylinder, visible, setVisible, updateHotWaterCylinder, packs }) => {
    const [localCylinder, setLocalCylinder] = useState({
        name: cylinder.name,
        cost_price: cylinder.cost_price,
        markup: cylinder.markup,
        litres: cylinder.litres
    });
    const handleUpdateCylinder = () => {
        updateHotWaterCylinder({
            ...cylinder,
            ...localCylinder
        });
        setVisible(false);
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Edit hot water cylinder', onConfirm: handleUpdateCylinder, confirmButtonLabel: 'Save' },
        React.createElement(CylinderInputs, { cylinder: localCylinder, setCylinder: setLocalCylinder, isEditing: true, packs: packs })));
};
export const HotWaterCylindersInventory = ({ hotWaterCylinders, addHotWaterCylinder, deleteHotWaterCylinder, updateHotWaterCylinder, packs }) => {
    const [modalCylinderId, setModalCylinderId] = useState();
    const [addCylinderModalVisible, setAddCylinderModalVisible] = useState(false);
    const modalCylinder = hotWaterCylinders.find(x => x.uuid === modalCylinderId);
    const cylindersColumns = [
        { key: 'name', name: 'Name' },
        { key: 'litres', name: 'Capacity (L)' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "space-y-6" },
            React.createElement("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full" },
                React.createElement(Heading, { size: "2xl" }, "Hot Water Cylinders"),
                React.createElement(Button, { colour: 'DARK', onClick: () => setAddCylinderModalVisible(true), iconLeft: faPlus }, "Add Cylinder")),
            !hotWaterCylinders.length
                ? React.createElement(EmptySection, { title: 'No hot water cylinders added', description: 'Add hot water cylinders to your inventory to create estimates and quotes for your customers.', button: React.createElement(Button, { onClick: () => setAddCylinderModalVisible(true), iconLeft: faPlus }, "Add hot water cylinder") })
                : React.createElement(Section, null,
                    React.createElement(InventoryTable, { rows: hotWaterCylinders
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(x => {
                            const packForHotWaterCylinder = packs.find(pack => pack.uuid === x.default_pack_uuid);
                            return {
                                name: React.createElement("div", { className: "flex flex-col gap-2 items-start" },
                                    React.createElement(Text, { size: "SM" }, x.name),
                                    packForHotWaterCylinder && React.createElement("div", { className: 'flex gap-1 items-center' },
                                        React.createElement(Badge, { color: "LIGHT", text: packForHotWaterCylinder.name }))),
                                litres: React.createElement(Input, { type: 'number', value: x.litres, setValue: (value) => updateHotWaterCylinder({ ...x, litres: parseInt(value) }) }),
                                costPrice: React.createElement(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => updateHotWaterCylinder(priceCalculations.updateCostPrice(x, parseFloat(value))) }),
                                markup: React.createElement(Input, { type: 'number', step: 1, value: Math.round(x.markup), setValue: (value) => updateHotWaterCylinder(priceCalculations.updateMarkup(x, parseFloat(value))), postfix: '%' }),
                                customerPrice: React.createElement(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => updateHotWaterCylinder(priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value))), prefix: '\u00A3' }),
                                menu: React.createElement(DropdownMenu, { items: [
                                        { label: 'Edit', onClick: () => setModalCylinderId(x.uuid), icon: faEdit },
                                        { label: 'Delete', onClick: () => deleteHotWaterCylinder(x.uuid), icon: faTrashAlt, confirmText: 'Are you sure you want to delete this hot water cylinder?' }
                                    ] })
                            };
                        }), columns: cylindersColumns }))),
        React.createElement(AddCylinderModal, { visible: addCylinderModalVisible, setVisible: setAddCylinderModalVisible, addHotWaterCylinder: addHotWaterCylinder, packs: packs }),
        modalCylinder && (React.createElement(EditCylinderModal, { cylinder: modalCylinder, visible: !!modalCylinderId, setVisible: (visible) => setModalCylinderId(visible ? modalCylinderId : undefined), updateHotWaterCylinder: updateHotWaterCylinder, packs: packs }))));
};
