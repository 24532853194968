import { captureMessage } from '@sentry/react';
export const parseWithZod = (schema, payload) => {
    const result = schema.safeParse(payload);
    if (!result.success) {
        // Log the error to Sentry
        console.error('Zod schema validation failed', result.error);
        captureMessage('Zod schema validation failed', {
            level: 'warning',
            extra: {
                errors: result.error,
                payload
            }
        });
        // Return the original payload
        return payload;
    }
    // If parsing was successful, return the parsed data
    return result.data;
};
