import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { getAllCompaniesList, getCompany } from '../../code/models/company';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { SpruceIconWhite, SpruceLogoWhite } from '../../assets/images/spruce_logo/spruce_logo';
import { AuthContext, AuthProvider, AuthSDK } from '../../code/utils/auth_provider';
import { noop } from 'lodash';
import { getHeatPumps } from '../../code/models/heat_pump';
import { getHotWaterCylinders } from '../../code/models/hot_water_cylinder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCog, faInbox, faPoundSign, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { getUser, hasEnquriesAccess, hasInventoryAccess, hasSettingsAccess, isSuperAdmin } from '../../code/models/user';
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback';
import { getGenericMaterials, getMaterialsGenericLayers } from '../../code/models/material';
import { getBrandRanges } from '../../code/models/brand_range';
import { Select } from '../../components/inputs_and_selections/select';
import { TermsAndConditionsModal } from './components/terms_and_conditions_modal';
import { getTermsAndConditions, userHasAcceptedLatestTerms } from '../../code/models/terms_and_conditions';
import { Alert } from '../../components/indicators_and_messaging/alert';
import { Link } from '../../components/buttons/link';
import { appStoreBadge, googleBadge } from '../../assets/images/store_badges/store_badges';
import { getPacks } from '../../code/models/packs';
import { getLabour } from '../../code/models/labour';
import { getParts } from '../../code/models/parts';
const RequireAuth = ({ children }) => {
    const auth = useContext(AuthContext);
    const location = useLocation();
    const { companySubdomain } = useParams();
    const allowList = [
        `/${companySubdomain}/admin/login`,
        `/${companySubdomain}/admin/remind-password`,
        `/${companySubdomain}/admin/new-password`
    ];
    if (auth.isSignedIn() && location.pathname.replace(/\/$/, '') === `/${companySubdomain}/admin`) {
        return React.createElement(Navigate, { to: `/${companySubdomain}/admin/enquiries` });
    }
    if (auth.isSignedIn() && allowList.includes(location.pathname)) {
        // If they're logged in and they're trying to go to a page that's on the allow list,
        // send them to the home page.
        return React.createElement(Navigate, { to: `/${companySubdomain}/admin/enquiries`, replace: true });
    }
    if (!auth.isSignedIn() && !allowList.includes(location.pathname)) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return React.createElement(Navigate, { to: '/login', state: { from: location }, replace: true });
    }
    return children;
};
const Wrapper = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const auth = useContext(AuthContext);
    const adminContext = useContext(AdminContext);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const location = useLocation();
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const navRef = useRef(null);
    document.addEventListener('mousedown', (e) => {
        if (buttonRef.current && !buttonRef.current.contains(e.target) && navRef.current && !navRef.current.contains(e.target)) {
            adminContext.hideSidebar();
        }
    });
    const sidebarItems = [];
    if (((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user) &&
        isSuperAdmin((_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.user) &&
        ((_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.allCompaniesList) === null || _d === void 0 ? void 0 : _d.length) &&
        ((_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.allCompaniesList.length) >= 1) {
        sidebarItems.push({
            type: 'subheader',
            content: 'Superadmin'
        }, {
            type: 'item',
            content: React.createElement("div", { className: "text-gray-500" },
                React.createElement(Select, { colour: "LIGHT", filter: true, options: adminContext.data.allCompaniesList.map(x => ({
                        key: x.subdomain,
                        value: x.name + ' (' + x.subdomain + ')'
                    })), selectedKey: companyPublicInfo.subdomain, setSelectedKey: (subdomain) => {
                        if (subdomain)
                            window.location.href = `/${subdomain}/admin`;
                    } }))
        });
    }
    if (((_g = (_f = adminContext.data) === null || _f === void 0 ? void 0 : _f.user) === null || _g === void 0 ? void 0 : _g.companies) &&
        ((_h = adminContext.data) === null || _h === void 0 ? void 0 : _h.user.companies.length) > 1) {
        sidebarItems.push({
            type: 'subheader',
            content: 'Your companies'
        }, {
            type: 'item',
            content: React.createElement("div", { className: "text-gray-500" },
                React.createElement(Select, { filter: true, options: (_k = (_j = adminContext.data) === null || _j === void 0 ? void 0 : _j.user) === null || _k === void 0 ? void 0 : _k.companies.map(x => ({
                        key: x.subdomain,
                        value: x.name
                    })), selectedKey: companyPublicInfo.subdomain, setSelectedKey: (value) => {
                        window.location.href = `/${value}/admin`;
                    }, dataCy: "companies_dropdown" }))
        });
    }
    if (hasEnquriesAccess((_l = adminContext.data) === null || _l === void 0 ? void 0 : _l.company, (_m = adminContext.data) === null || _m === void 0 ? void 0 : _m.user)) {
        sidebarItems.push({
            type: 'subheader',
            content: 'Jobs'
        }, {
            type: 'item',
            onClick: () => {
                navigate(`/${companyPublicInfo.subdomain}/admin/enquiries`);
            },
            content: React.createElement(React.Fragment, null,
                React.createElement(FontAwesomeIcon, { className: 'self-center text-lg text-white', icon: faInbox }),
                " Jobs"),
            isActive: () => {
                return [
                    `/${companyPublicInfo.subdomain}/admin/enquiries`,
                    `/${companyPublicInfo.subdomain}/admin/enquiries/inprogress`,
                    `/${companyPublicInfo.subdomain}/admin/enquiries/estimated`,
                    `/${companyPublicInfo.subdomain}/admin/enquiries/accepted`,
                    `/${companyPublicInfo.subdomain}/admin/enquiries/rejected`
                ].includes(location.pathname);
            }
        }, {
            type: 'item',
            onClick: () => {
                navigate(`/${companyPublicInfo.subdomain}/admin/enquiries/new`);
            },
            content: React.createElement(React.Fragment, null,
                React.createElement(FontAwesomeIcon, { className: 'self-center w-5 h-5 text-white', icon: faCirclePlus }),
                " New Enquiry"),
            isActive: () => location.pathname === `/${companyPublicInfo.subdomain}/admin/enquiries/new`
        });
    }
    if (hasInventoryAccess((_o = adminContext.data) === null || _o === void 0 ? void 0 : _o.company, (_p = adminContext.data) === null || _p === void 0 ? void 0 : _p.user) || hasSettingsAccess((_q = adminContext.data) === null || _q === void 0 ? void 0 : _q.company, (_r = adminContext.data) === null || _r === void 0 ? void 0 : _r.user)) {
        sidebarItems.push({
            type: 'subheader',
            content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "text-opacity-50 text-xs font-semibold uppercase tracking-wide" }, "Configuration"))
        });
    }
    if (hasInventoryAccess((_s = adminContext.data) === null || _s === void 0 ? void 0 : _s.company, (_t = adminContext.data) === null || _t === void 0 ? void 0 : _t.user)) {
        sidebarItems.push({
            type: 'item',
            onClick: () => {
                navigate(`/${companyPublicInfo.subdomain}/admin/costs`);
            },
            content: React.createElement(React.Fragment, null,
                React.createElement(FontAwesomeIcon, { className: 'self-center w-5 h-5 text-white', icon: faPoundSign }),
                "Costs & inventory"),
            isActive: () => location.pathname === `/${companyPublicInfo.subdomain}/admin/costs`
        });
    }
    if (hasSettingsAccess((_u = adminContext.data) === null || _u === void 0 ? void 0 : _u.company, (_v = adminContext.data) === null || _v === void 0 ? void 0 : _v.user)) {
        sidebarItems.push({
            type: 'item',
            onClick: () => {
                navigate(`/${companyPublicInfo.subdomain}/admin/settings`);
            },
            content: React.createElement(React.Fragment, null,
                React.createElement(FontAwesomeIcon, { className: 'self-center w-5 h-5 text-white', icon: faCog }),
                "Settings"),
            isActive: () => location.pathname === `/${companyPublicInfo.subdomain}/admin/settings`
        });
    }
    sidebarItems.push({
        type: 'subheader',
        content: 'User'
    }, {
        type: 'item',
        onClick: () => {
            auth.signOut(() => {
                navigate(`/${companyPublicInfo.subdomain}/admin/login`);
            });
        },
        content: React.createElement(React.Fragment, null,
            React.createElement(React.Fragment, null,
                React.createElement(FontAwesomeIcon, { className: 'self-center text-white w-5 h-5', icon: faSignOut }),
                "Logout")),
        isActive: () => false
    });
    useEffect(() => {
        // We need to do a raw regex check here, react router useMatch does not work.
        const surveyPage = /\/admin\/quotes\/[0-9a-fA-F-]{36}\/survey\/floors\/(.*)$/;
        const isSidebarVisible = !surveyPage.test(window.location.pathname);
        adminContext.setIsSidebarVisible(isSidebarVisible);
    }, [location]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex h-full" },
            auth.isSignedIn() && React.createElement(React.Fragment, null,
                React.createElement("div", { ref: navRef, className: `${!adminContext.isSidebarOpen ? 'hidden' : ''} h-full w-3/4 lg:w-72 max-w-72 fixed z-10 px-5 pt-6 pb-2 bg-gray-900 border-r ${!adminContext.isSidebarVisible ? '' : 'lg:visible lg:block lg:relative'}`, "data-cy": "sidebar" },
                    React.createElement("div", { className: 'flex-col flex lg:gap-16 h-full' },
                        React.createElement("div", { ref: buttonRef, onClick: () => {
                                adminContext.hideSidebar();
                            }, className: "cursor-pointer visible lg:hidden transition ease-linear duration-200" },
                            React.createElement(XMarkIcon, { className: "w-6 h-6 text-white" })),
                        React.createElement("div", { className: "max-lg:hidden" },
                            React.createElement("img", { alt: "Spruce logo", className: "w-8 h-8", src: SpruceIconWhite })),
                        React.createElement("div", { className: 'flex flex-col gap-9 overflow-y-auto h-full' },
                            React.createElement("div", { className: "flex-col flex" },
                                React.createElement("div", { className: "flex-col gap-1 flex" }, sidebarItems.map((item, idx) => {
                                    var _a;
                                    if (item.type === 'item') {
                                        return React.createElement("div", { key: idx, className: `${((_a = item.isActive) === null || _a === void 0 ? void 0 : _a.call(item)) ? 'bg-white bg-opacity-10 font-bold' : 'font-semibold text-opacity-80 hover:bg-white hover:bg-opacity-5'} text-white self-stretch px-2 py-3 rounded-md justify-start items-start gap-3 inline-flex cursor-pointer`, onClick: () => {
                                                if (item.onClick) {
                                                    adminContext.hideSidebar();
                                                    item.onClick();
                                                }
                                            } }, item.content);
                                    }
                                    if (item.type === 'subheader') {
                                        return React.createElement("div", { key: idx, className: "self-stretch px-2 pt-7 pb-2 justify-start items-start gap-2.5 inline-flex text-opacity-50 text-xs uppercase tracking-wide text-white" }, item.content);
                                    }
                                }))),
                            React.createElement("div", { className: "lg:hidden self-stretch flex-col justify-center items-start gap-2.5 flex" },
                                React.createElement("img", { alt: "Spruce logo", className: "w-32 h-12", src: SpruceLogoWhite })),
                            React.createElement("div", { className: "flex flex-col items-center gap-4" },
                                React.createElement("a", { href: "https://apps.apple.com/gb/app/spruce-energy/id6530588740", target: "_blank", rel: "noopener noreferrer" },
                                    React.createElement("img", { alt: "Download on the App Store", className: "w-32 h-auto", src: appStoreBadge })),
                                React.createElement("a", { href: "https://play.google.com/store/apps/details?id=eco.spruce.app", target: "_blank", rel: "noopener noreferrer" },
                                    React.createElement("img", { alt: "Get it on Google Play", className: "w-32 h-auto", src: googleBadge }))))))),
            React.createElement("div", { className: "overflow-auto print:overflow-visible w-full h-full flex flex-col" },
                (!adminContext.isLoading && adminContext.data.termsAndConditions && !userHasAcceptedLatestTerms(adminContext.data.user, adminContext.data.termsAndConditions)) && React.createElement(Alert, { type: 'WARNING' },
                    "We\u2019ve updated our Terms & Conditions. Please could you review and accept them ",
                    React.createElement(Link, { text: 'here', className: 'inline', onClick: () => setTermsModalVisible(true) }),
                    "."),
                React.createElement(Outlet, null))),
        React.createElement(TermsAndConditionsModal, { visible: termsModalVisible, setVisible: setTermsModalVisible, termsAndConditions: adminContext.data.termsAndConditions })));
};
const AdminContextDataDefaultValue = {
    company: undefined,
    allCompaniesList: [],
    heatPumps: [],
    hotWaterCylinders: [],
    labour: [],
    parts: [],
    packs: [],
    user: undefined
};
// Used to store global state for the admin UI
// also has triggers to update the high-level state for some objects like sidebar
export const AdminContext = React.createContext({
    showSidebar: noop,
    hideSidebar: noop,
    isSidebarOpen: false,
    isLoading: true,
    isOffline: false,
    data: AdminContextDataDefaultValue,
    // seters for the data
    setCompany: noop,
    setHeatPumps: noop,
    setHotWaterCylinders: noop,
    setLabour: noop,
    setParts: noop,
    setPacks: noop,
    acceptTermsAndConditions: noop,
    setIsOffline: noop,
    isSidebarVisible: true,
    setIsSidebarVisible: noop
});
export const AdminLayout = () => {
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [adminData, setAdminData] = useState(AdminContextDataDefaultValue);
    // the only reason this is here is to force reloading data when the user signs in
    const isLoggedIn = useRef(AuthSDK.isSignedIn());
    // flags to track loading data
    const [loadedDataFlags, setLoadedDataFlags] = useState(0);
    const FLAG_COMPANY_LOADED = 0x0001;
    const FLAG_HEAT_PUMPS_LOADED = 0x0002;
    const FLAG_HWCS_LOADED = 0x0004;
    const FLAG_ALL_COMPANIES_LOADED = 0x0008;
    const FLAG_USER_LOADED = 0x0010;
    const FLAG_GENERIC_MATERIALS_LOADED = 0x0020;
    const FLAG_MATERIALS_GENERIC_LAYERS_LOADED = 0x0040;
    const FLAG_BRAND_RANGES_LOADED = 0x0080;
    const FLAG_LABOUR_LOADED = 0x0100;
    const FLAG_PARTS_LOADED = 0x0200;
    const FLAG_PACKS_LOADED = 0x0400;
    const FLAG_ALL_LOADED = FLAG_COMPANY_LOADED | FLAG_HEAT_PUMPS_LOADED | FLAG_HWCS_LOADED |
        FLAG_ALL_COMPANIES_LOADED | FLAG_USER_LOADED | FLAG_GENERIC_MATERIALS_LOADED | FLAG_MATERIALS_GENERIC_LAYERS_LOADED | FLAG_BRAND_RANGES_LOADED |
        FLAG_LABOUR_LOADED | FLAG_PARTS_LOADED | FLAG_PACKS_LOADED;
    const [isOffline, setIsOffline] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    window.addEventListener('offline', () => {
        setIsOffline(true);
    });
    window.addEventListener('online', () => {
        setIsOffline(false);
    });
    const [, , , handleDataCompanyReload] = useIndexedDBFallback('admin_data', 'company', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getCompany(companyPublicInfo.subdomain);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_COMPANY_LOADED);
        setAdminData(prev => ({ ...prev, company: data }));
    });
    const [, , , handleDataHeatPumpsReload] = useIndexedDBFallback('admin_data', 'heat_pumps', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getHeatPumps(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_HEAT_PUMPS_LOADED);
        setAdminData(prev => ({ ...prev, heatPumps: data }));
    });
    const [, , , handleDataHWCsReload] = useIndexedDBFallback('admin_data', 'hot_water_cylinders', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getHotWaterCylinders(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_HWCS_LOADED);
        setAdminData(prev => ({ ...prev, hotWaterCylinders: data }));
    });
    const [, , , handleDataLabourReload] = useIndexedDBFallback('admin_data', 'labour', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getLabour(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_LABOUR_LOADED);
        setAdminData(prev => ({ ...prev, labour: data }));
    });
    const [, , , handleDataPartsReload] = useIndexedDBFallback('admin_data', 'parts', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getParts(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_PARTS_LOADED);
        setAdminData(prev => ({ ...prev, parts: data }));
    });
    const [, , , handleDataPacksReload] = useIndexedDBFallback('admin_data', 'packs', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getPacks(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_PACKS_LOADED);
        setAdminData(prev => ({ ...prev, packs: data }));
    });
    const [, , , handleDataAllCompaniesReload] = useIndexedDBFallback('admin_data', 'all_companies', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getAllCompaniesList();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_ALL_COMPANIES_LOADED);
        setAdminData(prev => ({ ...prev, allCompaniesList: data }));
    });
    const [, , , handleDataUserReload] = useIndexedDBFallback('admin_data', 'current_user', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getUser();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_USER_LOADED);
        setAdminData(prev => ({ ...prev, user: data }));
    });
    const [, , , handleDataTermsAndConditionsReload] = useIndexedDBFallback('admin_data', 'terms_and_conditions', undefined, async () => {
        return await getTermsAndConditions();
    }, (data) => {
        setAdminData(prev => ({ ...prev, termsAndConditions: data }));
    });
    const [, , , handleDataGenericMaterialsReload] = useIndexedDBFallback('admin_data', 'generic_materials', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getGenericMaterials();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_GENERIC_MATERIALS_LOADED);
        setAdminData(prev => ({ ...prev, genericMaterials: data }));
    });
    const [, , , handleDataMaterialsGenericLayersReload] = useIndexedDBFallback('admin_data', 'generic_materials_layers', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getMaterialsGenericLayers();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_MATERIALS_GENERIC_LAYERS_LOADED);
        setAdminData(prev => ({ ...prev, materialsGenericLayers: data }));
    });
    const [, , , handleDataBrandRangesReload] = useIndexedDBFallback('admin_data', 'brand_ranges', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getBrandRanges();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_BRAND_RANGES_LOADED);
        setAdminData(prev => ({ ...prev, brandRanges: data }));
    });
    // ask user permission to persist data
    async function persistData() {
        var _a;
        if ((_a = navigator.storage) === null || _a === void 0 ? void 0 : _a.persist) {
            await navigator.storage.persist();
        }
    }
    // aks user permission to persist data, triggers only on first load
    useEffect(() => {
        const ensureDataPersisted = async () => {
            // check if the browser supports the storage persist API
            const isPersisted = await navigator.storage.persisted();
            if (!isPersisted) {
                await persistData();
            }
        };
        ensureDataPersisted();
    }, []);
    // control the loading state
    useEffect(() => {
        // check if the user is logged in
        if (!isLoggedIn.current)
            return;
        // reset the loading state only when all data is loaded
        if (loadedDataFlags === FLAG_ALL_LOADED) {
            setIsLoading(false);
        }
    }, [loadedDataFlags]);
    const toggleSidebar = (arg) => {
        setIsSidebarOpen(arg);
    };
    const handleLoginCallback = async (newLoginStatus) => {
        isLoggedIn.current = newLoginStatus;
        if (newLoginStatus) {
            setIsLoading(true);
            await handleDataCompanyReload();
            await handleDataHeatPumpsReload();
            await handleDataHWCsReload();
            await handleDataLabourReload();
            await handleDataPartsReload();
            await handleDataPacksReload();
            await handleDataAllCompaniesReload();
            await handleDataUserReload();
            await handleDataGenericMaterialsReload();
            await handleDataMaterialsGenericLayersReload();
            await handleDataBrandRangesReload();
            await handleDataTermsAndConditionsReload();
            setIsLoading(false);
        }
    };
    return (React.createElement(AuthProvider, { loginCallback: handleLoginCallback },
        React.createElement(RequireAuth, null,
            React.createElement(AdminContext.Provider, { value: {
                    showSidebar: () => {
                        toggleSidebar(true);
                    },
                    hideSidebar: () => {
                        toggleSidebar(false);
                    },
                    isSidebarOpen,
                    isLoading,
                    isOffline,
                    setIsOffline,
                    data: adminData,
                    setCompany: (data) => setAdminData(prev => ({ ...prev, company: data })),
                    setHeatPumps: (data) => setAdminData(prev => ({ ...prev, heatPumps: data })),
                    setHotWaterCylinders: (data) => setAdminData(prev => ({ ...prev, hotWaterCylinders: data })),
                    setLabour: (data) => setAdminData(prev => ({ ...prev, labour: data })),
                    setParts: (data) => setAdminData(prev => ({ ...prev, parts: data })),
                    setPacks: (data) => setAdminData(prev => ({ ...prev, packs: data })),
                    acceptTermsAndConditions: async (id) => { setAdminData(prev => ({ ...prev, user: { ...prev.user, accepted_terms_and_conditions_id: id } })); },
                    isSidebarVisible,
                    setIsSidebarVisible
                } },
                React.createElement(Wrapper, null)))));
};
