import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, ComboboxButton, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { Icon } from '../buttons/icon';
import { faCheck, faChevronDown, faChevronUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
const sizes = {
    font: {
        SM: 'text-xs',
        MD: 'text-sm',
        LG: 'text-base'
    },
    spacing: {
        SM: 'px-3 py-2',
        MD: 'px-4 py-2',
        LG: 'px-4 py-3'
    }
};
const colours = {
    // Primary and secondary are just default and light
    // They're only in here for type consistency
    PRIMARY: 'bg-white border-gray-300 text-gray-600',
    SECONDARY: 'bg-gray-100 text-gray-900 border-gray-100',
    DEFAULT: 'bg-white border-gray-300 text-gray-600',
    DARK: 'bg-gray-900 text-gray-100 border-gray-900',
    LIGHT: 'bg-gray-100 text-gray-900 border-gray-100',
    YELLOW: 'bg-yellow-100 text-yellow-900 border-yellow-100',
    RED: 'bg-red-100 text-red-900 border-red-100',
    BLUE: 'bg-blue-100 text-blue-900 border-blue-100',
    GREEN: 'bg-green-100 text-green-900 border-green-100'
};
const buttonStyles = (size, colour, focus) => `relative flex flex-col text-left w-full ${sizes.font[size]} ${sizes.spacing[size]} rounded-lg border ${colours[colour]} cursor-pointer ${focus && 'border-accent outline outline-accent'}`;
const dropdownContainerStyles = (type) => `[--anchor-gap:4px] empty:invisible ${type === 'simple' ? 'w-[var(--button-width)]' : 'w-[var(--input-width)]'} overflow-y-auto [--anchor-max-height:240px] z-20 rounded bg-white shadow border border-gray-200 focus:outline-none`;
const dropdownItemStyles = (size, selected, focus) => `group relative text-default cursor-pointer p-2 ${sizes.font[size]} ${sizes.spacing[size]} first:rounded-t last:rounded-b pr-10 ${focus ? 'bg-accent text-white' : selected ? 'bg-gray-100 text-default' : ''}`;
const Label = ({ label }) => (React.createElement("div", { className: "uppercase text-xs font-bold" }, label));
const Arrow = React.forwardRef(({ open, size, colour, loading }, ref) => {
    return (React.createElement("div", { className: `group absolute top-1/2 ${size === 'SM' ? 'right-3' : 'right-4'} -translate-y-1/2 ${sizes.font[size]} pointer-events-none`, ref: ref }, loading
        ? React.createElement(Icon, { icon: faSpinner, spin: true, colour: colours[colour] })
        : React.createElement(Icon, { icon: open ? faChevronUp : faChevronDown, colour: colours[colour] })));
});
const Check = ({ size, selected, focus }) => (React.createElement(Icon, { icon: faCheck, className: `absolute top-1/2 ${size === 'SM' ? 'right-3' : 'right-4'} -translate-y-1/2 ${sizes.font[size]} ${selected ? 'hidden sm:block' : 'hidden'} ${focus ? 'text-white' : 'text-default'}` }));
const valueForKey = (options, key) => { var _a; return (_a = options.find((option) => option.key === key)) === null || _a === void 0 ? void 0 : _a.value; };
const DEFAULT_PLACEHOLDER = 'Please select';
const SimpleSelect = ({ options, selectedKey, setSelectedKey, size = 'MD', colour = 'DEFAULT', label, placeholder, dataCy }) => {
    return (React.createElement(Listbox, { value: selectedKey, onChange: setSelectedKey },
        React.createElement(ListboxButton, { as: Fragment }, ({ open, focus, active }) => {
            var _a, _b;
            return (React.createElement("button", { className: `${buttonStyles(size, colour, [open, focus, active].some(Boolean))} w-full`, "data-cy": dataCy },
                label && React.createElement(Label, { label: label }),
                React.createElement("div", { className: "flex items-center w-full" },
                    React.createElement("div", { className: "mr-6" }, (_b = (_a = valueForKey(options, selectedKey)) !== null && _a !== void 0 ? _a : placeholder) !== null && _b !== void 0 ? _b : DEFAULT_PLACEHOLDER),
                    React.createElement(Arrow, { open: open, size: size, colour: colour }))));
        }),
        React.createElement(ListboxOptions, { className: dropdownContainerStyles('simple'), anchor: "bottom" }, options.map((option) => (React.createElement(ListboxOption, { as: Fragment, key: option.key, value: option.key }, ({ selected, focus }) => (React.createElement("div", { className: dropdownItemStyles(size, selected, focus) },
            option.value,
            React.createElement(Check, { size: size, selected: selected, focus: focus })))))))));
};
const FilterableSelect = ({ options, selectedKey, setSelectedKey, filterCallback, size = 'MD', colour = 'DEFAULT', placeholder, isLoading, autoFocus, dataCy, multiple }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    return (React.createElement(Combobox, { immediate: true, value: selectedKey, onChange: setSelectedKey, as: Fragment, multiple: multiple },
        React.createElement("div", { className: "relative w-full" },
            React.createElement(ComboboxInput, { displayValue: (value) => valueForKey(options, value), as: Fragment }, ({ focus }) => (React.createElement("input", { placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : DEFAULT_PLACEHOLDER, onChange: (event) => {
                    // If a filter function is provided, use it to filter the options (this may be a query to an API)
                    // Otherwise, just do a text search on the options
                    if (filterCallback) {
                        filterCallback(event.target.value);
                    }
                    else {
                        setFilteredOptions(options.filter((option) => option.value.toLowerCase().includes(event.target.value.toLowerCase())));
                    }
                }, className: `${buttonStyles(size, colour, focus)} pr-10`, autoComplete: 'off', autoFocus: autoFocus, "data-cy": dataCy }))),
            React.createElement(ComboboxButton, { as: Fragment }, ({ open }) => (React.createElement(Arrow, { open: open, size: size, colour: colour, loading: isLoading })))),
        React.createElement(ComboboxOptions, { anchor: "bottom", className: dropdownContainerStyles('filterable') }, (filterCallback ? options : filteredOptions).map((option) => (React.createElement(ComboboxOption, { key: option.key, value: option.key, as: Fragment }, ({ selected, focus }) => (React.createElement("div", { className: dropdownItemStyles(size, selected, focus) },
            option.value,
            React.createElement(Check, { size: size, selected: selected, focus: focus })))))))));
};
export const Select = ({ options, selectedKey, setSelectedKey, filter, filterCallback, size = 'MD', colour = 'DEFAULT', label, placeholder, isLoading, dataCy, autoFocus, multiple }) => {
    return filter ? (React.createElement(FilterableSelect, { options: options, selectedKey: selectedKey, setSelectedKey: setSelectedKey, filter: filter, size: size, colour: colour, placeholder: placeholder, filterCallback: filterCallback, isLoading: isLoading, dataCy: dataCy, autoFocus: autoFocus, multiple: multiple })) : (React.createElement(SimpleSelect, { options: options, selectedKey: selectedKey, setSelectedKey: setSelectedKey, size: size, colour: colour, label: label, placeholder: placeholder, dataCy: dataCy, multiple: multiple }));
};
