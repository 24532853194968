import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { getRoomTemp } from '../../../code/models/heat_loss';
import { max, min } from 'lodash';
export const HLRCalculationConditionsPage = () => {
    const hlrContext = React.useContext(HLRContext);
    const rooms = hlrContext.survey.floors.flatMap(x => x.rooms).map(x => getRoomTemp(x, hlrContext.survey));
    const minRoomTemp = min(rooms);
    const maxRoomTemp = max(rooms);
    const tableContent = [
        {
            title: 'Design outdoor air temperature',
            description: 'For the design outdoor temperature we use the “99th percentile” temperature. That means that the outdoor temperature in your area only falls below this temperature 1% of the time.',
            value: `${hlrContext.designTempC} °C`
        },
        {
            title: 'Design ground temperature',
            description: 'The ground temperature is used in calculating the heat loss through your floor. It’s the average temperature in your area across the year, because the ground temperature stays very constant through the year.',
            value: `${hlrContext.groundTempC} °C`
        },
        {
            title: 'Heating degree days',
            description: 'Heating degree days are a measure of how much heating your home needs over the whole year based on the typical temperature profile for your location.',
            value: `${hlrContext.degreeDays} °C days`
        },
        {
            title: 'Indoor temperature',
            description: 'The indoor temperature set point that the system needs to be able to maintain in each room. The value used in each room is shown in the room by room breakdown section.',
            value: `${minRoomTemp} °C to ${maxRoomTemp} °C depending on the room`
        },
        {
            title: 'Intermittent heating',
            description: 'Heating a home up from cold requires more power than just maintaining a temperature. For intermittently occupied homes like holiday homes you might slightly oversize the system to allow it to warm up the home more quickly when it’s needed. For a home that is occupied normally this would just result in the heat pump cycling, thus reducing efficiency.',
            value: hlrContext.survey.intermittent_heating ? 'Yes: heat loss increased by 10% to account for this' : 'No: assume property is occupied all winter'
        },
        {
            title: 'Exposed location',
            description: 'Properties very exposed to the wind will lose heat more quickly than typical so we would size the system to account for this.',
            value: hlrContext.survey.exposed_location ? 'Yes: design outdoor air temperature reduced by 1C to account for this' : 'No: property is not in an exposed location such as on the coast'
        },
        {
            title: 'MVHR',
            description: 'Mechanical ventilation with heat recovery systems capture heat from exhaust air and use it to pre-heat incoming air. They therefore reduce heat loss due to ventilation compared to standard mechanical ventilation systems (like bathroom fans + air vents) because they recycle some of the heat.',
            value: hlrContext.survey.mvhr_installed ? 'Yes: ventilation heat loss reduced by 50% to account for the heat recovery' : 'No: a ventilation system with heat recovery is not present'
        }
    ];
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBLock, { section: "Heat loss", title: "Calculation Conditions", description: "When calculating your home\u2019s heat loss we design to certain conditions. This section shows the conditions used for your home." }),
        React.createElement("div", { className: "flex flex-col" }, tableContent.map((item, index) => (React.createElement("div", { key: index, className: `${index === tableContent.length - 1 ? '' : 'border-b border-dashed border-gray-300'} justify-between flex flex-row gap-9 py-3 items-center` },
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: "text-gray-900 font-bold" }, item.title),
                React.createElement("div", { className: "text-gray-500 text-xs tracking-tight" }, item.description)),
            React.createElement("div", { className: "min-w-44 max-w-44 text-gray-900 text-xs tracking-tight" }, item.value))))),
        React.createElement("div", { className: "" }, "All calculations have been done in compliance with BS EN12831 (UK National Annex) and comply with the standards laid out in the Microgeneration Certification Scheme.")));
};
