import React, { useState, useEffect } from 'react';
import { DesignPageHeader } from '../design/components/design_page_header';
import { pipeMaterials } from '../../../code/models/property';
import { CompleteButton } from './complete_button';
import { faChevronRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { PIPE_MODELS, getPipeModelName } from '../../../code/models/pipes';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { getEmitterTypeName, getEmitterSizeName } from '../../../code/models/radiator';
import { tryParseFloat } from '../../../code/helpers';
import { Icon } from '../../../components/buttons/icon';
import { Input } from '../../../components/inputs_and_selections/input';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
import { Info } from '../../../components/buttons/info';
import { Select } from '../../../components/inputs_and_selections/select';
export const Pipework = ({ survey, setSurvey, isOffline, allSynced }) => {
    const page = 'PIPEWORK';
    const [currentPage, setCurrentPage] = useState('MAIN');
    // Add the room onto the Emitter object to make it easier for the user to choose the index rad
    const surveyedEmittersWithRooms = survey.floors
        .flatMap(x => x.rooms
        .flatMap(y => y.radiators
        .filter(x => x.emitter_type !== 'SECONDARY')
        .map(z => ({ ...z, room: y }))));
    const possibleIndexEmittersWithRooms = survey.secondary_index_emitter_uuids.length > 0
        ? surveyedEmittersWithRooms.filter(x => survey.secondary_index_emitter_uuids.includes(x.uuid))
        : surveyedEmittersWithRooms;
    const indexEmitterWithRoom = surveyedEmittersWithRooms.find(x => x.uuid === survey.index_emitter_uuid);
    const emittersOnCircuitLength = survey.secondary_index_emitter_uuids.length;
    // Define setters needed in the pipework inputs
    const setPrimaryPipeModel = async (pipeModelUUID) => await setSurvey(prev => ({ ...prev, existing_system_pipework_uuid: pipeModelUUID }));
    const setPrimaryPipeLength = async (pipeLengthM) => await setSurvey(prev => ({ ...prev, primary_pipework_length_m: pipeLengthM }));
    const setSecondaryPipeModel = async (pipeModelUUID) => await setSurvey(prev => ({ ...prev, secondary_index_pipework_uuid: pipeModelUUID }));
    const setSecondaryPipeLength = async (pipeLengthM) => await setSurvey(prev => ({ ...prev, secondary_index_pipework_length_m: pipeLengthM }));
    const setIndexEmitterUuid = async (indexEmitterUuid) => await setSurvey(prev => ({ ...prev, index_emitter_uuid: indexEmitterUuid }));
    const setIndexPipeModel = (pipeModelUUID) => {
        const { room, ...indexEmitter } = indexEmitterWithRoom;
        const updatedEmitter = { ...indexEmitter, pipe_model_uuid: pipeModelUUID };
        const radiatorRoom = survey.floors.flatMap(x => x.rooms).find(x => x.radiators.some(y => y.uuid === updatedEmitter.uuid));
        const radiatorFloor = survey.floors.find(x => x.rooms.some(y => y.uuid === (radiatorRoom === null || radiatorRoom === void 0 ? void 0 : radiatorRoom.uuid)));
        const newRoom = { ...radiatorRoom, radiators: radiatorRoom === null || radiatorRoom === void 0 ? void 0 : radiatorRoom.radiators.map(x => x.uuid === updatedEmitter.uuid ? updatedEmitter : x) };
        const newFloors = survey.floors.map(x => x.uuid === (radiatorFloor === null || radiatorFloor === void 0 ? void 0 : radiatorFloor.uuid) ? { ...radiatorFloor, rooms: radiatorFloor.rooms.map(y => y.uuid === newRoom.uuid ? newRoom : y) } : x);
        setSurvey(prev => ({ ...prev, floors: newFloors }));
    };
    const setIndexPipeLength = async (pipeLengthM) => await setSurvey(prev => ({ ...prev, index_emitter_pipe_length_m: pipeLengthM }));
    // Split out pipe material so can update based on pipe model
    const [primaryPipeMaterial, setPrimaryPipeMaterial] = useState();
    const [secondaryPipeMaterial, setSecondaryPipeMaterial] = useState('Copper');
    const [indexEmitterPipeMaterial, setIndexEmitterPipeMaterial] = useState();
    useEffect(() => {
        var _a, _b, _c;
        // Needed to update the pipe material based on the pipe model without having an infinite loop
        const pipeModel = PIPE_MODELS.find(x => x.uuid === survey.existing_system_pipework_uuid);
        const secondaryPipeModel = PIPE_MODELS.find(x => x.uuid === survey.secondary_index_pipework_uuid);
        const indexEmitterPipeModel = PIPE_MODELS.find(x => x.uuid === (indexEmitterWithRoom === null || indexEmitterWithRoom === void 0 ? void 0 : indexEmitterWithRoom.pipe_model_uuid));
        setPrimaryPipeMaterial((_a = pipeModel === null || pipeModel === void 0 ? void 0 : pipeModel.material) !== null && _a !== void 0 ? _a : 'Copper');
        setSecondaryPipeMaterial((_b = secondaryPipeModel === null || secondaryPipeModel === void 0 ? void 0 : secondaryPipeModel.material) !== null && _b !== void 0 ? _b : 'Copper');
        setIndexEmitterPipeMaterial((_c = indexEmitterPipeModel === null || indexEmitterPipeModel === void 0 ? void 0 : indexEmitterPipeModel.material) !== null && _c !== void 0 ? _c : 'Copper');
    }, [survey]);
    if (currentPage === 'SECONDARY_EMITTERS') {
        return React.createElement(SecondaryEmittersSelectPage, { isOffline: isOffline, allSynced: allSynced, completed: survey.completed_sections.includes(page), onBack: () => setCurrentPage('MAIN'), emitterUUIDs: survey.secondary_index_emitter_uuids, toggleEmitter: async (rad) => await setSurvey(prev => ({
                ...prev,
                secondary_index_emitter_uuids: prev.secondary_index_emitter_uuids.some(y => y === rad.uuid)
                    ? prev.secondary_index_emitter_uuids.filter(y => y !== rad.uuid) // if it was included, remove it
                    : [...prev.secondary_index_emitter_uuids, rad.uuid] // if it wasn't included, add it
            })), allEmitters: surveyedEmittersWithRooms });
    }
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(DesignPageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Pipework', onBack: () => window.history.back(), completed: survey.completed_sections.includes(page) }),
        React.createElement("div", { className: 'overflow-y-auto flex-grow' },
            React.createElement("div", { className: "p-5 flex-col gap-6 flex" },
                React.createElement("div", { className: "items-center gap-1 flex" },
                    React.createElement("div", { className: 'text-sm\'}' }, "Enter the details of the index circuit for this home so we can calculate the pressure drop"),
                    React.createElement(IndexCircuitInfo, null)),
                React.createElement(PrimaryPipeFormLabel, null),
                React.createElement(PipeworkInputs, { pipeMaterial: primaryPipeMaterial, setPipeMaterial: setPrimaryPipeMaterial, pipeModelUUID: survey.existing_system_pipework_uuid, setPipeModel: setPrimaryPipeModel, pipeworkLengthM: survey.primary_pipework_length_m, setPipeworkLength: setPrimaryPipeLength, pipeLengthHelperText: PRIMARY_PIPE_LENGTH_HELPER_TEXT }),
                React.createElement("div", { className: "h-0 border border-gray-200" }),
                React.createElement(SecondaryPipeFormLabel, null),
                React.createElement(SecondaryEmittersCard, { setCurrentPage: setCurrentPage, emittersOnCircuitLength: emittersOnCircuitLength, numberOfEmitters: surveyedEmittersWithRooms.length }),
                React.createElement(PipeworkInputs, { pipeMaterial: secondaryPipeMaterial, setPipeMaterial: setSecondaryPipeMaterial, pipeModelUUID: survey.secondary_index_pipework_uuid, setPipeModel: setSecondaryPipeModel, pipeworkLengthM: survey.secondary_index_pipework_length_m, setPipeworkLength: setSecondaryPipeLength, pipeLengthHelperText: SECONDARY_PIPE_LENGTH_HELPER_TEXT }),
                React.createElement("div", { className: "h-0 border border-gray-200" }),
                React.createElement(IndexEmitterFormLabel, null),
                React.createElement(IndexEmitterSelect, { indexEmitterUuid: survey.index_emitter_uuid, setIndexEmitterUuid: setIndexEmitterUuid, possibleIndexEmittersWithRooms: possibleIndexEmittersWithRooms }),
                survey.index_emitter_uuid && React.createElement(PipeworkInputs, { pipeMaterial: indexEmitterPipeMaterial, setPipeMaterial: setIndexEmitterPipeMaterial, pipeModelUUID: indexEmitterWithRoom === null || indexEmitterWithRoom === void 0 ? void 0 : indexEmitterWithRoom.pipe_model_uuid, setPipeModel: setIndexPipeModel, pipeworkLengthM: survey.index_emitter_pipe_length_m, setPipeworkLength: setIndexPipeLength, pipeLengthHelperText: INDEX_PIPE_LENGTH_HELPER_TEXT, editPipeModel: (indexEmitterWithRoom === null || indexEmitterWithRoom === void 0 ? void 0 : indexEmitterWithRoom.type) !== 'UNDERFLOOR' }))),
        React.createElement(CompleteButton, { onBack: async () => window.history.back(), page: page, survey: survey, setSurvey: setSurvey, pageChecks: [] }));
};
export const PipeworkInputs = ({ pipeMaterial, setPipeMaterial, pipeModelUUID, setPipeModel, pipeworkLengthM, setPipeworkLength, pipeLengthHelperText, editPipeModel = true, lengthOverridden = false, clearOverride = undefined }) => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-col gap-5 flex" },
            editPipeModel && React.createElement(React.Fragment, null,
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Material' }), input: React.createElement(RadioGroup, { items: pipeMaterials.map(x => ({
                            name: x,
                            onClick: () => setPipeMaterial(x),
                            variant: pipeMaterial === x ? 'ACTIVE' : 'DEFAULT'
                        })) }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Diameter' }), input: React.createElement(Select, { options: PIPE_MODELS.filter(x => x.material === pipeMaterial).map(x => ({
                            key: x.uuid,
                            value: getPipeModelName(x)
                        })), selectedKey: pipeModelUUID, setSelectedKey: (e) => setPipeModel(e) }) })),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Approximate pipe length (flow only)', helperText: pipeLengthHelperText }), input: React.createElement(Input, { type: 'number', value: pipeworkLengthM !== null && pipeworkLengthM !== void 0 ? pipeworkLengthM : '', setValue: (e) => setPipeworkLength(tryParseFloat(e, undefined)), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" },
                        React.createElement("div", { className: "" }, "metres"),
                        lengthOverridden && clearOverride && React.createElement(Icon, { icon: faTimesCircle, onClick: clearOverride, colour: 'text-gray-400' })) }) })));
};
export const SecondaryEmittersCard = ({ setCurrentPage, emittersOnCircuitLength, numberOfEmitters }) => {
    return React.createElement("div", { onClick: () => setCurrentPage('SECONDARY_EMITTERS'), className: "items-center gap-4 flex justify-between cursor-pointer" },
        React.createElement("div", { className: "flex-col flex" },
            React.createElement(FormLabel, { labelText: 'Emitters on this secondary', helperText: emittersOnCircuitLength === 0
                    ? 'Please indicate which emitters are fed from this secondary.'
                    : `${emittersOnCircuitLength}/${numberOfEmitters} emitters are served by this secondary` })),
        React.createElement(Icon, { icon: faChevronRight }));
};
export const IndexEmitterSelect = ({ indexEmitterUuid, setIndexEmitterUuid, possibleIndexEmittersWithRooms }) => {
    return React.createElement("div", { className: "flex-col gap-2 flex" },
        React.createElement(FormLabel, { labelText: 'Index Emitter' }),
        React.createElement(Select, { selectedKey: indexEmitterUuid, setSelectedKey: (e) => setIndexEmitterUuid(e), options: possibleIndexEmittersWithRooms.map(x => ({
                key: x.uuid,
                value: `${x.room.name} - ${getEmitterTypeName(x)} • ${getEmitterSizeName(x)}`
            })) }));
};
export const SecondaryEmittersSelectPage = ({ onBack, completed, allEmitters, emitterUUIDs, toggleEmitter, isOffline, allSynced }) => {
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(DesignPageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Pipework', onBack: onBack, completed: completed }),
        React.createElement("div", { className: 'overflow-y-auto flex-grow' },
            React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex" },
                React.createElement("div", null, "Please select the emitters that are fed by this secondary circuit."),
                React.createElement("div", { className: 'flex flex-col divide-y divide-gray-200' }, allEmitters.map(x => React.createElement("div", { key: x.uuid, className: 'flex items-center justify-between gap-3 first:pt-0 last:pb-0 py-3' },
                    React.createElement("div", { className: "flex-col flex" },
                        React.createElement("div", { className: "font-bold text-gray-900" }, x.room.name),
                        React.createElement("div", { className: "text-gray-500 text-sm" },
                            getEmitterTypeName(x),
                            " \u2022 ",
                            getEmitterSizeName(x))),
                    React.createElement(Toggle, { value: emitterUUIDs.some(y => y === x.uuid), setValue: () => toggleEmitter(x) })))))));
};
// Make the section headers + info compontents reusable between survey and design
export const PrimaryPipeFormLabel = () => {
    return React.createElement(FormLabel, { labelText: 'Primary', helperText: 'Enter the details of the primary pipework', size: 'XL', info: React.createElement(Info, { infoModalHeader: 'What is the primary pipework?', infoModalBody: 'The primary carries the full output from the heat pump to the first split in the space heating circuit' }) });
};
export const SecondaryPipeFormLabel = () => {
    return React.createElement(FormLabel, { labelText: 'Secondary', size: 'XL', helperText: 'Enter the details of the secondary pipe on the index circuit', info: React.createElement(Info, { infoModalHeader: 'What is the secondary pipework?', infoModalBody: 'The secondaries are the pipes that split out from the primary to take heat to groups of emitters. ' +
                'They need to be able to carry all the heat required by the emitters they serve. ' +
                'The secondary we need for the index circuit calculations is the one that that ends up taking heat to the index emitter. ' +
                'For example if your furthest rad is on the second floor then the secondary needed would be the pipe that splits off from the primary to take heat to the second floor.' }) });
};
export const IndexEmitterFormLabel = () => {
    return React.createElement(FormLabel, { labelText: 'Index Emitter', size: 'XL', helperText: 'Enter the details of the emitter on the index circuit and the pipe that serves it.', info: React.createElement(Info, { infoModalHeader: 'What is the index emitter?', infoModalBody: 'The emitter at the end of your index circuit. It\'s typically the emitter that\'s furthest from the heat pump or low loss header, but it could be a different emitter if it\'s particularly large or on particularly small pipes.' }) });
};
export const IndexCircuitInfo = () => {
    return React.createElement(Info, { infoModalHeader: 'What is the index circuit?', infoModalBody: 'The index circuit is the route from the heat pump to an emitter and back with the highest pressure drop.' +
            ' Typically this is the route to the furthest rad, but it could be to a particularly large emitter' +
            ' or to an emitter on particularly small pipes. If you have a buffer/low loss header then it\'s the route from the buffer to the the furthest rad.' });
};
export const PRIMARY_PIPE_LENGTH_HELPER_TEXT = 'From the heat pump to the first split in the space heating circuit.';
export const SECONDARY_PIPE_LENGTH_HELPER_TEXT = 'The length of pipe that splits from the primary up to the individual pipe splitting off to feed the index emitter';
export const INDEX_PIPE_LENGTH_HELPER_TEXT = 'The length of pipe that serves only this emitter';
