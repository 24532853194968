import { getCompanyEstimateCoverNote, updateCompanySettings } from '../../../../../code/models/company';
import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderEstimateContactName, TTPlaceholderEstimateContactPhone, TTPlaceholderInstallerName } from '../../../../../code/tiptap_placeholders';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { SettingsImageInput } from '../../components/settings_image_input';
export const EstimateCoverNoteTemplateBlock = ({ company, setCompany }) => {
    var _a, _b, _c, _d;
    return React.createElement(Section, { border: true, title: 'Cover note' },
        React.createElement("div", { className: 'flex flex-col gap-4  ' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Message' }), input: React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                            setCompany({
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    calculated_estimate_cover_note: JSON.stringify(editor.getJSON())
                                }
                            });
                        }, placeholders: [
                            TTPlaceholderInstallerName,
                            TTPlaceholderCompanyName,
                            TTPlaceholderCompanyPhone,
                            TTPlaceholderEstimateContactName,
                            TTPlaceholderEstimateContactPhone
                        ], content: getCompanyEstimateCoverNote(company.public_info.calculated_estimate_cover_note || '') }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Sender' }), input: React.createElement(Input, { value: (_a = company.public_info.estimate_contact_name) !== null && _a !== void 0 ? _a : '', setValue: senderInput => setCompany({
                            ...company,
                            public_info: {
                                ...company.public_info,
                                estimate_contact_name: senderInput
                            }
                        }), placeholder: 'Estimate contact name' }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Role' }), input: React.createElement(Input, { value: (_b = company.public_info.estimate_contact_role) !== null && _b !== void 0 ? _b : '', setValue: roleInput => setCompany({
                            ...company,
                            public_info: {
                                ...company.public_info,
                                estimate_contact_role: roleInput
                            }
                        }), placeholder: 'Estimate contact role' }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Phone' }), input: React.createElement(Input, { value: (_c = company.public_info.estimate_contact_phone) !== null && _c !== void 0 ? _c : '', setValue: senderInput => setCompany({
                            ...company,
                            public_info: {
                                ...company.public_info,
                                estimate_contact_phone: senderInput
                            }
                        }), placeholder: 'Estimate contact phone' }) })),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Headshot', helperText: 'For best results, image should be square' }), input: React.createElement(SettingsImageInput, { imgClassName: 'rounded-full aspect-square object-cover', buttonText: 'Upload headshot', placeholderText: 'No headshot', helperText: 'Maximum file size 5MB', b64Image: (_d = company.public_info.estimate_contact_portrait) !== null && _d !== void 0 ? _d : '', altText: 'Estimate contact headshot', handleUpdateImage: async (imageSrc) => {
                        const newCompany = {
                            ...company,
                            public_info: {
                                ...company.public_info,
                                estimate_contact_portrait: imageSrc
                            }
                        };
                        await updateCompanySettings(newCompany);
                        setCompany(newCompany);
                    }, handleRemoveImage: async () => {
                        const newCompany = {
                            ...company,
                            public_info: {
                                ...company.public_info,
                                estimate_contact_portrait: ''
                            }
                        };
                        await updateCompanySettings(newCompany);
                        setCompany(newCompany);
                    } }) })));
};
