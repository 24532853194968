import React, { useRef, useState } from 'react';
import { faArrowUpFromBracket, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../components/buttons/button';
import { resizeBase64Image } from '../../../../components/inputs_and_selections/photo_scroll';
export const SettingsImageInput = ({ b64Image, altText = 'Image upload result', handleUpdateImage: handleUpdateLogo, handleRemoveImage: handleRemoveLogo, placeholderText: inputText, helperText, buttonText, imgClassName }) => {
    const [fileSizeValid, setFileSizeValid] = useState(true);
    const fileInputRef = useRef(null);
    return (React.createElement("div", { className: 'flex flex-col gap-4' },
        (b64Image) && React.createElement("div", { className: 'flex flex-wrap gap-4 justify-between' },
            React.createElement("img", { src: b64Image, className: ` ${imgClassName}`, alt: altText }),
            React.createElement("div", { className: "justify-start items-center gap-3 flex" },
                React.createElement(Button, { colour: 'LIGHT', iconLeft: faArrowUpFromBracket, onClick: () => { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, "Change"),
                React.createElement(Button, { colour: 'LIGHT', iconLeft: faTrashAlt, onClick: handleRemoveLogo }, "Remove"))),
        !b64Image && React.createElement("div", { className: `h-56 px-6 py-12 bg-gray-50 rounded-lg ${!fileSizeValid && 'border border-red-500'}` },
            React.createElement("div", { className: 'flex flex-col gap-4 items-center justify-center' },
                React.createElement("div", { className: 'font-bold' }, inputText),
                React.createElement("div", { className: 'text-gray-500' }, helperText),
                React.createElement(Button, { iconLeft: faArrowUpFromBracket, onClick: () => { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, buttonText))),
        React.createElement("input", { ref: fileInputRef, id: "company-logo", className: 'hidden', type: "file", accept: 'image/*', capture: "environment", onChange: (e) => {
                var _a, _b;
                const newFile = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
                if (!newFile)
                    return;
                // Max file size is 5MB
                if (newFile.size > 5 * 1024 * 1024) {
                    setFileSizeValid(false);
                    return;
                }
                setFileSizeValid(true);
                const reader = new FileReader();
                reader.onload = async (e) => {
                    var _a;
                    const resized = await resizeBase64Image((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
                    handleUpdateLogo(resized);
                };
                reader.readAsDataURL(newFile);
            } }),
        !fileSizeValid && React.createElement("div", { className: 'text-red-700 text-sm' }, "File size must be less than 5MB")));
};
