import { Bars3Icon } from '@heroicons/react/16/solid';
import React from 'react';
export const CostsHeader = ({ adminContext }) => {
    return (React.createElement("div", { className: "flex md:items-center flex-col md:flex-row gap-4 px-6 pt-3" },
        React.createElement("div", { className: 'flex items-center gap-4' },
            React.createElement("div", { className: 'flex items-center gap-2' },
                React.createElement(Bars3Icon, { "data-cy": 'nav_open', className: "lg:hidden size-6 cursor-pointer", onClick: () => {
                        adminContext.showSidebar();
                    } }),
                React.createElement("h1", { className: "text-2xl font-semibold text-bold" }, "Costs & inventory")))));
};
