import { useEffect, useState } from 'react';
export const useNavigation = () => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname.replace(/\/$/, ''));
    useEffect(() => {
        const handlePopState = () => {
            setCurrentPath(window.location.pathname.replace(/\/$/, ''));
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState); // cleanup on unmount
        };
    }, []);
    const navigateTo = (url) => {
        history.pushState(null, '', url);
        setCurrentPath(url);
    };
    return { currentPath, navigateTo };
};
