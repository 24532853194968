import React, { useState } from 'react';
import { DesignPageHeader } from '../components/design_page_header';
import { ClickableCard } from '../../../../components/content_display/card';
import { Input } from '../../../../components/inputs_and_selections/input';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
import { SelectAHeatPumpPage } from '../../select_heat_pump';
import { SelectedHeatPump } from '../../selected_heat_pump';
import { SoundAssessmentPage } from '../../sound_assessment_page';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { FlowTempSlider } from './emitter_design_page';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
export const HeatPumpDesign = ({ survey, design, setSurvey, setDesign, currentHeatPump, totalHeatLossKW, files, setFiles, locationImages, setLocationImages, soundCalculation, heatPumps, designTempC, minFlowTemp, maxFlowTemp, scop, companyUUID, isOffline, allSynced }) => {
    const [currentPage, setCurrentPage] = useState('MAIN');
    const locationImagesHydrated = locationImages.map(x => files.find(y => y.uuid === x.image_uuid));
    if (currentPage === 'SELECT_HEAT_PUMP') {
        return React.createElement(SelectAHeatPumpPage, { heatPumps: heatPumps, designTempC: designTempC, flowTempC: design.flow_temp, totalHeatLossKW: totalHeatLossKW, setCurrentPage: setCurrentPage, design: design, setDesign: setDesign, isOffline: isOffline, allSynced: allSynced });
    }
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(DesignPageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Heat pump', onBack: () => window.history.back() }),
        React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex overflow-y-auto" },
            React.createElement("div", { className: "flex-col gap-6 flex" },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Heat pump design"),
                React.createElement(SelectedHeatPump, { currentHeatPump: currentHeatPump, soundCalculation: soundCalculation, variant: 'DESIGN', designTempC: designTempC, scop: scop, design: design, totalHeatLossKW: totalHeatLossKW, setCurrentPage: setCurrentPage }),
                React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: (e) => setDesign({ ...design, flow_temp: e }), minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Target delta T', helperText: 'Enter the target temperature drop between the heat pump flow and return.' }), input: React.createElement(Input, { postfix: '\u00B0C', type: 'number', value: design.delta_t_flow_return_c.toString(), setValue: (e) => setDesign({ ...design, delta_t_flow_return_c: Number(e) }) }) })),
            React.createElement("div", { className: "border border-gray-200" }),
            React.createElement("div", { className: "flex-col gap-6 flex" },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Survey summary"),
                React.createElement(ClickableCard, { variant: 'WHITE' },
                    React.createElement("div", { className: "flex-col flex text-xs font-semibold" },
                        React.createElement("div", { className: "py-2 justify-between gap-2 flex" },
                            React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                                "Heat loss at ",
                                designTempC,
                                " \u00B0C"),
                            React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                                totalHeatLossKW.toFixed(2),
                                " kW")),
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", null, "Dimensions (WxHxD)"),
                            React.createElement("div", null,
                                survey.available_space_width_mm,
                                " x ",
                                survey.available_space_height_mm,
                                " x ",
                                survey.available_space_depth_mm,
                                " mm")),
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", null, "Location"),
                            React.createElement("div", null, survey.location_description)),
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", null, "Mounted on"),
                            survey.mounting_location !== 'Flat roof' && React.createElement("div", null, survey.mounting_location),
                            survey.mounting_location === 'Flat roof' &&
                                React.createElement("div", null, `${survey.mounting_location} (${survey.roof_height_m}m)`)),
                        survey.mounting_location === 'Ground' &&
                            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                React.createElement("div", null, "Base to be built by"),
                                React.createElement("div", null, survey.base_to_be_build_by)),
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", null, "Condensate drain"),
                            React.createElement("div", null, survey.condensate_drain)))),
                React.createElement("div", { className: "flex-col gap-3 flex" },
                    React.createElement(PhotoScroll, { images: locationImagesHydrated, deleteImage: (id) => {
                            setLocationImages(locationImages.filter(x => x.image_uuid !== id));
                        }, addImage: (image) => {
                            setFiles(prev => [...prev, { ...image, created_at: new Date().getTime(), updated_at: new Date().getTime() }]);
                            const newLocationImage = {
                                uuid: crypto.randomUUID(),
                                image_uuid: image.uuid,
                                entity_uuid: 'survey',
                                entity_attribute: 'location_images'
                            };
                            setLocationImages([...locationImages, newLocationImage]);
                        }, companyUUID: companyUUID }))),
            React.createElement("div", { className: "border border-gray-200" }),
            React.createElement(SoundAssessmentPage, { survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, soundCalculation: soundCalculation, currentHeatPump: currentHeatPump, companyUUID: companyUUID })));
};
