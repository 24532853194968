import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { updateCompanySettings } from '../../../../../code/models/company';
import { SettingsImageInput } from '../../components/settings_image_input';
export const BrandLogoBlock = ({ company, setCompany }) => {
    var _a;
    const handleRemoveLogo = () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                logo: ''
            }
        };
        setCompany(newCompany);
        updateCompanySettings(newCompany);
    };
    const handleUpdateLogo = async (imageSrc) => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                logo: imageSrc
            }
        };
        await updateCompanySettings(newCompany);
        setCompany(newCompany);
    };
    return React.createElement(Section, { border: true, title: "Company logo" },
        React.createElement(SettingsImageInput, { buttonText: 'Upload logo', placeholderText: 'No logo', helperText: 'Maximum file size 5MB', b64Image: (_a = company.public_info.logo) !== null && _a !== void 0 ? _a : '', altText: 'Company logo', handleUpdateImage: handleUpdateLogo, handleRemoveImage: handleRemoveLogo }));
};
