import React, { useState } from 'react';
import { FLOOR_COVERINGS, getUnderfloorHeatingOutput, SURFACE_TEMPERATURE_DATA, UFH_PIPE_CENTRES } from '../../../code/models/underfloor_heating';
import { Input } from '../../../components/inputs_and_selections/input';
import { ClickableCard } from '../../../components/content_display/card';
import { RadioGroup } from '../../../components/inputs_and_selections/radio';
import { Button } from '../../../components/buttons/button';
import { getFloorAreaM2, getRoomTemp, getRoomWatts } from '../../../code/models/heat_loss';
import { PhotoScroll } from '../../../components/inputs_and_selections/photo_scroll';
import { DEFAULT_MANIFOLD } from '../../../code/survey_defaults';
import { ManifoldDetailPage } from './manifold';
import { Link } from '../../../components/buttons/link';
import { Icon } from '../../../components/buttons/icon';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { uniqBy } from 'lodash';
import { validateTemperatureRange } from '../../../code/validators';
import { PIPE_MODELS } from '../../../code/models/pipes';
import { numberFormat } from '../../../code/number_format';
import { Select } from '../../../components/inputs_and_selections/select';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Alert } from '../../../components/indicators_and_messaging/alert';
import { Info } from '../../../components/buttons/info';
import { FlowTempSlider } from '../design/pages/emitter_design_page';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
export const UnderfloorEmitter = ({ room, underfloorHeating, setUnderfloorHeating, floor, designTempC, groundTempC, setFlowTemp, minFlowTemp, maxFlowTemp, survey, onSave, files, setFiles, design, setSurvey, page, setPage, onBack, companyUUID }) => {
    const [currentManifold, setCurrentManifold] = useState();
    const images = underfloorHeating.photos.map(x => files.find(y => y.uuid === x.image_uuid));
    const deleteImage = (imageUUID) => {
        setUnderfloorHeating(prev => ({ ...prev, photos: prev.photos.filter(x => x.image_uuid !== imageUUID) }));
    };
    const roomTempC = getRoomTemp(room, survey);
    const floorAreaM2 = getFloorAreaM2(room.walls);
    const roomWatts = getRoomWatts(room, floor.rooms, designTempC, groundTempC, survey);
    const roomWattsPerM2 = roomWatts / floorAreaM2;
    const meanSystemFlowTempC = design.flow_temp - design.delta_t_flow_return_c / 2;
    const ufhOutputs = getUnderfloorHeatingOutput(underfloorHeating, survey.manifolds, roomTempC, floorAreaM2, design.flow_temp, design.delta_t_flow_return_c);
    const validPipeCentres = uniqBy(SURFACE_TEMPERATURE_DATA
        .filter(x => x.floor_construction === underfloorHeating.floor_construction_type)
        .map(x => UFH_PIPE_CENTRES.find(y => y.uuid === x.pipe_spacing_mm)), x => x.uuid);
    const validPipeDiameters = uniqBy(SURFACE_TEMPERATURE_DATA
        .filter(x => x.floor_construction === underfloorHeating.floor_construction_type && x.pipe_spacing_mm === underfloorHeating.pipe_centres_uuid)
        .map(x => PIPE_MODELS.find(y => y.uuid === x.pipe_model_uuid)), x => x.uuid);
    if (page === 'MANIFOLD' && currentManifold) {
        return React.createElement("div", { className: 'flex flex-col' },
            React.createElement(ManifoldDetailPage, { manifold: currentManifold, setManifold: setCurrentManifold, files: files, setFiles: setFiles, companyUUID: companyUUID }),
            React.createElement("div", { className: 'p-4 bg-white' },
                React.createElement(Button, { disabled: !currentManifold.name ||
                        (currentManifold.ufh_temp_differs_from_system_temp && currentManifold.max_mean_water_temp_c !== undefined && !validateTemperatureRange(currentManifold.max_mean_water_temp_c, 30, 55).value), block: true, onClick: () => {
                        var _a;
                        const uuid = (_a = currentManifold.uuid) !== null && _a !== void 0 ? _a : crypto.randomUUID();
                        setSurvey(prev => ({
                            ...prev,
                            manifolds: prev.manifolds.some(x => x.uuid === currentManifold.uuid)
                                ? prev.manifolds.map(x => x.uuid === currentManifold.uuid ? currentManifold : x)
                                : [...prev.manifolds, { ...currentManifold, uuid }]
                        }));
                        setUnderfloorHeating(prev => ({ ...prev, manifold_uuid: uuid }));
                        setCurrentManifold(undefined);
                        onBack();
                    } }, "Apply")));
    }
    return React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'flex flex-col p-5 gap-5 overflow-y-auto' },
            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Inputs"),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement(FormLabel, { labelText: 'Manifold', helperText: 'Select or add the manifold that supplies this room' }),
                React.createElement(RadioGroup, { isVertical: true, items: survey.manifolds.map(x => ({
                        name: x.name,
                        description: x.ufh_temp_differs_from_system_temp ? `${x.max_mean_water_temp_c}°C mean water temp` : undefined,
                        variant: x.uuid === underfloorHeating.manifold_uuid ? 'ACTIVE' : 'DEFAULT',
                        onClick: () => setUnderfloorHeating(prev => ({ ...prev, manifold_uuid: x.uuid })),
                        rightContent: React.createElement("div", { className: 'flex gap-4 items-center' },
                            React.createElement(Link, { text: 'Edit', onClick: () => {
                                    setPage('MANIFOLD');
                                    setCurrentManifold(x);
                                } }),
                            React.createElement(Icon, { onClick: () => setSurvey(prev => ({
                                    ...prev,
                                    manifolds: prev.manifolds.filter(y => y.uuid !== x.uuid)
                                })), icon: faTrashCan, confirmTextHeader: 'Delete manifold?' }))
                    })) }),
                React.createElement(Button, { onClick: () => {
                        setPage('MANIFOLD');
                        setCurrentManifold({
                            ...DEFAULT_MANIFOLD,
                            name: `Manifold ${survey.manifolds.length + 1}`,
                            flow_temp_c: design.flow_temp,
                            return_temp_c: design.flow_temp - 5
                        });
                    } }, "Add manifold")),
            React.createElement("div", { className: " flex flex-col gap-3" },
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Floor construction', info: React.createElement(Info, { infoModalHeader: 'Floor construction', infoModalBody: 'The output data we use is from the CIBSE underfloor heating design and installation guide.' +
                                ' For Solid (Screed) floors we use the Type A Solid Floor System data.' +
                                ' For Suspended floors we use the Type B or Type B1 "Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors" data. ' }) }), input: React.createElement(RadioGroup, { items: [
                            {
                                name: 'Solid (Screed)',
                                variant: underfloorHeating.floor_construction_type === 'Solid (Screed)' ? 'ACTIVE' : 'DEFAULT',
                                onClick: () => setUnderfloorHeating(prev => ({ ...prev, floor_construction_type: 'Solid (Screed)' }))
                            },
                            {
                                name: 'Suspended',
                                variant: underfloorHeating.floor_construction_type === 'Suspended' ? 'ACTIVE' : 'DEFAULT',
                                onClick: () => setUnderfloorHeating(prev => ({ ...prev, floor_construction_type: 'Suspended' }))
                            }
                        ] }) }),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Floor covering"),
                    React.createElement(Select, { selectedKey: underfloorHeating.floor_covering_uuid, options: FLOOR_COVERINGS.map(x => ({ key: x.uuid, value: `${x.name} - ${x.resistanceM2KPerW} m²K/W` })), setSelectedKey: (e) => setUnderfloorHeating(prev => ({ ...prev, floor_covering_uuid: e })) })),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Percentage of floor area heated"),
                    React.createElement(Input, { value: underfloorHeating.pct_of_floor_area_heated, setValue: (e) => setUnderfloorHeating(prev => ({ ...prev, pct_of_floor_area_heated: parseFloat(e) })), type: 'number', postfix: '%' })),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Pipe centres"),
                    React.createElement(RadioGroup, { items: validPipeCentres.map(x => ({
                            name: x.uuid,
                            variant: underfloorHeating.pipe_centres_uuid === x.uuid ? 'ACTIVE' : 'DEFAULT',
                            onClick: () => setUnderfloorHeating(prev => ({ ...prev, pipe_centres_uuid: x.uuid }))
                        })) })),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Pipe diameter"),
                    React.createElement(RadioGroup, { items: validPipeDiameters.map(x => ({
                            name: x.exterior_diameter_mm.toString() + 'mm',
                            variant: underfloorHeating.pipe_model_uuid === x.uuid ? 'ACTIVE' : 'DEFAULT',
                            onClick: () => setUnderfloorHeating(prev => ({ ...prev, pipe_model_uuid: x.uuid }))
                        })) })),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Photos"),
                    React.createElement(PhotoScroll, { images: images, addImage: (fileWrapper) => {
                            fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, created_at: new Date().getTime(), updated_at: new Date().getTime() }]);
                            fileWrapper && setUnderfloorHeating(prev => ({
                                ...prev,
                                photos: [...prev.photos, {
                                        uuid: crypto.randomUUID(),
                                        entity_uuid: prev.uuid,
                                        entity_attribute: 'photos',
                                        image_uuid: fileWrapper.uuid
                                    }]
                            }));
                        }, deleteImage: deleteImage, companyUUID: companyUUID })),
                React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }),
                ufhOutputs.meanWaterTempC > 0 && meanSystemFlowTempC > ufhOutputs.meanWaterTempC && React.createElement(Alert, { type: 'WARNING' }, `The average system flow temp (${meanSystemFlowTempC}°C)  is higher than the average manifold water temperature (${ufhOutputs.meanWaterTempC}°C) so the manifold is mixing down the water entering the UFH`)),
            React.createElement("div", { className: "border border-gray-200" }),
            React.createElement("div", { className: " flex flex-col gap-3" },
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Demand', helperText: `The heat demand of the ${room.name}`, size: 'XL' }), input: React.createElement(ClickableCard, { variant: 'GREY' },
                        React.createElement("div", { className: 'flex gap-4' },
                            React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                                React.createElement("div", { className: 'font-bold text-gray-900' }, "Area"),
                                React.createElement("div", null,
                                    floorAreaM2,
                                    " m\u00B2")),
                            React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                                React.createElement("div", { className: 'font-bold text-gray-900' }, "W/m\u00B2"),
                                React.createElement("div", null,
                                    (roomWattsPerM2).toFixed(0),
                                    " W/m\u00B2")),
                            React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                                React.createElement("div", { className: 'font-bold text-gray-900' }, "Total"),
                                React.createElement("div", null,
                                    numberFormat(0).format(roomWatts),
                                    " W")),
                            React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                                React.createElement("div", { className: 'font-bold text-gray-900' }, "Room temp"),
                                React.createElement("div", null,
                                    roomTempC,
                                    " \u00B0C")))) })),
            React.createElement("div", { className: "border border-gray-200" }),
            React.createElement("div", { className: " flex flex-col gap-3" },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Outputs"),
                React.createElement("div", { className: 'text-gray-500 text-sm' }, 'These results are approximate. The precise results will depend on the details of the underfloor lay up.'),
                ufhOutputs.warning && React.createElement(Alert, { type: 'WARNING' }, ufhOutputs.warning),
                React.createElement(ClickableCard, { variant: 'GREY' },
                    React.createElement("div", { className: 'flex gap-4' },
                        React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Surface temp"),
                            React.createElement("div", { className: ufhOutputs.surfaceTempC > roomTempC + 9 ? 'text-red-700' : '' },
                                ufhOutputs.surfaceTempC.toFixed(),
                                " \u00B0C")),
                        React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "W/m\u00B2"),
                            React.createElement("div", { className: roomWattsPerM2 > ufhOutputs.wattsPerM2ActiveFloor ? 'text-red-700' : '' },
                                ufhOutputs.wattsPerM2ActiveFloor.toFixed(),
                                " W/m\u00B2")),
                        React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Total"),
                            React.createElement("div", { className: roomWatts > ufhOutputs.watts ? 'text-red-700' : '' },
                                ufhOutputs.watts.toFixed(),
                                " W ")),
                        React.createElement("div", { className: 'flex flex-col gap-1 flex-grow' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Pipe length"),
                            React.createElement("div", null,
                                ufhOutputs.pipeLengthM.toFixed(),
                                " m")))),
                ufhOutputs.surfaceTempC > roomTempC + 9 && React.createElement(Alert, { type: 'DANGER' },
                    React.createElement("div", { className: "items-center gap-1 flex" },
                        'Surface temperature is too high',
                        React.createElement(Info, { infoModalHeader: 'Surface temperature is too high', infoModalBody: `The floor surface temperature should be no more than 9°C higher than the room temperature. In this case it is ${(ufhOutputs.surfaceTempC - roomTempC).toFixed(1)} °C higher.`, colour: 'text-red-800' }))),
                roomWatts > ufhOutputs.watts && React.createElement(Alert, { type: 'DANGER' },
                    React.createElement("div", { className: "items-center gap-1 flex" },
                        'Room demand not met',
                        React.createElement(Info, { infoModalHeader: 'Room demand not met', infoModalBody: `The room needs ${(roomWatts).toFixed(0)} W of heat at the design outdoor temp but the current UFH design only provides ${ufhOutputs.watts.toFixed(0)} W.
              Reduce the pipe spacing, change the floor covering resistance or increase the flow temperature to meet the room demand.`, colour: 'text-red-800' }))))),
        React.createElement("div", { className: 'p-4 bg-white' },
            React.createElement(Button, { disabled: !underfloorHeating.manifold_uuid || !survey.manifolds.find(m => m.uuid === underfloorHeating.manifold_uuid) || !underfloorHeating.floor_covering_uuid, block: true, onClick: () => {
                    onSave();
                    setUnderfloorHeating(undefined);
                    onBack();
                } }, "Apply")));
};
