import React, { useEffect, useRef, useState } from 'react';
import { Section } from '../../../../components/containers/section';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Map, Marker, useAutocomplete } from '@vis.gl/react-google-maps';
import { SettingsLayout } from '../components/settings_layout';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { validateEmail, validatePhone } from '../../../../code/validators';
import { Info } from '../../../../components/buttons/info';
export const AccountProfile = ({ company, setCompany }) => {
    const [addressLocation, setAddressLocation] = useState();
    const [mapCenter, setMapCenter] = useState();
    const inputRef = useRef(null);
    useEffect(() => {
        var _a, _b, _c, _d;
        setAddressLocation({ lat: (_a = company.lat) !== null && _a !== void 0 ? _a : 0, lng: (_b = company.lng) !== null && _b !== void 0 ? _b : 0 });
        setMapCenter({ lat: (_c = company.lat) !== null && _c !== void 0 ? _c : 0, lng: (_d = company.lng) !== null && _d !== void 0 ? _d : 0 });
    }, []);
    const onPlaceChanged = (place) => {
        var _a, _b, _c, _d, _e, _f;
        // OnPlaceChanged fires when we click an autocompleted suggestion when typing an address in the Address input.
        // Update the company address, company lat and lng with the new details.
        // And update the map view with the pin on the new location.
        if (place) {
            setCompany({ ...company, address: (_b = (_a = place.formatted_address) !== null && _a !== void 0 ? _a : place.name) !== null && _b !== void 0 ? _b : '' });
            setAddressLocation((_d = (_c = place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.toJSON());
            setMapCenter((_f = (_e = place.geometry) === null || _e === void 0 ? void 0 : _e.location) === null || _f === void 0 ? void 0 : _f.toJSON());
        }
    };
    useAutocomplete({
        inputField: inputRef === null || inputRef === void 0 ? void 0 : inputRef.current,
        onPlaceChanged
    });
    return React.createElement(SettingsLayout, { heading: 'Profile', description: 'Manage your Spruce profile' },
        React.createElement(Section, { border: true, title: "Account details" },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4' },
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'First name' }), input: React.createElement(Input, { value: company.public_info.installer_first_name, setValue: (e) => setCompany({
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    installer_first_name: e
                                }
                            }) }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Last name' }), input: React.createElement(Input, { value: company.public_info.installer_last_name, setValue: (e) => setCompany({
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    installer_last_name: e
                                }
                            }) }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Notification email', info: React.createElement(Info, { infoModalHeader: 'Notification email', infoModalBody: 'This is the email address where you receive notification emails from Spruce when customers submit an enquiry, book a heat loss survey, or request a callback. It can be different from your login email.' }) }), input: React.createElement(Input, { value: company.notification_email, setValue: (e) => {
                                if (validateEmail(e).value) {
                                    setCompany({ ...company, notification_email: e });
                                }
                            }, validator: validateEmail, validateImmediately: true }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Phone number' }), input: React.createElement(Input, { value: company.phone, setValue: (e) => {
                                if (validatePhone(e).value) {
                                    setCompany({ ...company, phone: e });
                                }
                            }, validator: validatePhone, validateImmediately: true }) })),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Company name' }), input: React.createElement(Input, { value: company.public_info.name, setValue: (e) => {
                            setCompany({ ...company, public_info: { ...company.public_info, phone: e } });
                        } }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Company address' }), input: React.createElement(React.Fragment, null,
                        React.createElement(Input, { ref: inputRef, value: company.address, setValue: (e) => setCompany({ ...company, address: e }) }),
                        ((addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lat) && (addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lng)) &&
                            React.createElement(Map, { onClick: (e) => {
                                    var _a, _b, _c, _d;
                                    const newLocation = e.detail.latLng;
                                    setAddressLocation(newLocation !== null && newLocation !== void 0 ? newLocation : undefined);
                                    setCompany({
                                        ...company,
                                        lat: (_b = (_a = e.detail.latLng) === null || _a === void 0 ? void 0 : _a.lat) !== null && _b !== void 0 ? _b : undefined,
                                        lng: (_d = (_c = e.detail.latLng) === null || _c === void 0 ? void 0 : _c.lng) !== null && _d !== void 0 ? _d : undefined
                                    });
                                }, streetViewControl: false, zoom: 18, className: "min-h-[200px] w-full h-full rounded overflow-hidden", mapTypeId: "satellite", center: mapCenter },
                                React.createElement(Marker, { position: addressLocation }))) }))));
};
