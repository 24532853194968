import { getCompanyBookingConfirmationActionEmailTemplate, getCompanyBookingConfirmationNoActionEmailTemplate } from '../../../../../code/models/company';
import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerName } from '../../../../../code/tiptap_placeholders';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
const BookingConfirmationWithAction = ({ company, setCompany }) => {
    var _a;
    return React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Message', helperText: 'Book in directly' }), input: React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                setCompany({
                    ...company,
                    booking_confirmation_action_template: JSON.stringify(editor.getJSON())
                });
            }, placeholders: [
                TTPlaceholderCustomerName,
                TTPlaceholderCompanyName,
                TTPlaceholderCompanyPhone
            ], content: getCompanyBookingConfirmationActionEmailTemplate((_a = company.booking_confirmation_action_template) !== null && _a !== void 0 ? _a : '') }) });
};
const BookingConfirmationWithoutAction = ({ company, setCompany }) => {
    var _a;
    return React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Message', helperText: 'Wait for callback' }), input: React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                setCompany({
                    ...company,
                    booking_confirmation_noaction_template: JSON.stringify(editor.getJSON())
                });
            }, placeholders: [
                TTPlaceholderCustomerName,
                TTPlaceholderCompanyName,
                TTPlaceholderCompanyPhone
            ], content: getCompanyBookingConfirmationNoActionEmailTemplate((_a = company.booking_confirmation_noaction_template) !== null && _a !== void 0 ? _a : '') }) });
};
export const BookingConfirmationOptionsBlock = ({ company, setCompany }) => {
    var _a, _b;
    return React.createElement(Section, { border: true, title: "Booking survey options" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Take user straight to booking link' }), input: React.createElement(Toggle, { value: (_a = company.booking_redirect_enabled) !== null && _a !== void 0 ? _a : false, setValue: async () => setCompany({
                        ...company,
                        booking_redirect_enabled: !company.booking_redirect_enabled
                    }) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Booking redirect URL' }), input: React.createElement(Input, { validator: (value) => (value === null || value === void 0 ? void 0 : value.length) > 0 ? { value, message: '' } : {
                        value: undefined,
                        message: 'Please enter a valid URL'
                    }, disabled: !company.booking_redirect_enabled, placeholder: 'Enter url here', value: (_b = company.booking_redirect_url) !== null && _b !== void 0 ? _b : '', setValue: url => setCompany({
                        ...company,
                        booking_redirect_url: url
                    }) }) }),
            company.booking_redirect_enabled
                ? React.createElement(BookingConfirmationWithAction, { company: company, setCompany: setCompany })
                : React.createElement(BookingConfirmationWithoutAction, { company: company, setCompany: setCompany })));
};
