import React, { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { priceCalculations } from '../../../../../code/price_calculations';
import { EmptySection } from '../empty_section';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { useDebounceCallback } from 'usehooks-ts';
const AddPartModal = ({ addPart, visible, setVisible }) => {
    const [part, setPart] = useState({
        name: '',
        description: '',
        cost_price: 0,
        markup: 0
    });
    const updatePart = (field, value) => {
        setPart(prev => ({ ...prev, [field]: value }));
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Add part', onConfirm: () => {
            addPart({
                ...part,
                uuid: crypto.randomUUID(),
                default_include: false
            });
            setPart({ name: '', description: '', cost_price: 0, markup: 0 });
        } },
        React.createElement("div", { className: "space-y-4 w-full" },
            React.createElement(Input, { label: 'Name', placeholder: 'Enter part name', value: part.name, setValue: (value) => updatePart('name', value) }),
            React.createElement("div", { className: "flex flex-col gap-1" },
                React.createElement(FormLabel, { labelText: 'Description', helperText: 'Part descriptions are displayed on the final quote, but not the estimate.' }),
                React.createElement(TextArea, { placeholder: 'Enter part description', value: part.description, setValue: (value) => updatePart('description', value) })),
            React.createElement(Input, { type: "number", label: 'Cost price', placeholder: 'Enter cost price', value: part.cost_price, setValue: (value) => updatePart('cost_price', parseFloat(value)), prefix: '\u00A3' }),
            React.createElement(Input, { type: "number", label: 'Markup', placeholder: 'Enter markup', value: part.markup, setValue: (value) => updatePart('markup', parseFloat(value)), postfix: '%' }),
            React.createElement(Input, { type: "number", label: 'Customer price', placeholder: 'Enter customer price', value: priceCalculations.calculateCustomerPrice(part.cost_price, part.markup), setValue: (value) => {
                    updatePart('markup', 0);
                    updatePart('cost_price', parseFloat(value));
                }, prefix: '\u00A3' }))));
};
const EditPartModal = ({ part, visible, setVisible, updatePart }) => {
    const [editingName, setEditingName] = useState(part.name);
    const [editingDescription, setEditingDescription] = useState(part.description);
    const debounceUpdatePart = useDebounceCallback(updatePart, 500);
    const handleUpdateDescription = (value) => {
        setEditingDescription(value);
        debounceUpdatePart({ ...part, description: value });
    };
    const handleUpdateName = (value) => {
        setEditingName(value);
        debounceUpdatePart({ ...part, name: value });
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Edit part' },
        React.createElement("div", { className: "space-y-4 w-full" },
            React.createElement(Input, { label: 'Name', placeholder: 'Enter part name', value: editingName, setValue: handleUpdateName }),
            React.createElement("div", { className: "flex flex-col gap-1" },
                React.createElement(FormLabel, { labelText: 'Description', helperText: 'Part descriptions are displayed on the final quote, but not the estimate.' }),
                React.createElement(TextArea, { placeholder: 'Enter part description', value: editingDescription || '', setValue: handleUpdateDescription })))));
};
export const PartsInventory = ({ addOrUpdatePart, deletePart, parts }) => {
    const [addPartModalVisible, setAddPartModalVisible] = useState(false);
    const [editPartModalPart, setEditPartModalPart] = useState(undefined);
    const partsColumns = [
        { key: 'name', name: 'Name' },
        { key: 'alwaysInclude', name: 'Always include', infoText: 'If toggled, the part will always be added to new estimates and quotes.' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "space-y-6" },
            React.createElement("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full" },
                React.createElement(Heading, { size: "2xl" }, "Parts & materials"),
                React.createElement(Button, { colour: 'DARK', onClick: () => setAddPartModalVisible(true), iconLeft: faPlus }, "Add part")),
            !parts.length
                ? React.createElement(EmptySection, { title: 'No parts added', description: 'Add parts to your inventory to quickly add them to estimates and quotes.', button: React.createElement(Button, { onClick: () => setAddPartModalVisible(true), iconLeft: faPlus }, "Add part") })
                : React.createElement(Section, null,
                    React.createElement(InventoryTable, { rows: parts
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((x) => ({
                            name: React.createElement(React.Fragment, null,
                                React.createElement(Text, { size: "SM" }, x.name),
                                React.createElement(Text, { size: "XS", className: 'text-gray-500' }, x.description)),
                            alwaysInclude: React.createElement(Toggle, { value: x.default_include, setValue: () => addOrUpdatePart({ ...x, default_include: !x.default_include }) }),
                            costPrice: React.createElement(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => {
                                    addOrUpdatePart(priceCalculations.updateCostPrice(x, parseFloat(value)));
                                } }),
                            markup: React.createElement(Input, { type: 'number', step: 1, value: x.markup, setValue: (value) => {
                                    addOrUpdatePart(priceCalculations.updateMarkup(x, parseFloat(value)));
                                }, postfix: '%' }),
                            customerPrice: React.createElement(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => {
                                    addOrUpdatePart(priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)));
                                }, prefix: '\u00A3' }),
                            menu: React.createElement(DropdownMenu, { items: [
                                    { label: 'Edit', onClick: () => setEditPartModalPart(x), icon: faEdit },
                                    { label: 'Delete', onClick: () => deletePart(x.uuid), icon: faTrashAlt, confirmText: 'Are you sure you want to delete this part?' }
                                ] })
                        })), columns: partsColumns }))),
        React.createElement(AddPartModal, { addPart: addOrUpdatePart, visible: addPartModalVisible, setVisible: setAddPartModalVisible }),
        editPartModalPart && React.createElement(EditPartModal, { part: editPartModalPart, visible: !!editPartModalPart, setVisible: (visible) => {
                if (!visible) {
                    setEditPartModalPart(undefined);
                }
            }, updatePart: addOrUpdatePart })));
};
