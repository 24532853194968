import React, { useContext, useState } from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { faBatteryFull, faBoltLightning, faChevronRight, faCloudDownload, faDatabase, faHome, faHouse, faList, faPlus, faSyncAlt, faWrench } from '@fortawesome/free-solid-svg-icons';
import { DesignPageHeader } from '../design/components/design_page_header';
import { sum } from 'lodash';
import { FloorAttributesBlock } from '../floor/floor_attributes_block';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { getAreaM2 } from '../floor/code/utils';
import { MagicplanModal } from '../magicplan/magicplan_modal';
import { isCompanyHasMagicPlanIntegration } from '../../../code/models/company';
import { Button } from '../../../components/buttons/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminContext } from '../../admin/admin_layout';
import { EmptyState } from '../../../components/content_display/empty_state';
import { Alert } from '../../../components/indicators_and_messaging/alert';
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_SURVEY_DESIGN } from '../../../code/survey_defaults';
import { handleEstimateMapping } from '../../admin/job_layout/handle_estimate_mapping';
import { db } from '../db';
export const Survey = ({ lead, survey, navigateTo, adminContext, leadUUID, setCustomMaterials, surveyDB, companyPublicInfo, useFloorplanFlow }) => {
    var _a;
    const totalArea = Math.round(sum(survey.floors.flatMap(x => x.rooms.flatMap(y => getAreaM2(y.walls.map(z => ({ x: z.x, y: z.y })))))));
    const estimatedArea = (_a = lead === null || lead === void 0 ? void 0 : lead.property.floorArea) !== null && _a !== void 0 ? _a : 0;
    const areaPercentage = Math.round((totalArea / estimatedArea) * 100);
    const siteDetailsCompleted = survey.completed_sections.includes('PROPERTY');
    const existingHeatingSystemCompleted = survey.completed_sections.includes('EXISTING_HEATING');
    const heatPumpCompleted = survey.completed_sections.includes('HEAT_PUMP');
    const cylinderCompleted = survey.completed_sections.includes('CYLINDER');
    const electricsCompleted = survey.completed_sections.includes('ELECTRICS');
    const pipeworkCompleted = survey.completed_sections.includes('PIPEWORK');
    const settingsCompleted = survey.completed_sections.includes('SETTINGS');
    const materialsCompleted = survey.completed_sections.includes('MATERIALS');
    const floorplanCompleted = survey.completed_sections.includes('FLOORPLAN');
    const addNewSurvey = async () => {
        var _a, _b, _c, _d, _e;
        const newSurvey = { ...DEFAULT_PROPERTY_SURVEY, uuid: leadUUID, designs: [{ ...DEFAULT_SURVEY_DESIGN, uuid: crypto.randomUUID() }] };
        const hydratedFromLead = lead
            ? handleEstimateMapping(lead, adminContext.data.genericMaterials, (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.heatPumps) !== null && _b !== void 0 ? _b : [], (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders) !== null && _d !== void 0 ? _d : [], (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company, newSurvey, setCustomMaterials)
            : newSurvey;
        await db.surveys.add({
            uuid: leadUUID,
            created_at: new Date().getTime(),
            updated_at: new Date().getTime(),
            deleted_at: undefined,
            server_updated_at: 0,
            data: hydratedFromLead,
            company_uuid: companyPublicInfo.uuid
        });
    };
    if (!surveyDB || !survey) {
        return React.createElement(EmptyState, { icon: faList, primaryText: 'Start survey', secondaryText: 'Starting a survey will pull in material, heat pump and other data from your enquiry.', primaryButton: React.createElement(Button, { onClick: addNewSurvey, iconLeft: faPlus }, "Start survey"), alert: React.createElement(Alert, { type: 'WARNING' }, "If you have previously started this survey on another device please wait for the synchronisation to finish or the data will be overwritten by this process.") });
    }
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0 bg-gray-100' },
        React.createElement("div", { className: 'flex flex-col gap-4 overflow-y-auto' },
            React.createElement("div", { className: 'text-xl font-bold text-gray-900' }, "Site survey"),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Site details"),
                    React.createElement(Badge, { color: siteDetailsCompleted ? 'GREEN' : 'LIGHT', text: siteDetailsCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faHome, onClick: () => navigateTo('/survey/site_details'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Existing heating system"),
                    React.createElement(Badge, { color: existingHeatingSystemCompleted ? 'GREEN' : 'LIGHT', text: existingHeatingSystemCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faBatteryFull, onClick: () => navigateTo('/survey/existing_heating'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Heat pump location"),
                    React.createElement(Badge, { color: heatPumpCompleted ? 'GREEN' : 'LIGHT', text: heatPumpCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faSyncAlt, onClick: () => navigateTo('/survey/heat_pump_location'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, titleIcon: faDatabase, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Cylinder location"),
                    React.createElement(Badge, { color: cylinderCompleted ? 'GREEN' : 'LIGHT', text: cylinderCompleted ? 'Complete' : 'Incomplete' })), onClick: () => navigateTo('/survey/cylinder_location'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, titleIcon: faBoltLightning, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Electrics"),
                    React.createElement(Badge, { color: electricsCompleted ? 'GREEN' : 'LIGHT', text: electricsCompleted ? 'Complete' : 'Incomplete' })), onClick: () => navigateTo('/survey/electrics'), submitIcon: faChevronRight }),
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: 'text-xl font-bold text-gray-900' }, "Heat loss")),
            useFloorplanFlow &&
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, className: 'flex-1', title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Start floor plan"),
                        React.createElement(Badge, { color: 'LIGHT', text: 'Incomplete' })), onClick: () => navigateTo('/survey/floors') }),
            !useFloorplanFlow && React.createElement(React.Fragment, null,
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, className: 'flex-1', title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Settings"),
                        React.createElement(Badge, { color: settingsCompleted ? 'GREEN' : 'LIGHT', text: settingsCompleted ? 'Complete' : 'Incomplete' })), onClick: () => navigateTo('/survey/settings') }),
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, className: 'flex-1', title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Materials"),
                        React.createElement(Badge, { color: materialsCompleted ? 'GREEN' : 'LIGHT', text: materialsCompleted ? 'Complete' : 'Incomplete' })), onClick: () => navigateTo('/survey/materials') }),
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, onClick: () => {
                        survey.floors.length === 0 ? navigateTo('/survey/new_floor') : navigateTo('/survey/floors');
                    }, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Floorplan"),
                        React.createElement(Badge, { color: floorplanCompleted ? 'GREEN' : 'LIGHT', text: floorplanCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faHouse, submitIcon: faChevronRight },
                    React.createElement("div", { className: 'flex gap-4' },
                        React.createElement("div", null,
                            React.createElement("div", { className: 'text-sm text-gray-600' }, "Estimated area"),
                            React.createElement("div", { className: 'text-gray-900 font-bold' }, lead === null || lead === void 0 ? void 0 :
                                lead.property.floorArea,
                                " m\u00B2")),
                        React.createElement("div", null,
                            React.createElement("div", { className: 'text-sm text-gray-600' }, "Measured area"),
                            React.createElement("div", { className: 'text-gray-900 font-bold' },
                                totalArea,
                                " m\u00B2 ",
                                React.createElement("span", { className: 'text-gray-600 font-medium' },
                                    "(",
                                    areaPercentage,
                                    "%)")))))),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, titleIcon: faWrench, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Pipework"),
                    React.createElement(Badge, { color: pipeworkCompleted ? 'GREEN' : 'LIGHT', text: pipeworkCompleted ? 'Complete' : 'Incomplete' })), onClick: () => navigateTo('/survey/pipework'), submitIcon: faChevronRight })));
};
export const FLOOR_NAMES = ['Ground floor', 'First floor', 'Second floor', 'Third floor'];
export const NewFloor = ({ survey, setSurvey, floors, showHeader = true, navigateTo, isOffline, allSynced }) => {
    const adminContext = useContext(AdminContext);
    const [showMagicplanModal, setShowMagicplanModal] = useState(false);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col h-full' },
            showHeader && React.createElement(DesignPageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Add floor', onBack: () => window.history.back() }),
            React.createElement(MagicplanModal, { survey: survey, setSurvey: setSurvey, showMagicplanModal: showMagicplanModal, setShowMagicplanModal: setShowMagicplanModal, afterImport: () => navigateTo('/survey/floor_list') }),
            React.createElement("div", { className: 'p-6 flex flex-col gap-6 items-center' },
                isCompanyHasMagicPlanIntegration(adminContext.data.company) && React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(Button, { onClick: () => setShowMagicplanModal(prev => !prev), colour: 'DARK' },
                            React.createElement(FontAwesomeIcon, { icon: faCloudDownload }),
                            "Magicplan")),
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "OR")),
                React.createElement("div", { className: 'w-full' },
                    React.createElement(FloorAttributesBlock, { floors: floors, onSave: (newFloor) => {
                            setSurvey(prev => ({ ...prev, floors: [...floors, newFloor] }));
                            navigateTo(`/survey/floors/${newFloor.uuid}`);
                        } })))));
};
