import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CanvasNav } from './floor/canvas_nav';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
export const RoomDrawingButtons = ({ cancelDrawingRooms, setScalingPoints, scalingPoints }) => {
    return React.createElement(CanvasNav, { title: 'Draw room', items: [
            { name: 'Remove last point', icon: faCircle, onClick: () => setScalingPoints(prev => prev.slice(0, -1)), variant: 'DEFAULT' },
            { name: 'Cancel', icon: faTimes, onClick: cancelDrawingRooms, variant: 'DEFAULT' }
        ] });
};
