import React from 'react';
import { Info } from '../../../../components/buttons/info';
export const InventoryTable = ({ columns, rows }) => {
    return (React.createElement("table", { className: 'w-full text-default' },
        React.createElement("thead", { className: 'text-xs font-semibold hidden sm:table-header-group' },
            React.createElement("tr", null, columns.map((x, i) => (React.createElement("th", { key: i, className: `p-2 ${x.align === 'right' ? 'text-end' : x.align === 'center' ? 'text-center' : 'text-start'} ${i === 0 && 'w-1/3'}` },
                x.name,
                x.infoText && React.createElement(Info, { className: "pl-1", infoModalHeader: x.name, infoModalBody: x.infoText })))))),
        React.createElement("tbody", { className: 'sm:divide-y sm:border-light' }, rows.map((row, i) => (React.createElement("tr", { key: i, className: 'flex flex-col sm:table-row border border-light rounded sm:border-0 mb-2 p-2 sm:mb-0 sm:p-0' }, columns.map((column, j) => (React.createElement("td", { key: j, className: `grid grid-cols-[1fr_2fr] sm:table-cell border-b last:border-0 border-gray-100 sm:border-0 ${column.align === 'right' ? 'sm:text-end' : column.align === 'center' ? 'sm:text-center' : 'text-start'} py-2 sm:p-2 sm:first:ps-0 sm:last:pe-0` },
            React.createElement("span", { className: 'sm:hidden uppercase tracking-wide text-xs text-gray-500 font-semibold' },
                column.name,
                column.infoText && React.createElement(Info, { className: "pl-1", infoModalHeader: column.name, infoModalBody: column.infoText })),
            React.createElement("div", { className: 'sm:whitespace-pre-wrap' }, column.render ? column.render(row) : row[column.key]))))))))));
};
